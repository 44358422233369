"use strict";

function getSVGUrl(text, style, resolution, fontCSS, htmlTextData) {
  const {
    domElement,
    styleElement,
    svgRoot
  } = htmlTextData;
  domElement.innerHTML = `<style>${style.cssStyle}</style><div style='padding:0;'>${text}</div>`;
  domElement.setAttribute("style", `transform: scale(${resolution});transform-origin: top left; display: inline-block`);
  styleElement.textContent = fontCSS;
  const {
    width,
    height
  } = htmlTextData.image;
  svgRoot.setAttribute("width", width.toString());
  svgRoot.setAttribute("height", height.toString());
  return new XMLSerializer().serializeToString(svgRoot);
}
export { getSVGUrl };
