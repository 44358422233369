import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HelpersService } from '../helpers/helpers.service';
import { metadataObjectFabric } from './fabric';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MetadataService {
  private url = 'https://helper.coduluz.com/metadata';
  constructor(
    private helper: HelpersService,
    private http: HttpClient
  ) {}

  get(profile: any, type: string): Observable<any> {
    const data: any = metadataObjectFabric.get(profile, type, this.helper.setUserIds(profile));
    return this.http.post(`${this.url}/get`, data);
  }

  add(profile: any, value: any, type: string): void {
    const data: any = metadataObjectFabric.add(profile, value, type, this.helper.setUserIds(profile));
    this.http.post(`${this.url}/update`, data).subscribe(() => {});
  }

  getTags(profile: any, type: string, birthDate?: any): Observable<any> {
    const data: any = metadataObjectFabric.getTags(type, profile.firstName + profile.lastName + birthDate);
    return this.http.post(`${this.url}/get`, data);
  }
  addTags(profile: any, value: any, type: string, birthDate: any, userID: string, updateData?: boolean): void {
    const data: any = metadataObjectFabric.addTags(
      profile,
      value,
      type,
      birthDate,
      userID,
      profile.firstName + profile.lastName + birthDate
    );
    this.getTags(profile, type, birthDate).subscribe((res) => {
      if (JSON.stringify(updateData || res[0]?.metadata?.data) !== JSON.stringify(value) || res.length === 0) {
        this.http
          .post(`${this.url}/update`, data)
          .pipe()
          .subscribe(() => {});
      }
    });
  }
  syncTags(profile: any) {
    this.http
      .post('https://tagsync.coduluz.com/', {
        birthDay: profile.birthDay,
        birthMonth: profile.birthMonth,
        birthYear: profile.birthYear,
        firstName: profile.firstName,
        lastName: profile.lastName,
        email: profile.email,
      })
      .subscribe();
  }
  checkTerms(profile: any): Observable<any> {
    const data: any = metadataObjectFabric.checkTerms(this.helper.setUserIds(profile));
    return this.http.post(`${this.url}/get`, data);
  }

  addToTerms(profile: any, termsLast: string): void {
    const data: any = metadataObjectFabric.addToTerms(profile, termsLast, this.helper.setUserIds(profile));
    this.http.post(`${this.url}/update`, data).subscribe(() => {});
  }
}
