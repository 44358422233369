import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, Observable, Subject, takeUntil } from 'rxjs';
import { LocalStorageService } from '../localStorage/local-storage.service';
import { selectUserBalances } from '../../store/user/selectors/user.selector';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class BankService {
  constructor(
    private http: HttpClient,
    private storage: LocalStorageService,
    private store: Store
  ) {}
  Balance$: Observable<any> = this.store.select(selectUserBalances);
  unsubus$: Subject<boolean> = new Subject();

  getBankRates(): Observable<any> {
    const currency = this.getUserCurrency() ?? '';
    return this.http.get(`/api/v2/loyalty/bank/get-bank-rates`, {
      params: { currency },
    });
  }

  stackMoney(amount: number, transactionId: any) {
    return this.http.post<any>(`/api/v2/loyalty/bank/stack-money`, {
      amount: amount,
      transactionId: transactionId?.ID,
      currency: this.getUserCurrency(),
    });
  }
  withdrawalMoney(amount: number, transactionId?: any) {
    return this.http.post<any>(`/api/v2/loyalty/bank/withdraw-money`, {
      amount: -amount,
      transactionId: transactionId?.ID,
      currency: this.getUserCurrency(),
    }); //- kostil for backend
  }
  closePosition(activeBankActionId: number) {
    return this.http.post<any>(`/api/v2/loyalty/bank/close-position`, {
      activeBankActionId: activeBankActionId,
      currency: this.getUserCurrency(),
    });
  }
  getActiveBankRates() {
    return this.http.post<any>(`/api/v2/loyalty/bank/get-active-bank-rates`, { currency: this.getUserCurrency() });
  }
  refreshTimer() {
    return this.http.post<any>(`/api/v2/loyalty/bank/refresh-stacks`, { currency: this.getUserCurrency() });
  }
  claimReward() {
    return this.http.post<any>(`/api/v2/loyalty/bank/collect-welle-coins`, { currency: this.getUserCurrency() });
  }
  exchangeAmount(from: string, to: string, amount: number) {
    return this.http.post('/api/v2/exchange/exchange', {
      from,
      to,
      amount,
    });
  }

  withdrawToFundist(id: number, amount: number) {
    const randomDigits = Math.floor(10000000 + Math.random() * 90000000);

    const params: any = {
      systemId: id,
      amount: amount,
      additional: {
        withdraw_address: 'bank' + randomDigits, //todo
      },
    };
    return this.http.post('/withdrawals?GetID=1', params);
  }

  getWithdrawsTransactions(): any {
    return this.http.get('/transactions?status=0');
  }

  deleteWithdrawTransaction(id: string) {
    return this.http.delete(`/withdrawals?id=${id}`);
  }

  async getWithdrawsActiveTransactions() {
    const data: any = await firstValueFrom(this.getWithdrawsTransactions());

    const activeWithdrawals = data
      .filter((item: any) => item.Amount * 1 < 0 && item.System === 'Cashforo WelleStock') //todo && item.System !== 'testBank'
      .map((item: any) => {
        return {
          ...item,
          Amount: this.setAmount(item.Amount),
          Date: this.setDate(item.Date),
        };
      });

    return activeWithdrawals;
  }

  private setAmount(amount: string) {
    return Number(amount).toFixed(2);
  }

  private setDate(date: string) {
    return date.split(' ')[1];
  }
  private getTimeCoefficientInMilliseconds(dateOfCreate: Date, startTime: Date): number {
    return new Date().getTime() - startTime.getTime();
  }
  WithdrawalToFundist(amount: number) {
    return this.http.post<any>(`/withdrawals`, { amount: amount });
  }
  getUserCurrency() {
    let currency;
    if (this.storage.getSessionStorage('currency')) {
      currency = this.storage.getSessionStorage('currency');
    } else {
      this.Balance$.pipe(takeUntil(this.unsubus$)).subscribe((res) => {
        if (res?.isLoaded) {
          currency = res.currency;
          this.storage.setSessionStorage('currency', res.currency);
          this.unsubus$.next(true);
          this.unsubus$.complete();
        }
      });
    }

    return currency;
  }

  calculateStackAmount(activeRates: any, startTime: Date, rate: number): number {
    const bankRates: any = activeRates?.bankRate;
    const rates: any = bankRates?.rateRelations;
    return (
      (activeRates?.moneyStackAllAmount / +(bankRates.timePeriodInMilliseconds * rate)) *
        (rates.percentFromAllAmount / 100) *
        this.getTimeCoefficientInMilliseconds(activeRates.dateOfCreate, startTime) +
      activeRates.stackedAmount
    );
  }
}
