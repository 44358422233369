import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const verificationActions = createActionGroup({
  source: 'verification',
  events: {
    '[verification]GetVerificationStatus': emptyProps(),
    '[verification]SetVerificationStatusSuccess': props<{
      data: any;
    }>(),
    '[verification]SetVerificationStatusFailure': emptyProps(),
  },
});
