<div class="flex flex-col items-center text-mainTextLight px-4 pb-8 gap-7" *transloco="let t">
  <img
    class="w-[4.875rem] h-[4.875rem]"
    src="https://cdn.chipychapa.com//welle/General_site/Games/allert.png"
    alt="Alert" />
  <h2 class="text-xl font-bold">{{ t('games.unavailable') }}</h2>
  <p class="text-base font-normal text-center">
    {{ t('games.unavailable_with_active_bonus') }}
  </p>
  <div
    (click)="openLiveChat()"
    class="w-full py-5 cursor-pointer flex-center bg-mainBgLight dark:bg-brownMain text-white dark:text-yellowBgMain hover:bg-secondBtnHoverLight dark:hover:text-mainHover dark:hover:bg-yellowBgLight rounded-xl h-12 font-bold text-lg">
    {{ t('buttons.contact_support') }}
  </div>
</div>
