import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { selectBoxItem } from 'src/app/core/interfaces/interfaces';
import { ModalsDataTransferService } from 'src/app/core/services/modalService/modals-data-transfer.service';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'app-select-box-modal-pep',
  standalone: true,
  imports: [CommonModule, TranslocoModule],
  templateUrl: './select-box-modal-pep.component.html',
  styleUrl: './select-box-modal-pep.component.scss',
})
export class SelectBoxModalPepComponent implements OnInit {
  items: Array<selectBoxItem> = [];
  pepSelected!: any;

  constructor(private modalDataTransferService: ModalsDataTransferService) {
    this.items = this.modalDataTransferService.getSelectedBoxItemsPep();
  }
  ngOnInit(): void {
    this.pepSelected = this.modalDataTransferService.getSelectedItemInSelectBoxPep();
  }
  selectItem(item: selectBoxItem) {
    this.modalDataTransferService.selectItemInSelectBoxPep(item);
  }
}
