<ng-container *transloco="let t">
  <div
    [class]="
      'w-full relative flex flex-col gap-2 justify-end ' +
      (isDisabled ? ' pointer-events-none' : '') +
      (type !== 'withdraw' ? 'h-full' : '')
    ">
    <label
      *ngIf="showLabel"
      [class]="
        'dark:text-secondaryTextMain  text-base font-normal h-6 text-left ' +
        (type === 'withdraw' ? 'text-mainTextLight' : 'text-blackTextLight')
      ">
      {{ t(label) }}
      <span *ngIf="control.errors?.required && type === 'withdraw'" class="text-errorColor text-2xl">*</span>
    </label>
    <div [class]="isDisabled ? 'pointer-events-none' : ''">
    <input
      autocomplete="off"
      autocorrect="off"
      autocapitalize="none"
      spellcheck="false"
      #field
      [type]="fieldType"
      [ngAttributes]="attributes"
      [placeholder]="t(placeholder)"
      [formControl]="control"
      (input)="search($event.target.value)"
      [id]="id"
      [name]="'field-' + _controlName.name"
      [class]="
        ' dark:text-mainText text-grayText text-base font-normal dark:placeholder:text-mainText text-placeholder:text-grayText leading-5 text-left focus-visible:outline-0 w-full rounded-xl px-3 h-[48px] border-[1px] border-lightGrayBorder mainInput ' +
        ((control.invalid && control.touched && type !== 'signin') ||
        (value === '' && error?.required && type !== 'signin')
          ? ' border-errorColor '
          : ' border-lightGrayBorder ') +
        (isLight ? ' dark:bg-containerBg bg-containerBgLight ' : ' dark:bg-mainBg bg-inputBackground ') +
        (isDisabled ? ' disabled-input bg-inputBackground ' : '') +
        (attention && (value === undefined || value === '') ? ' important-input ' : '')
      " />
    </div>
  </div>
  @if (type !== 'signin') {
    <div [class]="isDisabled ? '' : 'pt-2'">
      <div
        *ngIf="control.errors?.email"
        class="flex items-center gap-1 text-sm font-normal leading-5 text-left text-errorColor">
        <img src="https://cdn.chipychapa.com//welle/General_site/Autharization/error_field.svg" />
        <span>{{ t('errors.email_registred') }}</span>
      </div>
      <div
        *ngIf="control.errors?.incorectPhoneNumber && control.errors?.required"
        class="flex gap-1 items-center text-sm font-normal leading-5 text-left text-errorColor">
        <img src="https://cdn.chipychapa.com//welle/General_site/Autharization/error_field.svg" />
        <span>{{ t('errors.phone_invalid') }}</span>
      </div>
      <div
        *ngIf="control.errors?.phoneNumber"
        class="flex gap-1 items-center text-sm font-normal leading-5 text-left text-errorColor">
        <img src="https://cdn.chipychapa.com//welle/General_site/Autharization/error_field.svg" />
        <span>{{ t('errors.phone_exists') }}</span>
      </div>
      <div
        *ngIf="control.errors?.iban"
        class="flex gap-1 items-center text-sm font-normal leading-5 text-left text-errorColor">
        <img src="https://cdn.chipychapa.com//welle/General_site/Autharization/error_field.svg" />
        <span>{{ t('errors.wrong_iban') }}</span>
      </div>
      <div
        *ngIf="control.errors?.restrictedIban && !control.errors?.iban"
        class="flex gap-1 items-center text-sm font-normal leading-5 text-left text-errorColor">
        <img src="https://cdn.chipychapa.com//welle/General_site/Autharization/error_field.svg" />
        <span>{{ t('errors.iban_not_serviced') }}</span>
      </div>
      <div
        *ngIf="(control.errors?.required && control.touched) || (error?.required && value === '')"
        class="flex gap-1 items-center text-sm font-normal leading-5 text-left text-errorColor">
        <img src="https://cdn.chipychapa.com//welle/General_site/Autharization/error_field.svg" />
        <span>{{ t(label) }} {{ t('required') }}</span>
      </div>
      <div
        *ngIf="
          control.invalid &&
          control.touched &&
          !control.errors?.required &&
          !control.errors?.restrictedIban &&
          !control.errors?.email &&
          !control.errors?.phoneNumber &&
          !control.errors?.iban
        "
        class="flex gap-1 items-center text-sm font-normal leading-5 text-left text-errorColor">
        <img src="https://cdn.chipychapa.com//welle/General_site/Autharization/error_field.svg" />
        <span>{{ t(label) }} {{ t('errors.invalid_format') }}</span>
      </div>
    </div>
  }
</ng-container>
