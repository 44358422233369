<ng-container *transloco="let t">
  <div
    *ngIf="openModal"
    class="rounded-2xl relative w-full dark:bg-biggerContainerBg bg-smallContainerBgLight p-6 grid grid-cols-1 gap-6 items-center">
    <button
      class="group absolute top-7 right-7 rounded-lg hover:bg-sidebarLight dark:hover:bg-mainHover h-8 w-8"
      (click)="buttonAction('Close')">
      <img
        class="m-auto group-hover:scale-110"
        src="https://cdn.chipychapa.com//welle/General_site/Sidebar/Cashier/Deposit/close.svg"
        alt="cross" />
    </button>
    <div class="grid w-full h-full place-content-center pt-6">
      <img class="w-16 h-16" [src]="content[openModal]?.img" [alt]="openModal" />
    </div>
    <h2 class="text-center font-semibold text-lg text-mainTextLight dark:text-white">
      {{ t(content[openModal]?.title) }}
    </h2>
    <h2 class="text-center font-normal text-base text-mainTextLight dark:text-white">
      {{ text === 'sidenav.cashier.withdraw.failed_exceeded' ? t(text) : text }}
    </h2>
    <ng-container *ngIf="amount > 0 && currency !== ''">
      <p class="text-center font-normal text-base text-mainTextLight">
        <span>{{ currency | userCurrencyPipe }}{{ amount }}</span>
        {{ t('sidenav.cashier.deposit.succes_deposti_with_amount') }}
      </p>
    </ng-container>
    <div class="w-full grid grid-cols-1 gap-4">
      <app-main-button
        *ngFor="let button of content[openModal]?.Btns"
        [name]="button?.text"
        [variant]="button?.variant"
        (click)="buttonAction(button?.text)"
        [id]="button?.text + button?.title?.replace('sidenav.cashier.', '') + 'Btn'"></app-main-button>
    </div>
  </div>
</ng-container>
