import { createReducer, on } from '@ngrx/store';
import { AnimationActions } from '../actions/animation.actions';

export const configFeatureKey = 'animation';

export interface State {
  isSitebarOpen: boolean;
  isLoaded: boolean;
  tirggerClose: boolean;
}

export const initialState: State = {
  isSitebarOpen: true,
  isLoaded: false,
  tirggerClose: false,
};

export const reducer = createReducer(
  initialState,
  on(AnimationActions['[animation]OpenSitebarSuccess'], (state) => {
    return {
      ...state,
      isSitebarOpen: true,
      tirggerClose: true,
      isLoaded: true,
    };
  }),
  on(AnimationActions['[animation]CloseSitebarSuccess'], (state) => {
    return {
      ...state,
      isSitebarOpen: false,
      isLoaded: true,
    };
  }),
  on(AnimationActions['[animation]TriggerMenuClose'], (state) => {
    return {
      ...state,
      tirggerClose: false,
      isLoaded: true,
    };
  })
);
