import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextFieldComponent } from '../../forms/text-field/text-field.component';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { ModalsActions } from 'src/app/core/store/modals/actions/modals.actions';
import { ModalsDataTransferService } from 'src/app/core/services/modalService/modals-data-transfer.service';
import { InnerLoaderComponent } from '../../animations/inner-loader/inner-loader.component';
import { states } from 'src/app/core/utils/global-variables/states';
import { selectUser } from 'src/app/core/store/user/selectors/user.selector';

@Component({
  selector: 'app-select-box-state-modal',
  standalone: true,
  imports: [CommonModule, TextFieldComponent, FormsModule, ReactiveFormsModule, InnerLoaderComponent],
  templateUrl: './select-box-state-modal.component.html',
  styleUrl: './select-box-state-modal.component.scss',
})
export class SelectBoxStateModalComponent implements OnInit {
  @Input() country: string = '';
  items: any;
  form: FormGroup = new FormGroup({});
  filtredState: Array<{ title: string }> = [];
  unsubus$: Subject<boolean> = new Subject();
  user$: Observable<any> = this.store.select(selectUser);
  selectedState!: { title: string };
  stateSelected!: string | null;
  currentCountry: string = '';
  viewInit: boolean = false;
  constructor(
    private fb: FormBuilder,
    private store: Store,
    private modalDataTransferService: ModalsDataTransferService
  ) {
    setTimeout(() => {
      this.viewInit = true;
    }, 1500);
    this.items = this.modalDataTransferService.getSelectedBoxItemsState();
  }
  ngOnInit(): void {
    this.form = this.fb.group({
      stateName: [null],
    });
    this.user$.pipe(takeUntil(this.unsubus$)).subscribe((res: any) => {
      this.currentCountry = this.country ? this.country : res?.userProfile?.data?.countryCode;
      this.search('');
    });
    this.stateSelected = this.modalDataTransferService.getSelectedItemInSelectBoxState();
  }
  search(value: any) {
    if (value.match(/^(?![+])+\d+(?![a-zA-Z])/)) {
      value = '+' + value;
    }
    value = value?.toLowerCase();
    this.filtredState = states[this.currentCountry]?.filter((item: { title: string }) => {
      return item?.title?.toLowerCase().startsWith(value);
    });
  }
  onSubmit() {
    if (this.filtredState?.length == 1) {
      this.selectState(this.filtredState[0]);
    }
  }
  selectState(item: any) {
    this.modalDataTransferService.selectItemInSelectBoxState(item);
    this.store.dispatch(ModalsActions['[modals]CloseModalsSuccess']());
  }
}
