import { createReducer, on } from '@ngrx/store';
import { SetUserBalances } from '../actions/user.actions';

export const UserBalanceKey = 'UserBalance';

export interface State {
  balance: number;
  currency: string;
  bonusBalance: number;
  points?: number;
  isLoaded: boolean;
}

export const initialState: State = {
  balance: 0,
  currency: 'USD',
  bonusBalance: 0,
  points: 0,
  isLoaded: false,
};

export const reducer = createReducer(
  initialState,
  on(SetUserBalances['[SetUserBalances]SetUserBalanceSuccess'], (state, { data }) => {
    return {
      ...state,
      balance: data.balance,
      currency: data.currency,
      bonusBalance: data.bonusBalance,
      points: data.points,
      isLoaded: true,
    };
  }),
  on(SetUserBalances['[SetUserBalances]SetUserBalanceFailure'], (state) => {
    return {
      ...state,
      balance: 0,
      currency: 'USD',
      bonusBalance: 0,
      points: 0,
      isLoaded: true,
    };
  })
);
