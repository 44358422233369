import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TournamentsService {
  constructor(private http: HttpClient) {}

  getTournaments() {
    return this.http.get('/tournaments');
  }

  getBestPlayers(id: string, limit: number, start: number) {
    return this.http.get(`/tournaments/${id}/top?limit=${limit}&start=${start}`);
  }

  participateTournament(id: string) {
    return this.http.post(`/tournaments/${id}`, {
      Selected: 1,
    });
  }

  leaveTournament(id: string) {
    return this.http.delete(`/tournaments/${id}`);
  }

  getTournamentUserStatistics(id: string) {
    return this.http.get(`/tournaments/${id}/user`);
  }
}
