import { APP_INITIALIZER, ErrorHandler, NgModule, isDevMode } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { HttpClient, HttpClientModule, provideHttpClient, withFetch } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { reducers, metaReducers, httpInterceptorProviders } from '.';
import { environment } from 'src/environments/environment.prod';
import { ConfigEffects } from './core/store/config/effects/config.effects';
import { TranslocoRootModule } from './transloco-root.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';
import { checkUserExistEffect } from './core/store/sign-up/effects/sign-up.effects';
import { auhtUserEffect } from './core/store/user/effects/authUser.effect';
import { HelpersEffects } from './core/store/helpers/effects/helpers.effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { CustomSerializer } from './core/serializer/customUrlserializer';
import { Router, UrlSerializer } from '@angular/router';
import { StandardUrlSerializer } from './core/serializer/StandardUrlSerializer';
import { RouterViewerEffects } from './core/store/router/effects/router.effects';
import { PRECONNECT_CHECK_BLOCKLIST } from '@angular/common';
import { verificationEffects } from './core/store/verification/effects/verification.effect';
import { ImagesModule } from './core/services/imagesConfig/images.module';
import { bonusesEffect } from './core/store/bonuses/effects/bonuses.effect';
import { WithdrawTransactionsEffects } from './core/store/withdraw-transactions/effects/withdraw-transactions.effects';
import { CheckTermsEffects } from './core/store/metabase/effects/check-terms.effects';
import { ModalSelectorComponent } from './shared/global-modal/modal-selector/modal-selector.component';
import { ParticlesBackgroundComponent } from './shared/animations/particles-background/particles-background.component';
import { loyaltyEffect } from './core/store/loyalty/effects/loyalty.effect';
import { FilterEffects } from './core/store/filters/effects/filter.effects';
import * as Sentry from '@sentry/angular';

function httpLoader(http: HttpClient): TranslateHttpLoader {
  const translationUri = environment.production ? './static/dist/assets/i18n/' : './assets/i18n/';
  return new TranslateHttpLoader(http, translationUri, '.json');
}
export class MissingTranslationService implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    return `WARN: '${params.key}' is missing in '${params.translateService.currentLang}' locale`;
  }
}
@NgModule({
  declarations: [AppComponent],
  providers: [
    httpInterceptorProviders,
    {
      provide: UrlSerializer,
      useClass: StandardUrlSerializer,
    },
    // { provide: APP_BASE_HREF, useValue: '/' },
    {
      provide: PRECONNECT_CHECK_BLOCKLIST,
      useValue: 'https://welle.casino',
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    provideClientHydration(),
    provideHttpClient(withFetch()),
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    ModalSelectorComponent,
    ParticlesBackgroundComponent,
    AppRoutingModule,
    HttpClientModule,
    ImagesModule,
    EffectsModule.forRoot([]),
    StoreModule.forRoot(reducers, {
      metaReducers,
    }),
    StoreRouterConnectingModule.forRoot({
      serializer: CustomSerializer,
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: !environment.production,
    }),
    EffectsModule.forFeature([
      verificationEffects,
      ConfigEffects,
      checkUserExistEffect,
      auhtUserEffect,
      RouterViewerEffects,
      HelpersEffects,
      bonusesEffect,
      WithdrawTransactionsEffects,
      CheckTermsEffects,
      loyaltyEffect,
      FilterEffects,
    ]),
    TranslocoRootModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoader,
        deps: [HttpClient],
      },
      isolate: false,
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MissingTranslationService,
      },
    }),

    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // enabled: true,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
      // scope: '/',
    }),
  ],
})
export class AppModule {}
