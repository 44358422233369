import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
@Directive({
  selector: '[ngAttributes]',
  standalone: true,
})
export class AttributesDirective implements OnInit {
  @Input() ngAttributes: any = {};

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    if (this.ngAttributes?.[this.el.nativeElement.id]) {
      for (const attr in this.ngAttributes[this.el.nativeElement.id]) {
        this.renderer.setAttribute(this.el.nativeElement, attr, this.ngAttributes[this.el.nativeElement.id][attr]);
      }
    }
  }
}
