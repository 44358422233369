import { CommonModule, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { loyaltyInfoMenu, userRewardedStatistics } from 'src/app/core/interfaces/interfaces';
import { selectLoyalty } from 'src/app/core/store/loyalty/selector/loyalty.selector';

@Component({
  selector: 'app-user-loyalty-balance-modal',
  standalone: true,
  imports: [CommonModule, NgIf, TranslocoModule],
  templateUrl: './user-loyalty-balance-modal.component.html',
  styleUrl: './user-loyalty-balance-modal.component.scss',
})
export class UserLoyaltyBalanceModalComponent implements OnInit, OnDestroy {
  totalSum: number = 0;
  unsubus$: Subject<boolean> = new Subject();
  loyalty$: Observable<any> = this.store.select(selectLoyalty);

  userRewardedStatistics: userRewardedStatistics = {
    id: 1,
    userId: '1',
    totalRewarded: 0,
    rakeBalanceRewarded: 0,
    rankRewarded: 0,
    calendarRewarded: 0,
    cashbackRewarded: 0,
    welleBetBalanceRewarded: 0,
  };
  userRewardedStatisticsArray: loyaltyInfoMenu[] = [
    { name: 'regular', balance: 0, progress: 0 },
    { name: 'calendar', balance: 0, progress: 0 },
    { name: 'reefback', balance: 0, progress: 0 },
    { name: 'wellbet', balance: 0, progress: 0 },
    { name: 'ranks', balance: 0, progress: 0 },
  ];
  constructor(private store: Store) {}

  ngOnInit(): void {
    this.loyalty$.pipe(takeUntil(this.unsubus$)).subscribe((res) => {
      if (res?.isLoaded) {
        if (res.rewardedStatistics !== null) {
          this.userRewardedStatistics = res.rewardedStatistics;
          this.totalSum = this.userRewardedStatistics.totalRewarded;
          this.userRewardedStatisticsArray = [
            { name: 'regular', balance: this.userRewardedStatistics.cashbackRewarded },
            { name: 'calendar', balance: this.userRewardedStatistics.calendarRewarded },
            { name: 'reefback', balance: this.userRewardedStatistics.rakeBalanceRewarded },
            { name: 'wellbet', balance: this.userRewardedStatistics.welleBetBalanceRewarded },
            { name: 'ranks', balance: this.userRewardedStatistics.rankRewarded },
          ];
          this.userRewardedStatisticsArray.forEach((element: loyaltyInfoMenu) => {
            element.progress = this.calculateProgress(element.balance);
          });
        }
      }
    });
  }
  calculateProgress(turnoverAmount: number): number {
    const percentage = turnoverAmount > 0 && this.totalSum > 0 ? (turnoverAmount / this.totalSum) * 100 : 0;
    return percentage;
  }

  ngOnDestroy(): void {
    this.unsubus$.next(true);
    this.unsubus$.complete();
  }
}
