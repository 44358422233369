import { NavigationService } from './../../../core/services/navigation/navigation.service';
import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { ModalsActions } from 'src/app/core/store/modals/actions/modals.actions';

@Component({
  selector: 'app-quest-notification-modal',
  standalone: true,
  imports: [CommonModule, TranslocoModule],
  templateUrl: './quest-notification-modal.component.html',
  styleUrl: './quest-notification-modal.component.scss',
})
export class QuestNotificationModalComponent implements OnDestroy {
  @Input()
  openModal: 'notification' | 'succesClaim' | null = null;
  @Input()
  text: string = '';

  constructor(
    private navigationService: NavigationService,
    private store: Store
  ) {}

  close() {
    this.store.dispatch(ModalsActions['[modals]CloseModalsSuccess']());
  }
  ngOnDestroy(): void {
    this.store.dispatch(ModalsActions['[modals]CloseModalsSuccess']());
  }
  navigateTo() {
    this.navigationService.navigateTo('/loyalty/quests');
    this.store.dispatch(ModalsActions['[modals]CloseModalsSuccess']());
  }
}
