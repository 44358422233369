import { Injectable } from '@angular/core';
import { catchError, firstValueFrom, map, throwError } from 'rxjs';
import { MetadataService } from '../metabase/metadata.service';
import { KeystoneService } from '../keystone/keystone.service';
import { Store } from '@ngrx/store';
import { ModalsActions } from '../../store/modals/actions/modals.actions';
import { selectUser } from '../../store/user/selectors/user.selector';
import { NavigationService } from '../navigation/navigation.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class TermsAndConditionsService {
  userSelector$ = this.store.select(selectUser);
  constructor(
    private metadata: MetadataService,
    private keystone: KeystoneService,
    private store: Store,
    private navigator: NavigationService,
    private http: HttpClient
  ) {}

  async acceptTerms() {
    this.getProfile()
      .subscribe((data) => this.callback(data))
      .unsubscribe();
  }
  private getProfile() {
    return this.userSelector$.pipe(map((data: any) => data));
  }
  async callback(data: any) {
    const version = await firstValueFrom(this.keystone.getPage(`terms`).pipe(map((res: any) => res?.opengraphImage)));
    this.metadata.addToTerms(data?.userProfile?.data, version);
    return this.navigator.navigateTo('/');
  }
  async openTerms() {
    this.store.dispatch(
      ModalsActions['[modals]OpenModalSuccess']({
        id: 'terms',
        unClosable: true,
      })
    );
  }
  postStorage(key: string, value: string) {
    return this.http
      .post(`/storage/${key}`, {
        value,
      })
      .pipe(
        catchError((error: Error): any => {
          return throwError(() => error?.message);
        })
      );
  }

  getStorage() {
    return this.http.get<any>(`/storage/terms`);
  }
}
