import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpContextToken,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { catchError, map, Observable, switchMap, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { authUser } from '../store/user/actions/user.actions';
import { AuthService } from '../services/auth/auth.service';

@Injectable()
export class UrlInterceptor implements HttpInterceptor {
  private urlPrefix = '/api/';

  excludeRules(url: string) {
    let xpateHelperUrl = '';

    if (
      url.indexOf('https://helper.coduluz.com') > -1 ||
      url.indexOf('https://redoby.com/charge') > -1 ||
      url.indexOf('https://verification.coduluz.com') > -1
    ) {
      xpateHelperUrl = url;
    }
    const listOfExcludeUrlsPatterns = [
      'https://cashforo.com/withdraw-flat/create',
      'https://cashforo.com/main',
      'ws://176.57.189.135:81/',
      'https://welle-api.coduluz.com',
      'https://test.welle.games/graphql',
      '/content/content-restapi.php',
      'https://api.bigdatacloud.net/data/client-ip',
      'https://welle.games/',
      'https://prod.welle.games/graphql',
      '/assets',
      'https://welle.homes/api',
      // test keystone (poka podstavlju prod dlaj 1):
      'https://welle.homes/api',
      'https://welle.homes/admin/signin',
      // test keystone (poka podstavlju prod dlja 1):
      'https://welle.homes/admin/signin',
      'https://welle.homes/admin/api',
      'http://test.welle.games/',
      'https://cashforo.com/zcolibrix-adapter/charge',
      'https://tagsync.coduluz.com/',
      '/api/v2/',
      '/api/v3/',
      '/auth/',
      'https://test.pledoo.games/graphql',
      'casino/bootstrap',
      'https://staging-api.coduluz.com/',
      xpateHelperUrl,
    ];

    return !!listOfExcludeUrlsPatterns.find((value) => {
      if (url.indexOf(value) > -1) {
        return value;
      }
      return;
    });
  }

  constructor(
    private authService: AuthService,
    private store: Store
  ) {}
  isGetAccessTokenInProgress: boolean = false;
  RETRY_CONTEXT = new HttpContextToken<boolean>(() => true);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = this.excludeRules(this.urlPrefix + req.url) ? req.url : this.urlPrefix + req.url;

    let modifiedReq = req.clone({ url });

    const accessTokenPaths = ['/v2/', '/auth/validate-access', '/auth/logout'];

    if (accessTokenPaths.some((path) => url.includes(path))) {
      const accessToken = sessionStorage.getItem('ACCESS_TOKEN');
      modifiedReq = this.setHeadersBearer(accessToken, modifiedReq);
    }

    return next.handle(modifiedReq).pipe(
      map((event: HttpEvent<any>) => {
        let result = event;
        if (event instanceof HttpResponse && event.body) {
          const newEvent = {
            body: event.body.data,
          };
          result = event.clone(newEvent);
        }
        return result;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error?.status === 403 && !error.error?.isAccessTokenValid) {
          if (this.isGetAccessTokenInProgress) {
            return throwError(() => error);
          }
          this.isGetAccessTokenInProgress = true;

          return this.authService.getAccessToken().pipe(
            catchError((tokenError) => {
              this.isGetAccessTokenInProgress = false;
              this.store.dispatch(authUser['[authUser]SetAccessToken']({ data: false }));
              return throwError(() => tokenError);
            }),
            switchMap((token: any) => {
              this.store.dispatch(authUser['[authUser]SetDataFromToken'](JSON.parse(atob(token.split('.')[1]))));
              this.isGetAccessTokenInProgress = false;
              sessionStorage.setItem('ACCESS_TOKEN', token);
              this.store.dispatch(authUser['[authUser]SetAccessToken']({ data: true }));
              const updatedReq = this.setHeadersBearer(token, modifiedReq);
              return next.handle(updatedReq);
            })
          );
        }
        return throwError(() => error);
      }),
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && event.body) {
          return event.clone({ body: event.body.data });
        }
        return event;
      })
    );
  }

  setHeadersBearer(token: string | null, req: HttpRequest<any>): HttpRequest<any> {
    return req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
