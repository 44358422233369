import { Component, Input } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { appState } from 'src/app';
import { ModalsActions } from 'src/app/core/store/modals/actions/modals.actions';
import { MainButtonComponent } from '../../buttons/main-button/main-button.component';
import { AccountService } from 'src/app/core/services/account/account.service';

@Component({
  selector: 'app-block-modal',
  standalone: true,
  imports: [TranslocoModule, MainButtonComponent],
  templateUrl: './block-modal.component.html',
  styleUrl: './block-modal.component.scss',
})
export class BlockModalComponent {
  @Input() formValue!: { days: string; email: string };

  constructor(
    private store: Store<appState>,
    private account: AccountService
  ) {}

  closeModal() {
    this.store.dispatch(ModalsActions['[modals]CloseModalsSuccess']());
  }

  userBlock() {
    this.account.submitLimitForm(this.formValue);
    this.closeModal();
  }
}
