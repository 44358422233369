<div *ngIf="selectedModal === 'Year'" class="p-4">
  <div class="flex flex-col justify-between px-4 bg-smallContainerBgLight gap-4">
    <div class="flex flex-row justify-between">
      <div class="cursor-pointer w-6">
        <img
          alt="arrow left"
          (click)="prevPage()"
          *ngIf="years.indexOf(years[years.length - selectedYearsPage]) !== 0"
          src="https://cdn.playasballs.com//wazbee/icons/Sidebar/chevron-left-large.svg" />
      </div>
      <div class="dark:text-mainText text-mainTextLight">
        {{ years[years.length - selectedYearsPage][0] }}
        -
        {{ years[years.length - selectedYearsPage][years[years.length - selectedYearsPage].length - 1] }}
      </div>
      <div class="cursor-pointer w-6">
        <img
          alt="arrow right"
          (click)="nextPage()"
          *ngIf="years.indexOf(years[years.length - selectedYearsPage]) !== years.length - 1"
          src="https://cdn.playasballs.com//wazbee/icons/Sidebar/chevron-right-large.svg" />
      </div>
    </div>
    <div class="grid grid-cols-5 gap-6">
      <div *ngFor="let item of years[years.length - selectedYearsPage]">
        <app-main-button
          [datePicker]="true"
          (click)="selectYear(item)"
          [name]="item"
          variant="skyBtnDatePicker"></app-main-button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="selectedModal === 'Month'">
  <div class="grid grid-cols-4 p-4 gap-6" *transloco="let t">
    <div *ngFor="let item of months; let i = index">
      <app-main-button
        [disabled]="selectedCurrentYear ? i > currentMonth : false"
        [datePicker]="true"
        (click)="handleMonthSelect(item, i)"
        [name]="t('month.transactions_month-' + item.month)"
        variant="skyBtnDatePicker"></app-main-button>
    </div>
  </div>
</div>
<div *ngIf="selectedModal === 'Day'">
  <div class="grid grid-cols-7 p-4 gap-0.5">
    <div *ngFor="let item of days; let i = index">
      <app-main-button
        [disabled]="selectedCurrentMonth ? i > currentDay - 1 : false"
        [datePicker]="true"
        (click)="handleDaySelect(item, i)"
        [name]="item"
        variant="skyBtnDatePicker"></app-main-button>
      <div></div>
    </div>
  </div>
</div>
