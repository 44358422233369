import { createReducer, on } from '@ngrx/store';
import { UserResetPass, authUser } from '../actions/user.actions';

export const UserAuth = 'UserAuth';

export interface State {
  auth: any;
  data: any;
  err: any;
}

export const initialState: State = {
  auth: null,
  data: null,
  err: null,
};
export const reducer = createReducer(
  initialState,
  on(authUser['[authUser]AuthUserSuccess'], (state, { data }) => {
    return {
      ...state,
      data: data,
      err: data.errors,
    };
  }),
  on(authUser['[authUser]AuthUserFailure'], (state, { data }) => {
    return {
      ...state,
      err: data,
    };
  }),
  on(UserResetPass['[UserResetPass]UserResetPassSuccess'], (state, { data }) => {
    return {
      ...state,
      data: data,
    };
  }),
  on(authUser['[authUser]ClearErrors'], (state) => {
    return {
      ...state,
      err: null,
    };
  })
);
