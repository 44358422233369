import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { appState } from 'src/app';
import { AnimationActions } from '../../store/animation/actions/animation.actions';
import { selectHelpers } from '../../store/helpers/selector/helpers.selectors';
import { Observable } from 'rxjs';
import { selectSidebar } from '../../store/animation/selector/animation.selectors';
import { LocalStorageService } from '../localStorage/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  helpers$: Observable<any> = this.store.select(selectHelpers);
  animation$: Observable<any> = this.store.select(selectSidebar);

  constructor(
    private router: Router,
    private store: Store<appState>,
    private storage: LocalStorageService
  ) {}

  navigateTo(route: string, params?: any) {
    if (route.indexOf('http') > -1) {
      window.open(route, '_blank');
      return;
    }

    const navigationExtras: NavigationExtras = {
      queryParams: params,
    };
    route = route.toLowerCase();
    if (route === '') return;
    if (route.indexOf('aside:') > -1) {
      const routeSide = route.replace('/(aside:', '').replace(')', '').split('/');
      try {
        this.router.navigate(
          [
            '',
            {
              outlets: {
                aside: routeSide,
              },
            },
          ],
          navigationExtras
        );
      } catch (error) {
        return;
      }
      return;
    }
    try {
      this.router
        .navigate([
          '',
          {
            outlets: {
              aside: null,
            },
          },
        ])
        .then(() => {
          this.helpers$
            .subscribe((res: any) => {
              if (res?.isMobile) this.closeSideNav();
              this.router.navigate([route], navigationExtras);
            })
            .unsubscribe();
        });
    } catch (error) {
      return;
    }
  }

  closeSideNav() {
    setTimeout(() => {
      this.store.dispatch(AnimationActions['[animation]TriggerMenuClose']());
    }, 50);
  }

  clearAside() {
    this.router.navigate([
      '',
      {
        outlets: {
          aside: null,
        },
      },
    ]);
  }

  getCuretRoute() {
    return this.router.url;
  }

  getQueryParams() {
    return this.router.parseUrl(this.router.url).queryParams;
  }

  navigateByOrder(primaryRoute: string, secondaryRoute: string) {
    try {
      this.router.navigate([primaryRoute]).then(() => {
        this.navigateTo(secondaryRoute);
      });
    } catch (error) {
      return;
    }
  }

  removeQueryParams() {
    const navigationExtras: NavigationExtras = {
      queryParams: {},
    };
    this.router.navigate([], navigationExtras);
  }
  checkIfLinkActive(link: string, url: string): boolean {
    if (url.includes('/(')) {
      url = url.split('/(')[1];
    }
    if (url?.includes('aside:rewards/promotions') && link?.includes('aside:rewards/promotions')) {
      return true;
    } else if (
      url?.includes('rewards') &&
      !link?.includes('aside:rewards/promotions') &&
      !url?.includes('aside:rewards/promotions') &&
      link?.includes('rewards')
    ) {
      return true;
    }
    return link?.includes(url.split(')')[0]) && url !== '' && !url?.includes('rewards');
  }
  checkIfContentNavActive(link: string, url: string) {
    const segments = link.split('/').pop();
    return segments ? url.trim().toLowerCase().endsWith(`/${segments.trim().toLowerCase()}`) : false;
  }
}
