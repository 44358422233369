import { createReducer, on } from '@ngrx/store';
import { transferLoyaltydataActions } from '../actions/loyalty.actions';

export const transferDataFeatureKey = 'transferData';
export interface State {
  getCalendarData: {
    sidenavdata: boolean;
    calendardata: boolean;
    ranksdata: boolean;
    bankdata: boolean;
  };
  isLoaded: boolean;
}

export const initialState: State = {
  getCalendarData: { sidenavdata: false, calendardata: false, ranksdata: false, bankdata: false },
  isLoaded: false,
};

export const reducer = createReducer(
  initialState,
  on(transferLoyaltydataActions['[TransferData]SetRequesDataSuccess'], (state, action) => {
    return {
      ...state,
      getCalendarData: action.data,
      isLoaded: true,
    };
  })
);
