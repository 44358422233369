import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { LocalStorageService } from '../localStorage/local-storage.service';
import { appState } from 'src/app';
import { Store } from '@ngrx/store';
import { HelpersActions } from '../../store/helpers/actions/helpers.actions';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor(
    private translocoService: TranslocoService,
    private storage: LocalStorageService,
    private store: Store<appState>
  ) {}

  setLanguage(lang: string) {
    if (lang == '') return;
    this.store.dispatch(
      HelpersActions['[helpers]SetCurrentLanguage']({
        data: lang,
      })
    );
    this.storage.setLocalStorage('savedLang', lang);
    this.translocoService.setActiveLang(lang);
  }

  getAvailableLanguages() {
    return this.translocoService.getAvailableLangs();
  }

  setInitialPreferedLanguage(browserLang: Array<string>) {
    const savedLang: string | null = this.storage.getLocalStorage('preferedLanguage');
    if (savedLang !== null) {
      this.setLanguage(savedLang);
      return;
    }
    const getAvailableLangs: Array<unknown> = this.getAvailableLanguages();
    let preferedLang: string = browserLang.filter((lang) => getAvailableLangs.includes(lang))[0];
    if (preferedLang === undefined) {
      preferedLang = 'en';
    }
    this.setLanguage(preferedLang);
  }
}
