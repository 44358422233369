import { Component, ElementRef, HostListener, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Store } from '@ngrx/store';
import { appState } from '.';
import { BrowserReloadsActions } from './core/store/browser/actions/browser-reloads.actions';
import { selectConfig } from './core/store/config/selector/config.selectors';
import { Observable, Subject, delay, takeUntil, tap, timer } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { HelpersActions } from './core/store/helpers/actions/helpers.actions';
import { HelpersService } from './core/services/helpers/helpers.service';
import { LanguageService } from './core/services/language/language.service';
import { authUser, GetUser } from './core/store/user/actions/user.actions';
import { selectUser } from './core/store/user/selectors/user.selector';
import { GetBonuses } from './core/store/bonuses/actions/bonus.actions';
import { GetUserInfo } from './core/store/user/actions/userInfo.action';
import { LocalStorageService } from './core/services/localStorage/local-storage.service';
import { CookieService } from './core/services/cookies/cookie.service';
import { LoyaltyService } from './core/services/loyalty/loyalty.service';
import { MetadataService } from './core/services/metabase/metadata.service';
import { LoyaltyActions } from './core/store/loyalty/actions/loyalty.actions';
import { BonusesService } from './core/services/bonuses/bonuses.service';
import { GamesService } from './core/services/gameCatalog/games.service';
import { languages } from './core/interfaces/interfaces';
import { FinancesService } from './core/services/finances/finances.service';
import * as Sentry from '@sentry/angular';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChildren('checkboxes')
  checkboxes!: QueryList<ElementRef>;

  delay: ReturnType<typeof setTimeout> = setTimeout(() => {});
  width: number = 0;

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (window.innerWidth === this.width) {
      return;
    }
    this.width = window.innerWidth;
    clearTimeout(this.delay);
    this.delay = setTimeout(() => {
      this.isMobile = window.innerWidth < 786 ? true : false;
      this.store.dispatch(
        HelpersActions['[helpers]SetIsMobileStatus']({
          data: this.isMobile,
        })
      );
      this.store.dispatch(
        HelpersActions['[helpers]SetScreenWidth']({
          data: window.innerWidth,
        })
      );
    }, 100);
  }

  isOpen: boolean = false;

  title: string = 'test';
  currentLang: string = 'en';

  config$: Observable<any> = this.store.select(selectConfig);
  user$: Observable<any> = this.store.select(selectUser);

  unsubus$: Subject<boolean> = new Subject();
  unsubusUser$: Subject<boolean> = new Subject();

  isMobile: boolean = true;
  isAuth: boolean = false;

  preloaderAnimate: boolean = false;
  preloader: boolean = true;
  onceLoyalty: boolean = true;
  onceLoyaltyRegister: boolean = true;
  once: boolean = true;
  onceSocket: boolean = true;
  userCurrency: string = '';
  onceSentry: boolean = true;
  constructor(
    private store: Store<appState>,
    public activatedRoute: ActivatedRoute,
    private helpers: HelpersService,
    private language: LanguageService,
    private storage: LocalStorageService,
    private cookie: CookieService,
    private loyaltyService: LoyaltyService,
    private metadateService: MetadataService,
    private games: GamesService,
    private bonusService: BonusesService,
    private finance: FinancesService
  ) {
    this.onResize();
    if (document.readyState == 'complete') {
      this.preloadHide();
    }
    window.onload = () => {
      this.preloadHide();
    };
    const browserLang: Array<string> = this.helpers.getBrowserlanguages();
    this.language.setInitialPreferedLanguage(this.helpers.getCookie('_aff')?.includes('174') ? ['en'] : browserLang);
    this.store.dispatch(
      HelpersActions['[helpers]SetBrowserName']({
        data: this.helpers.detectBrowser(),
      })
    );
    this.store.dispatch(
      HelpersActions['[helpers]GetBrowserPreferedLanguages']({
        data: browserLang,
      })
    );
    this.store.dispatch(BrowserReloadsActions['[BrowserActions]BrowserReloadss']());
  }

  themeSwitcher() {
    throw new Error('Sentry Test Error');
    // if (localStorage.theme === 'dark' ? true : false) {
    //   localStorage.theme = 'light';
    //   document.documentElement.classList.remove('dark');
    // } else {
    //   localStorage.theme = 'dark';
    //   document.documentElement.classList.add('dark');
    // }
  }

  preloadHide() {
    const preloader = document.getElementById('preloader');
    timer(500)
      .pipe(
        tap(() => {
          const testHide = document.getElementById('testHide');
          testHide?.classList.add('preloader-hide');
          setTimeout(() => {
            testHide?.remove();
          }, 2000);
          preloader?.classList.add('preloader-hide');
        }),
        delay(500)
      )
      .subscribe(() => preloader?.remove());
  }

  ngOnInit() {
    this.store.dispatch(GetUser['[GetUser]GetUser']());
    const token = sessionStorage.getItem('ACCESS_TOKEN');
    if (token) {
      this.store.dispatch(authUser['[authUser]SetDataFromToken'](JSON.parse(atob(token.split('.')[1]))));
    }

    this.helpers.disablePseudoElementsIPhone();
    this.helpers.blockDoubleTapIPhone();
    this.games.initFilterFromStorageToStore();
    const bonusLoop = timer(0, 15000);
    this.connectLoyaltySocket();
    this.user$.pipe(takeUntil(this.unsubusUser$)).subscribe((res: any) => {
      if (res.loaded && res.isAuth && res.userProfile?.data && res?.tokenData) {
        this.loyaltyService.sendSocketUserId(res?.tokenData?.userId);
        if (this.onceSentry) {
          this.onceSentry = false;
          Sentry.setUser({ email: res?.userProfile?.data?.email });
        }

        this.store.dispatch(LoyaltyActions['[Loyalty]GetCombinedLoyaltyData']());
        this.store.dispatch(LoyaltyActions['[Loyalty]GetWellBetAndReefback']());
        this.store.dispatch(LoyaltyActions['[Loyalty]GetFinishedQuestsCount']());
        this.finance
          .getReefBack(res?.userProfile?.data?.currency, 'EUR', 1)
          .pipe(takeUntil(this.unsubus$))
          .subscribe((res: any) => {
            if (res) {
              this.storage.setSessionStorage('exchangeRate', res?.rate);
            }
          });
        this.unsubusUser$.next(true);
        this.unsubusUser$.complete();
      }
    });
    this.config$.pipe(takeUntil(this.unsubus$)).subscribe((res) => {
      if (res?.isLoaded) {
        if (res?.config?.user) {
          if (this.onceSentry) {
            this.onceSentry = false;
            Sentry.setUser({ email: res.config.user.Email });
          }

          const lang =
            this.helpers.getCookie('_aff')?.includes('174') || res?.config?.user?.AffiliateID === '70012640'
              ? 'en'
              : this.helpers.getCookie('userlang') || res?.config.user?.loyalty?.Language;
          this.language.setLanguage(lang);
          this.cookie.setCookie('userlang', lang, '/');
          this.games
            .getGamesFromFavoriteList()
            .pipe(takeUntil(this.unsubus$))
            .subscribe((response: any) => {
              const favoriteGameIds = response.map((game: { game_id: string }) => game.game_id);
              this.storage.setLocalStorage('favGames', JSON.stringify(favoriteGameIds));
            });
        } else {
          const userlangCookie = this.helpers.getCookie('userlang');
          if (userlangCookie) {
            this.language.setLanguage(userlangCookie);
          }
        }
        if (!this.storage.getSessionStorage('sortCurrencies')) {
          this.finance.setSortedCurrencies(res?.config?.currencies, res?.config?.ipCountry);
        }
        this.storage.setSessionStorage('country', res?.config?.ipCountry);
        this.store.dispatch(
          HelpersActions['[helpers]SetPossibleLanguages']({
            data: res?.config?.status === 451 ? languages : res?.config?.languages,
          })
        );

        this.store.dispatch(
          HelpersActions['[helpers]SetCurrentCountry']({
            data: res?.config?.ipCountry,
          })
        );
      }
    });
    this.user$.pipe(takeUntil(this.unsubus$)).subscribe((res: any) => {
      if (res?.loaded) {
        const countryCode = res?.userProfile?.data?.countryIso3;
        if (res.isAuth) {
          this.getLoyaltyData();
          const activeBonus =
            res?.userInfo?.data?.loyalty?.ActiveBonuses &&
            Object.keys(res?.userInfo?.data?.loyalty?.ActiveBonuses).length > 0
              ? JSON.stringify(res?.userInfo?.data?.loyalty?.ActiveBonuses)
              : '';
          this.loyaltyService.sendSocketActiveBonus(
            !!activeBonus && this.bonusService.checkIfBonusIsWelcome(activeBonus),
            res?.userInfo?.data?.idUser
          );
        }
        countryCode
          ? this.storage.setSessionStorage('countryFromProfile', countryCode)
          : this.storage.removeSessionStorage('countryFromProfile');

        this.isAuth = res.isAuth;
        if (this.once && this.isAuth) {
          this.store.dispatch(GetBonuses['[GetBonuses]GetBonuses']());
          this.metadateService.addTags(
            res?.userProfile?.data,
            res?.userInfo?.data.Tags,
            'welleTags',
            this.helpers.getBirthdayDate(
              res?.userProfile?.data?.birthDay,
              res?.userProfile?.data?.birthMonth,
              res?.userProfile?.data?.birthYear
            ),
            res?.userInfo?.data?.idUser
          );
          this.metadateService.syncTags(res?.userProfile?.data);
          this.once = false;
        }
      }
    });

    bonusLoop.subscribe(() => {
      if (this.isAuth) {
        this.store.dispatch(GetUserInfo['[GetUserInfo]GetUserInfo']());
        this.store.dispatch(GetBonuses['[GetBonuses]GetBonuses']());
      }
    });
  }
  getLoyaltyData() {
    this.store.dispatch(LoyaltyActions['[Loyalty]GetCombinedLoyaltyData']());
    this.store.dispatch(LoyaltyActions['[Loyalty]GetWellBetAndReefback']());
    this.store.dispatch(LoyaltyActions['[Loyalty]GetFinishedQuestsCount']());
    this.finance
      .getReefBack(this.userCurrency, 'EUR', 1)
      .pipe(takeUntil(this.unsubus$))
      .subscribe((res: any) => {
        if (res) {
          this.storage.setSessionStorage('exchangeRate', res?.rate);
        }
      });
  }

  connectLoyaltySocket() {
    this.loyaltyService.connect('https://staging-api.coduluz.com').subscribe({
      next: (res) => this.loyaltyService.addDataFromSocket(res),
      error: (err) => console.error('WebSocket error:', err),
    });
  }
  ngOnDestroy() {
    this.unsubus$.next(true);
    this.unsubus$.complete();
  }
}
