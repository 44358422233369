/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import type { Rectangle, Texture } from 'pixi.js';
import { Sprite } from 'pixi.js';

export class BunnyV8 {
  view: Sprite;

  gravity = 0;
  speedX = Math.random() * 5;
  speedY = Math.random() * 1 - 2;
  positionX = 0;
  positionY = 0;

  bounds: Rectangle;

  constructor(texture: Texture, bounds: Rectangle) {
    this.view = new Sprite(texture);
    this.view.scale.set(Math.random() * 1 + 0.5);
    this.view.anchor.set(0.5, 1);
    this.bounds = bounds;
  }

  public update() {
    const random = Math.random();
    let pX = this.positionX;
    let pY = this.positionY;

    pX += Math.sin(pY * 0.01) * 2;
    pY += this.speedY;
    this.speedY += this.gravity;

    if (pX > this.bounds.right) {
      pX = this.bounds.left + 250;
    } else if (pX < this.bounds.left) {
      pX = this.bounds.right + 250;
    }

    if (pY > this.bounds.bottom) {
      this.speedY *= -0.85;
      pY = this.bounds.bottom;
      if (random > 0.5) {
        this.speedY -= random * 6;
      }
    } else if (pY < this.bounds.top) {
      pY = this.bounds.bottom;
    }

    this.view.position.x = this.positionX = pX;
    this.view.position.y = this.positionY = pY;
  }

  getRandomInt(min: any, max: any) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  reset() {
    this.positionX = this.getRandomInt(this.bounds.left, this.bounds.right);
    this.positionY = this.getRandomInt(this.bounds.top, this.bounds.bottom);
  }
}
