import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WebSocketSubject } from 'rxjs/webSocket';

@Injectable({
  providedIn: 'root',
})
export class WebSoketsService {
  private socket$: WebSocketSubject<any> = new WebSocketSubject('');
  constructor() {}

  connect(url: string): Observable<any> {
    this.socket$ = new WebSocketSubject({
      url: url,
      deserializer: ({ data }) => data,
    });
    return new Observable((observer: any) => {
      this.socket$.subscribe({
        next: (res: any) => {
          observer.next(JSON?.parse(res));
        },
        error: (err: any) => {
          observer.error(err);
        },
        complete: () => {
          observer.complete();
        },
      });
    });
  }
  send(data: any) {
    this.socket$.next(JSON.stringify(data));
  }

  disconnect() {
    if (this.socket$) {
      this.socket$.complete();
    }
  }
}
