<div class="countries-container w-full h-full flex flex-col px-2 pb-6">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <app-text-field
      formControlName="stateName"
      [isSearch]="true"
      [showLabel]="false"
      [isLight]="true"
      (searchEmiter)="search($event)"
      id="state"></app-text-field>
  </form>
  <ng-container *ngIf="viewInit; else loader">
    <div
      *ngFor="let item of filtredState; let last = last"
      class="country relative w-full p-2"
      [ngClass]="last ? '' : 'border-b-[1px] dark:border-line border-mainBgLighter'">
      <div class="absolute w-16 h-[2px] left-0 -bottom-[2px] dark:bg-containerBg bg-containerBgLight"></div>
      <label
        [class]="
          'inner-container flex items-center gap-4 rounded-xl px-4 hover:dark:bg-mainHover  hover:bg-skyBtnHoverLight active:dark:bg-blueBgColor active:bg-blueBgColor transition-colors ' +
          (item?.title === stateSelected?.title ? ' bg-blueBgColor ' : '')
        ">
        <label class="flex justify-between items-center w-full rounded-xl cursor-pointer py-3 form-custom-check">
          <span class="dark:text-mainText text-mainTextLight text-sm leading-7 font-semibold">
            {{ item.title }}
          </span>
          <div class="flex gap-6 items-center">
            <input
              (click)="selectState(item)"
              type="checkbox"
              class="form-custom-check-state"
              [checked]="item?.title === stateSelected?.title"
              [id]="item.title + 'Checkbox'" />
          </div>
        </label>
      </label>
    </div>
  </ng-container>
  <ng-template #loader>
    <div class="min-h-20">
      <app-inner-loader></app-inner-loader>
    </div>
  </ng-template>
</div>
