<div class="w-full box-shadow p-2 pb-3" *transloco="let t">
  <div class="w-full flex-col flex gap-3 px-6 pb-3 text-mainTextLight text-sm" [innerHTML]="text"></div>

  <div class="w-full flex-col flex gap-3 px-6 pb-6 text-mainTextLight text-sm font-normal">
    <div class="flex gap-3">
      <input class="form-custom-check-input" type="checkbox" (click)="setChecked()" />
      <p>{{ t('tournaments.ruleAccept') }}</p>
    </div>

    <button
      (click)="state ? setParticipation() : leaveTournament()"
      [class]="
        'font-semibold text-base mt-6 bg-mainBtnLight hover:bg-mainHoverLight active:bg-[#EAB308] h-14 md:h-[2.625rem] lg:h-[2.875rem] xl:h-14 rounded-xl' +
        (isDisabled ? ' pointer-events-none opacity-50 ' : '')
      ">
      {{ state ? t('tournaments.play') : t('tournaments.leave') }}
    </button>
  </div>
</div>
