import { createFeatureSelector, createSelector } from '@ngrx/store';
import { appState } from 'src/app';
import { UserAuth } from '../reducers/auth.reducer';
import { saveUserKey } from '../reducers/saveUser.reducer';
import { UserBalanceKey } from '../reducers/userBalance.reducer';
export const getUserState = createFeatureSelector<appState>(UserAuth);
export const selectUser = createFeatureSelector(saveUserKey);
export const selectUserBalances = createFeatureSelector(UserBalanceKey);

export const userTags = createSelector(selectUser, (state: any) => state.userInfo.data.Tags);
