/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GamesQueryService {
  constructor() {}

  getGameBySlugQuery(value: string, take: any, skip: string, country?: string) {
    return {
      query: `query { gamesByCategory(slug: "${value}", take: ${take}, skip: ${skip}) {  active activeIntegration backgroundImage hasFreeSpins id image isBonusBuy isMegaways maxBet merchantId minBet restrictionListId softgamingId subMerchantId translations { language name } Likes { likes } merchant { id image name parentId slug sort } } }`,
    };
  }

  getGamesBySlugAndFilter(take: number, skip: string, categories?: any, merchants?: any, country?: string) {
    return {
      query: `{ game( take: ${take}, skip: ${skip}, orderby: [{ merchantSort: { sort: desc } }, { sort: { sort: desc } }], where: { AND: [ { merchantId: { in: [${merchants}] } }, { categories: { some: { category: { is: { slug: { equals: "${categories}" } } } } } }, { countryRestriction: { is: { countries: { none: { country: { equals: "${country}", mode: insensitive } } } } } }, { isGameActive: { equals: true } }   ] } ) { id hasDemo isVirtual merchant { name, alias } gameCategories { slug } image imageFullPath merchantId sort pageCodes { launch mobileUrl url default external mobileExternal mobileAndroid mobileExternal mobileWindows } parentMerchantId text { name_en } translations { name description language } Likes { likes }merchant { id image name parentId slug sort } } }`,
    };
  }
  getGamesBySlugAndName(take: number, skip: string, categories: any, search: string, country?: string) {
    return {
      query: `{ game(take: ${take}, skip: ${skip}, where: { translations: { some: { name: { contains: "${search}" } } }, category: { some: { slug: { equals: "${categories}" } } } }) {  active activeIntegration backgroundImage hasFreeSpins id image isBonusBuy isMegaways maxBet merchantId minBet restrictionListId softgamingId subMerchantId translations { name description language } Likes { likes } merchant { id image name parentId slug sort } } }`,
    };
  }

  getGamesByFilterAndName(take: number, skip: string, merchants: any, search: string, country?: string) {
    return {
      query: `{ game( take: ${take}, skip: ${skip}, orderby: [{ merchantSort: { sort: desc } }, { sort: { sort: desc } }], where: { translations: { some: { name: { contains: "${search}" } } }, { merchantId: { in: [${merchants}] } },   {  restrictions: { is: { countries: { some: { iso3: { equals: "${country}" } } } } }}}, { isGameActive: { equals: true } }   ] } ) { id hasDemo isVirtual merchant { name, alias } gameCategories { slug } image imageFullPath merchantId sort pageCodes { launch mobileUrl url default external mobileExternal mobileAndroid mobileExternal mobileWindows } parentMerchantId text { name_en } Likes { likes } merchant { id image name parentId slug sort } translations { name description language }  } }`,
    };
  }

  getAllGames(take: number, skip: string, country?: string) {
    // where: {  restrictions: { is: { countries: { some: { iso3: { equals: "${country}" } } } } }}
    return {
      query: `query { game(take: ${take}, skip: ${skip}  ) { active activeIntegration backgroundImage hasFreeSpins id image isBonusBuy isMegaways maxBet merchantId minBet restrictionListId softgamingId subMerchantId translations { language name } Likes { likes } merchant { id image name parentId slug sort }} }`,
    };
  }
  getAllSlugQuery(lang: any) {
    return { query: `{ category { id isVisible slug sort translationId translation { language name } } }` };
  }
  getAllMerchants(country: any) {
    return { query: `{ merchant(orderby: { sort: asc }) { id image name parentId slug sort } }` };
  }
  getGamesByMerchantId(take: number, skip: string, merchantId: any, country?: string) {
    return {
      query: `{ game(take: ${take}, skip: ${skip}, where: { merchantId: { in: [${merchantId}] } }) {  active activeIntegration backgroundImage hasFreeSpins id image isBonusBuy isMegaways maxBet merchantId minBet restrictionListId softgamingId subMerchantId translations { name description language } category { id isVisible slug sort translationId } Likes { likes } merchant { id image name parentId slug sort }} }`,
    };
  }
  searchGameByNameQuery(take: number, skip: string, value: string, country: string) {
    return {
      query: `query { game(take: ${take}, skip: ${skip}, where: { translations: { some: { name: { contains: "${value}" } } } }) {  active activeIntegration backgroundImage hasFreeSpins id image isBonusBuy isMegaways maxBet merchantId minBet restrictionListId softgamingId subMerchantId translations { name description language } category { id isVisible slug sort translationId } Likes { likes } merchant { id image name parentId slug sort }} }`,
    };
  }
  getGamesByAllFilters(take: number, skip: string, categories: any, merchants: any, country: string, search: string) {
    return {
      query: `{ game(take: ${take}, skip: ${skip}, where: { translations: { some: { name: { contains: "${search}" } } }, merchantId: { in: [${merchants}] }, category: { some: { slug: { equals: "${categories}" } } } }) {  active activeIntegration backgroundImage hasFreeSpins id image isBonusBuy isMegaways maxBet merchantId minBet restrictionListId softgamingId subMerchantId translations { name description language } category { id isVisible slug sort translationId } Likes { likes } merchant { id image name parentId slug sort } } }`,
    };
  }
  getGameByIdsQuery(take: number, skip: string, array: string, country?: string) {
    return {
      query: `{game(take: ${take}, skip: ${skip}, where: { id: { in: [${array
        .split(',')
        .map((id) => `"${id.trim()}"`)
        .join(
          ','
        )}] } }) {  active activeIntegration backgroundImage hasFreeSpins id image isBonusBuy isMegaways maxBet merchantId minBet restrictionListId softgamingId subMerchantId translations { name description language } category { id isVisible slug sort translationId } Likes { likes } merchant { id image name parentId slug sort } } }`,
    };
  }
  getGameByIdsQueryAndFilter(take: number, skip: string, array: string, merchantids: any, country?: string) {
    const idsList: any = array
      ?.split(',')
      .map((id) => `"${id.trim()}"`)
      .join(',');
    return {
      query: `{ game(take: ${take}, skip: ${skip}, where: { id: { in: [${idsList}] }, merchantId: { in: [${merchantids}] } }) {  active activeIntegration backgroundImage hasFreeSpins id image isBonusBuy isMegaways maxBet merchantId minBet restrictionListId softgamingId subMerchantId translations { name description language } category { id isVisible slug sort translationId } Likes { likes } merchant { id image name parentId slug sort } } }`,
    };
  }

  getGameByIdsQueryAndSearch(take: number, skip: string, array: string, search: string, country?: string) {
    const idsList = array
      ?.split(',')
      .map((id) => `"${id.trim()}"`)
      .join(',');
    return {
      query: `query { game(take: ${take}, skip: ${skip}, where: { translations: { some: { name: { contains: "${search}" } } }, id: { in: [${idsList}] } }) { RTP active activeIntegration backgroundImage hasFreeSpins id image isBonusBuy isMegaways maxBet merchantId minBet restrictionListId softgamingId subMerchantId Likes { likes } merchant { id image name parentId slug sort } } }`,
    };
  }
  getGameByIdsQueryAndAllFilter(
    take: number,
    skip: string,
    array: string,
    search: string,
    filter: any,
    country?: string
  ) {
    let idsList: any = array?.split(',');
    idsList = '"' + idsList?.join('","') + '"';
    return {
      query: `{game(take: ${take}, skip: ${skip}, where: { translations: { some: { name: { contains: "${search}" } } }, id: { in: [${idsList}] }, merchantId: { in: [${filter}] } }) { RTP active activeIntegration backgroundImage hasFreeSpins id image isBonusBuy isMegaways maxBet merchantId minBet restrictionListId softgamingId subMerchantId Likes { likes } merchant { id image name parentId slug sort } } }`,
    };
  }
  getGameByIdQuery(value: string, country?: string) {
    return {
      query: `query { game(where: { id: { equals: ${value} } }) { RTP active activeIntegration backgroundImage hasFreeSpins id image isBonusBuy isMegaways maxBet merchantId minBet restrictionListId softgamingId subMerchantId translations { name language } Likes { likes } merchant { id image name parentId slug sort } } }`,
    };
  }
  getGameIdsByCategory(slug: string) {
    return {
      query: `{gamesByCategory(slug:"${slug}"){id}}`,
    };
  }
}
/*
// This is your Prisma schema file,
// learn more about it in the docs: https://pris.ly/d/prisma-schema

generator nestgraphql {
  provider = "node node_modules/prisma-nestjs-graphql"
  output   = "../src/@generated/prisma-nestjs-graphql"
  // previewFeatures = ["orderByRelation", "referentialActions"]
}

datasource db {
  provider = "postgresql"
  url      = env("DATABASE_URL")
}

generator client {
  provider = "prisma-client-js"
}

model User {
  id    Int     @id @default(autoincrement())
  email String  @unique
  name  String?
}

model CategoryTranslation {
  id         Int      @id @default(autoincrement())
  category   Category @relation(fields: [categoryId], references: [id], onDelete: Cascade)
  categoryId Int      @unique
  name_bg    String   @default("")
  name_da    String   @default("")
  name_de    String   @default("")
  name_ee    String   @default("")
  name_el    String   @default("")
  name_en    String   @default("")
  name_es    String   @default("")
  name_fi    String   @default("")
  name_fr    String   @default("")
  name_id    String   @default("")
  name_it    String   @default("")
  name_gr    String   @default("")
  name_ja    String   @default("")
  name_ko    String   @default("")
  name_lt    String   @default("")
  name_nl    String   @default("")
  name_no    String   @default("")
  name_pl    String   @default("")
  name_pt    String   @default("")
  name_ro    String   @default("")
  name_ru    String   @default("")
  name_sk    String   @default("")
  name_sv    String   @default("")
  name_th    String   @default("")
  name_tr    String   @default("")
  name_ua    String   @default("")
  name_uk    String   @default("")
  name_nb    String   @default("")
  name_vi    String   @default("")
  name_zh    String   @default("")
  name_hi    String   @default("")
}

model Category {
  id            Int                  @id
  text          CategoryTranslation?
  cSort         Int
  cSubSort      Int
  slug          String
  customSort    String[]
  menuId        String
  games         CategoriesOnGames[]
  categoryGames Game[]
  tags          TagsOnCategories[]
}

model Tag {
  id         Int                @id @default(autoincrement())
  name       String             @unique
  categories TagsOnCategories[]
}

model TagsOnCategories {
  category   Category @relation(fields: [categoryId], references: [id], onDelete: Cascade)
  categoryId Int // relation scalar field (used in the @relation attribute above)
  tag        Tag?     @relation(fields: [tagId], references: [id], onDelete: Cascade)
  tagId      Int // relation scalar field (used in the @relation attribute above)

  @@id([categoryId, tagId])
}

model CategoriesOnGames {
  game       Game     @relation(fields: [gameId], references: [id], onDelete: Cascade)
  gameId     String // relation scalar field (used in the @relation attribute above)
  category   Category @relation(fields: [categoryId], references: [id], onDelete: Cascade)
  categoryId Int // relation scalar field (used in the @relation attribute above)
  sort       Int?

  @@id([gameId, categoryId])
}

Mi, [11/09/2024 15:33]
model GameTranslation {
  id             Int     @id @default(autoincrement())
  game           Game    @relation(fields: [gameId], references: [id], onDelete: Cascade)
  gameId         String  @unique
  name_bg        String  @default("")
  name_da        String  @default("")
  name_de        String  @default("")
  name_ee        String  @default("")
  name_el        String  @default("")
  name_en        String  @default("")
  name_es        String  @default("")
  name_fi        String  @default("")
  name_fr        String  @default("")
  name_id        String  @default("")
  name_it        String  @default("")
  name_gr        String  @default("")
  name_ja        String  @default("")
  name_ko        String  @default("")
  name_lt        String  @default("")
  name_nb        String  @default("")
  name_nl        String  @default("")
  name_no        String  @default("")
  name_pl        String  @default("")
  name_pt        String  @default("")
  name_ro        String  @default("")
  name_ru        String  @default("")
  name_sk        String  @default("")
  name_sv        String  @default("")
  name_th        String  @default("")
  name_tr        String  @default("")
  name_ua        String  @default("")
  name_uk        String  @default("")
  name_vi        String  @default("")
  name_zh        String  @default("")
  name_hi        String  @default("")
  description_bg String?
  description_da String?
  description_de String?
  description_ee String?
  description_el String?
  description_en String?
  description_es String?
  description_fi String?
  description_fr String?
  description_id String?
  description_it String?
  description_gr String?
  description_ja String?
  description_ko String?
  description_lt String?
  description_nb String?
  description_nl String?
  description_no String?
  description_pl String?
  description_pt String?
  description_ro String?
  description_ru String?
  description_sk String?
  description_sv String?
  description_th String?
  description_tr String?
  description_ua String?
  description_uk String?
  description_vi String?
  description_zh String?
  description_hi String?
}

model PageCodes {
  id             Int     @id @default(autoincrement())
  url            String
  mobileUrl      String?
  default        String
  mobile         String?
  mobileAndroid  String?
  mobileWindows  String?
  external       String?
  mobileExternal String?
  launch         String
  game           Game?
}

Mi, [11/09/2024 15:33]
model Game {
  id                   String              @id
  categories           CategoriesOnGames[]
  gameCategories       Category[]
  text                 GameTranslation?
  image                String?
  imageFullPath        String?
  imageMobile          String?
  background           String?
  branded              Boolean
  superBranded         Boolean
  hasDemo              Boolean
  merchant             Merchant            @relation(name: "merchant", fields: [merchantId], references: [id], onDelete: Cascade)
  merchantId           Int // relation scalar field (used in the @relation attribute above)
  parentMerchantId     Int? // relation scalar field (used in the `@relation)
  subMerchant          Merchant?           @relation(name: "subMerchant", fields: [subMerchantId], references: [id], onDelete: Cascade)
  subMerchantId        Int? // relation scalar field (used in the `@relation` attribute above)
  aR                   String?
  countryRestriction   CountryRestriction? @relation(fields: [countryRestrictionId], references: [id], onDelete: Cascade)
  countryRestrictionId Int? // relation scalar field (used in the @relation attribute above)
  sort                 Int?
  merchantSort         Int?
  pageCodes            PageCodes           @relation(fields: [pageCodesId], references: [id], onDelete: Cascade)
  pageCodesId          Int                 @unique // relation scalar field (used in the @relation attribute above)
  workingHours         String?
  isVirtual            Boolean
  tableID              String
                    String?
  freeround            Boolean?
  isRestricted         Boolean
  Likes                Likes?
  minBetDefault        Float?
  maxBetDefault        Float?
  maxMultiplier        Float?
  bonusBuy             Boolean?
  megaways             Boolean?
  freespins            Boolean?
  freeBonus            Boolean?
}

model Likes {
  id     Int     @id @default(autoincrement())
  gameId String? @unique
  likes  Int     @default(0)
  game   Game?   @relation(fields: [gameId], references: [id], onDelete: Cascade)

  @@index([gameId])
}

model Merchant {
  id         Int                     @id
  name       String
  alias      String
  image      String
  weight     Int
  menuId     String
  parent     Merchant?               @relation(name: "parent", fields: [parentId], references: [id], onDelete: Cascade)
  parentId   Int?
  gamesCount Int?
  childs     Merchant[]              @relation(name: "parent")
  games      Game[]                  @relation(name: "merchant")
  subGames   Game[]                  @relation(name: "subMerchant")
  currencies CurrenciesOnMerchants[]
}

model CountryRestriction {
  id            Int                         @id
  name          String
  isDefault     Boolean
  apiTemplateId Int?
  countries     CountryRestrictionCountry[]
  games         Game[]
}

model CountryRestrictionCountry {
  id                   Int                @id @default(autoincrement())
  country              String
  countryRestriction   CountryRestriction @relation(fields: [countryRestrictionId], references: [id], onDelete: Cascade)
  countryRestrictionId Int // relation scalar field (used in the @relation attribute above)
}

model Currency {
  id        Int                     @id @default(autoincrement())
  name      String                  @unique
  merchants CurrenciesOnMerchants[]
}

model CurrenciesOnMerchants {
  merchant   Merchant @relation(fields: [merchantId], references: [id], onDelete: Cascade)
  merchantId Int // relation scalar field (used in the @relation attribute above)
  currency   Currency @relation(fields: [currencyId], references: [id], onDelete: Cascade)
  currencyId Int // relation scalar field (used in the @relation attribute above)

  @@id([merchantId, currencyId])
}
  */
