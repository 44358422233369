import { createReducer, on } from '@ngrx/store';
import { verificationActions } from '../actions/verification.actions';

export const verificationData = 'verificationData';

export interface State {
  isLoaded: boolean;
  data: any;
}

export const initialState: State = {
  isLoaded: false,
  data: null,
};
export const reducer = createReducer(
  initialState,
  on(verificationActions['[verification]SetVerificationStatusSuccess'], (state, { data }) => {
    return {
      ...state,
      isLoaded: true,
      data: data,
    };
  }),
  on(verificationActions['[verification]SetVerificationStatusFailure'], (state) => {
    return {
      ...state,
      isLoaded: true,
      data: null,
    };
  })
);
