<div class="bg-tooltipLight cursor-pointer group rounded-[0.625rem] z-[101]" *transloco="let t">
  <div class="flex items-center h-full">
    <div class="grid grid-cols-[1fr,25%,7%] items-center w-full gap-1 px-3 py-[0.56rem]">
      <p class="font-semibold text-sm text-white">{{ t('notifications.loyalty.learnMore') }}</p>
      <img
        (click)="closeModal()"
        [class]="'justify-self-end col-start-3 w-4 h-4 pointer-cursor'"
        src="https://cdn.chipychapa.com//welle/Video/Loyalty_page/close.svg" />
    </div>
  </div>
  <div
    class="max-h-[44rem] px-3 py-4 w-full rounded-b-[0.625rem] accordionShadow bg-biggerContainerBgLight overflow-hidden z-[7] relative flex items-center justify-center transition-all ease-in-out duration-500 flex-col gap-2">
    <div class="w-[90%] h-[90%] rounded-[0.688rem] bg-[#EAEAEA]">
      <div style="position: relative; padding-top: 56.25%">
        <iframe
          id="loyaltyVideo"
          src="https://customer-f510plc3941a75sb.cloudflarestream.com/41b00c9ed147ffd9fce350289643013e/iframe?preload=true&poster=https%3A%2F%2Fcustomer-f510plc3941a75sb.cloudflarestream.com%2F41b00c9ed147ffd9fce350289643013e%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600&controls=true"
          loading="lazy"
          style="border: none; border-radius: 11px; position: absolute; top: 0; left: 0; height: 100%; width: 100%"
          allow="accelerometer; gyroscope; encrypted-media; picture-in-picture;"
          allowfullscreen="true"></iframe>
      </div>
    </div>
  </div>
</div>
