import { createReducer, on } from '@ngrx/store';

import { WithdrawTransactionActions } from '../actions/withdraw-transactions.actions';

export const configFeatureKey = 'withdraw-transactions';

export interface State {
  withdraws: any[];
}

export const initialState: State = {
  withdraws: [],
};

export const reducer = createReducer(
  initialState,
  on(WithdrawTransactionActions['[withdraw-transactions]GetWithdrawTransactionInitialization'], (state) => {
    return {
      ...state,
    };
  }),
  on(WithdrawTransactionActions['[withdraw-transactions]GetWithdrawTransactionSuccess'], (state, action) => {
    return {
      ...state,
      withdraws: action.data,
    };
  }),
  on(WithdrawTransactionActions['[withdraw-transactions]GetWithdrawTransactionFail'], (state, action) => {
    return {
      ...state,
      withdraws: action.data,
    };
  })
);
