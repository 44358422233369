<div
  *transloco="let t"
  [class]="
    'w-full h-full p-4  relative flex flex-col gap-2 items-center text-sm ' +
    (openModal === 'notification' ? 'bg-[#FEFCE8]' : ' bg-white')
  ">
  <div class="absolute cursor-pointer w-6 h-6 right-3 top-3" (click)="close()">
    <img
      src="https://cdn.chipychapa.com//welle/General_site/Autharization/close_page.svg"
      width="16"
      height="16"
      alt="cross"
      id="close" />
  </div>
  <span class="font-semibold">
    {{ t('quest.modal.congratulations') }}
  </span>

  <span class="font-normal">
    {{ t(openModal === 'notification' ? 'quest.modal.notification.text' : 'quest.modal.you_received') }}
  </span>
  <div *ngIf="openModal === 'succesClaim'" class="flex flex-row gap-1 font-bold">
    <span>{{ text }}</span>
    <img
      src="https://cdn.chipychapa.com//welle/General_site/Quests/wellcoin_icon.svg"
      alt="wellcoin"
      width="16"
      height="9" />
  </div>
  <div
    (click)="navigateTo()"
    *ngIf="openModal === 'notification'"
    class="rounded-[10px] bg-mainBtnLight hover:bg-mainHoverLight w-full h-[34px] font-semibold flex-center cursor-pointer">
    {{ t('buttons.go_to_loyalty') }}
  </div>
</div>
