import { Pipe, PipeTransform } from '@angular/core';
import { getCurrencySymbol } from '@angular/common';
@Pipe({
  name: 'userCurrencyPipe',
  standalone: true,
})
export class UserCurrencyPipePipe implements PipeTransform {
  constructor() {}

  transform(code: string, format: 'wide' | 'narrow' = 'narrow', locale?: string): any {
    const prefix = code === 'CAD' ? 'C' : code === 'MXN' ? 'M' : code === 'AUD' ? 'A' : '';
    return prefix + getCurrencySymbol(code, format, locale);
  }
}
