"use strict";

const findHooksRx = /\{\{(.*?)\}\}/g;
function compileHooks(programSrc) {
  const parts = {};
  const partMatches = programSrc.match(findHooksRx)?.map(hook => hook.replace(/[{()}]/g, "")) ?? [];
  partMatches.forEach(hook => {
    parts[hook] = [];
  });
  return parts;
}
export { compileHooks, findHooksRx };
