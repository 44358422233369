import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const WithdrawTransactionActions = createActionGroup({
  source: 'withdraw-transactions',
  events: {
    '[withdraw-transactions] Get Withdraw Transaction Initialization': emptyProps(),
    '[withdraw-transactions] Get Withdraw Transaction Success': props<{
      data: any;
    }>(),
    '[withdraw-transactions] Get Withdraw Transaction Fail': props<{
      data: any;
    }>(),
  },
});
