import { createActionGroup, emptyProps } from '@ngrx/store';

export const AnimationActions = createActionGroup({
  source: 'Animation',
  events: {
    '[animation] Animation': emptyProps(),
    '[animation] Open Sitebar Success': emptyProps(),
    '[animation] Close Sitebar Success': emptyProps(),
    '[animation] Trigger Menu Close': emptyProps(),
  },
});
