import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_CANCEL, ROUTER_NAVIGATED, ROUTER_REQUEST } from '@ngrx/router-store';
import { map, tap } from 'rxjs';
import { RouterViewerActions } from '../actions/router.actions';
import { LocalStorageService } from 'src/app/core/services/localStorage/local-storage.service';

@Injectable()
export class RouterViewerEffects {
  constructor(
    private actions$: Actions,
    private storage: LocalStorageService
  ) {}

  requetsRouter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_REQUEST),
      map(() => RouterViewerActions['[routerViewer]RouterRequestSuccess']())
    )
  );

  // requetsRouter$ = this.actions$
  // .pipe(
  //   ofType(ROUTER_REQUEST),
  //   tap(() => RouterViewerActions['[routerViewer]RouterRequestSuccess']())
  // )
  // .subscribe(() => null);

  navigatedRouter$ = this.actions$
    .pipe(
      ofType(ROUTER_NAVIGATED, ROUTER_CANCEL),
      tap(() => {
        RouterViewerActions['[routerViewer]RouterNavigatedSuccess']();
        if (this.storage.getLocalStorage('startedGame') === 'true') {
          setTimeout(() => {
            this.storage.removeLocalStorage('startedGame');
          });
        }
      })
    )
    .subscribe(() => null);
}
