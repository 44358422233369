import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { debounceTime, Observable, Subject, takeUntil } from 'rxjs';
import { selectUser } from '../../store/user/selectors/user.selector';

declare let LiveAgent: any;
let LiveChat: any;
@Injectable({
  providedIn: 'root',
})
export class LadeskChatService {
  userProfile$: Observable<any> = this.store.select(selectUser);
  unsubus$: Subject<boolean> = new Subject();
  liveAgent: any = {};
  delay: ReturnType<typeof setTimeout> = setTimeout(() => {});

  constructor(private store: Store) {}

  createLiveAgent(): Promise<{ chat: any; agent: any }> {
    return new Promise((resolve, reject) => {
      try {
        this.userProfile$.pipe(takeUntil(this.unsubus$), debounceTime(200)).subscribe((res) => {
          const userProfile = res?.userProfile?.data;
          if (res.loaded && res?.userProfile?.data && document.getElementById('la_x2s6df8d')) {
            clearTimeout(this.delay);
            this.delay = setTimeout(() => {
              LiveAgent.clearAllUserDetails();
              LiveAgent.setUserDetails(
                userProfile?.email,
                userProfile?.firstName || '',
                userProfile?.lastName || '',
                userProfile?.phone
              );
            }, 400);
            return;
          }
          const scriptUrl = 'https://jteam.ladesk.com/scripts/track.js';
          const node = document.createElement('script');
          node.src = scriptUrl;
          node.id = 'la_x2s6df8d';
          node.type = 'text/javascript';
          node.async = true;
          node.charset = 'utf-8';

          node.onload = () => {
            LiveAgent.clearAllUserDetails();
            LiveAgent.setUserDetails(
              userProfile?.email,
              userProfile?.firstName || '',
              userProfile?.lastName || '',
              userProfile?.phone
            );
            LiveAgent.addContactField(
              'welle_fundist_profile',
              'https://support.welle.casino/en/Users/Summary/' + userProfile?.idUser
            );
            const button = document.getElementById('chatButton');
            if (button) {
              LiveChat = LiveAgent.createButton('bj1w6u87', button);
            }
            if (LiveChat) {
              const btn: any = document.getElementById(LiveChat.elementId);
              if (btn) btn.style.display = 'none';
            }
            resolve({ chat: LiveChat, agent: LiveAgent });
          };

          document.getElementsByTagName('head')[0].appendChild(node);
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  async openLiveAgent(agent: { type: string; id?: string; code?: string }): Promise<void> {
    if (Object.values(this.liveAgent).length > 0) {
      if (agent.type === 'liveChat') {
        this.liveAgent.agent.instance.openedWidget = null;
        this.liveAgent.agent.instance.closeOpenedWidget();
        this.liveAgent.chat.onClick();
        return;
      }
      if (agent.type === 'liveForm') {
        if (agent.id) {
          return this.liveAgent.agent.createForm(agent.code, document.getElementById(agent.id));
        }
      }
    }
    this.createLiveAgent().then((res) => {
      if (res && res.chat && res.agent) {
        this.liveAgent = res;
        if (agent.type === 'liveChat') {
          setTimeout(() => {
            res.chat.onClick();
          }, 400);
          return;
        }
        if (agent.type === 'liveForm' && agent.id) {
          return res.agent.createForm(agent.code, document.getElementById(agent.id));
        }
      }
    });
  }
}
