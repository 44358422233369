import { Component, ElementRef, EventEmitter, Input, OnInit, Optional, Output, Self, ViewChild } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormControlName,
  NgControl,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { NgIf, NgClass } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { AttributesDirective } from 'src/app/core/directives/attributes.directive';

@Component({
  selector: 'app-text-field',
  templateUrl: './text-field.component.html',
  styleUrls: ['./text-field.component.scss'],
  standalone: true,
  imports: [TranslocoModule, FormsModule, ReactiveFormsModule, NgIf, NgClass, AttributesDirective],
})
export class TextFieldComponent implements ControlValueAccessor, OnInit {
  @ViewChild('field')
  field: ElementRef = new ElementRef({});
  @Output()
  searchEmiter: EventEmitter<any> = new EventEmitter<any>();
  @Input()
  value!: string;
  @Input()
  showLabel: boolean = true;
  @Input()
  attributes: any = {};
  @Input()
  label!: string;
  @Input()
  fieldType!: string;
  @Input()
  placeholder: string = '';
  @Input()
  id?: string;
  @Input()
  isSearch: boolean = false;
  @Input()
  isLight: boolean = false;
  @Input()
  error: any = {};
  @Input()
  isFull: boolean = false;
  @Input()
  isDisabled: boolean = false;
  @Input()
  attention: boolean = false;
  @Input() type: 'signup' | 'withdraw' | 'limitEmail' | 'signin' = 'signup';

  control: FormControl = new FormControl();

  public onChangeFn = () => {};
  public onTouchedFn = () => {};
  constructor(
    @Self()
    @Optional()
    public ngControl: NgControl,
    private _controlName: FormControlName
  ) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }
  ngOnInit(): void {
    this.control = this._controlName.control;
    this.control.setValue(this.value);
  }
  writeValue(obj: any): void {
    if (obj && this.field) {
      this.field.nativeElement.value = obj;
    }
  }
  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }
  search(value: any) {
    this.value = value;
    if (this.isSearch) {
      this.searchEmiter.emit(value);
    }
  }
}
