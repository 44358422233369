import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
// import * as Sentry from '@sentry/angular';

// Sentry.init({
//   dsn: 'https://6ad0689eeb9f53a17d3d03c074cd977b@sentry.coduluz.com/2',
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.browserProfilingIntegration(),
//     Sentry.replayIntegration({
//       networkCaptureBodies: true,
//       networkDetailAllowUrls: [window.location.origin],
//       networkDetailDenyUrls: [/(.*)\/api\/v1\/auth/, /(.*)\/auth\/login/],
//     }),
//     Sentry.captureConsoleIntegration(),
//     Sentry.httpClientIntegration(),
//   ],
//   // debug: true,
//   sendDefaultPii: true,
//   tracesSampleRate: 1.0,
//   tracePropagationTargets: [/welle.*\.casino/],
//   profilesSampleRate: 1.0,
//   replaysSessionSampleRate: 0.1,
//   replaysOnErrorSampleRate: 1.0,
// });

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
