import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { toasterMessage } from 'src/app/core/interfaces/interfaces';

export const ToasterActions = createActionGroup({
  source: 'Toaster',
  events: {
    '[toaster] Toaster': emptyProps(),
    '[toaster] Set Toaster messgage Success': props<{
      data: toasterMessage;
    }>(),
    '[toaster] Remove Toaster Message Success': emptyProps(),
  },
});
