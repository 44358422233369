import { Component, Input, OnDestroy } from '@angular/core';
import { NavigationService } from 'src/app/core/services/navigation/navigation.service';
import { MainButtonComponent } from '../../buttons/main-button/main-button.component';
import { NgIf, NgFor } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { Subject } from 'rxjs';
import { ModalsActions } from 'src/app/core/store/modals/actions/modals.actions';
import { appState } from 'src/app';
import { Store } from '@ngrx/store';
import { ModalsDataTransferService } from 'src/app/core/services/modalService/modals-data-transfer.service';
import { UserCurrencyPipePipe } from 'src/app/core/pipes/userCurrencyPipe/user-currency-pipe.pipe';
interface Button {
  variant: string;
  text: string;
  url?: string;
}

interface PaymentStatus {
  title: string;
  text?: string;
  amount?: number;
  currency?: string;
  img: string;
  Btns: Button[];
}

interface PaymentStatuses {
  PAYMENT_SUCCESS: PaymentStatus;
  PAYMENT_FAIL: PaymentStatus;
  PAYMENT_WARNING: PaymentStatus;
  WITHDRAW_WARNING: PaymentStatus;
  WITHDRAW_SUCCESS: PaymentStatus;
  WITHDRAW_SUCCESS_Projects: PaymentStatus;
  WITHDRAW_FAIL: PaymentStatus;
}

@Component({
  selector: 'app-payment-status-modal',
  templateUrl: './payment-status-modal.component.html',
  styleUrls: ['./payment-status-modal.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, MainButtonComponent, TranslocoModule, UserCurrencyPipePipe],
})
export class PaymentStatusModalComponent implements OnDestroy {
  @Input() amount: number = 0;
  @Input() currency: string = '';
  @Input() text: string = '';
  @Input()
  openModal:
    | 'PAYMENT_SUCCESS'
    | 'PAYMENT_FAIL'
    | 'PAYMENT_WARNING'
    | 'WITHDRAW_SUCCESS'
    | 'WITHDRAW_SUCCESS_Projects'
    | 'WITHDRAW_FAIL'
    | 'WITHDRAW_WARNING'
    | null = null;

  unsubus$: Subject<boolean> = new Subject<boolean>();
  content: PaymentStatuses = {
    PAYMENT_SUCCESS: {
      title: 'sidenav.cashier.deposit.success',
      amount: this.amount,
      currency: this.currency,
      text: 'sidenav.cashier.deposit.succes_deposti_with_amount',
      img: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/Cashier/Deposit/successful.svg',
      Btns: [
        {
          variant: 'yellowBg',
          text: 'go_to_games',
          url: '/games/all',
        },
      ],
    },
    PAYMENT_FAIL: {
      title: 'sidenav.cashier.deposit.failed',
      text: 'sidenav.cashier.deposit.failed_text',
      img: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/Cashier/Deposit/failed.svg',
      Btns: [
        {
          variant: 'yellowBg',
          text: 'try_again',
          url: '/',
        },
      ],
    },
    PAYMENT_WARNING: {
      title: 'sidenav.cashier.deposit.canceled',
      text: 'sidenav.cashier.deposit.canceled_text',
      img: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/Cashier/Withdrawals/cancel_withdrawal.svg',
      Btns: [
        {
          variant: 'primary',
          text: 'OK',
          url: '/',
        },
        {
          variant: 'secondary',
          text: 'Close',
        },
      ],
    },
    WITHDRAW_SUCCESS: {
      title: 'sidenav.cashier.withdraw.success',
      img: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/Cashier/Withdrawals/your_withdrwal_is_being_processes.svg',
      Btns: [
        {
          variant: 'yellowBg',
          text: 'go_to_games',
        },
      ],
    },
    WITHDRAW_SUCCESS_Projects: {
      title: 'sidenav.cashier.withdraw.success_projects',
      img: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/Cashier/Withdrawals/your_withdrwal_is_being_processes.svg',
      Btns: [
        {
          variant: 'yellowBg',
          text: 'go_to_games',
        },
      ],
    },
    WITHDRAW_FAIL: {
      title: 'sidenav.cashier.withdraw.failed',
      text: 'sidenav.cashier.withdraw.failed_text',
      img: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/Cashier/Withdrawals/failed_withdrawal.svg',
      Btns: [
        {
          variant: 'yellowBg',
          text: 'try_again',
        },
      ],
    },
    WITHDRAW_WARNING: {
      title: 'sidenav.cashier.withdraw.canceled',
      text: 'sidenav.cashier.withdraw.canceled_text',
      img: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/Cashier/Withdrawals/cancel_withdrawal.svg',
      Btns: [
        {
          variant: 'yellowBg',
          text: 'Cancel',
        },
        {
          variant: 'secondary',
          text: 'keep',
        },
      ],
    },
  };

  constructor(
    private navigator: NavigationService,
    private store: Store<appState>,
    private modalDataTransferService: ModalsDataTransferService
  ) {}

  ngOnDestroy(): void {
    this.store.dispatch(ModalsActions['[modals]CloseModalsSuccess']());
  }

  buttonAction(text: string | undefined) {
    if (text === 'Cancel') {
      this.modalDataTransferService.setOnCancelPayment();
    }
    if (text === 'go_to_games') {
      this.navigator.navigateTo('/games/all');
    }
    if (text === 'OK' || text === 'Close') {
      this.navigator.navigateTo('/');
    }
    if (text === 'try_again' && this.openModal === 'PAYMENT_FAIL') {
      this.navigator.navigateTo('/(aside:cashier/deposit)');
    }

    this.store.dispatch(ModalsActions['[modals]CloseModalsSuccess']());
  }
}
