export const metadataObjectFabric = {
  get: (profile: any, type: string, userId: string) => {
    return {
      where: {
        type: {
          equals: type,
        },
        userId: {
          equals: userId,
        },
      },
      orderBy: {
        created: 'desc',
      },
      skip: 0,
    };
  },
  add: (profile: any, value: any, type: string, userId: string) => {
    return {
      userId: userId,
      email: profile?.email,
      type: type,
      merchant: 'welle',
      metadata: {
        data: value,
      },
    };
  },
  getTags: (type: string, userId: string) => {
    return {
      where: {
        type: {
          equals: type,
        },
        userId: {
          equals: userId,
        },
      },
      orderBy: {
        created: 'desc',
      },
      skip: 0,
    };
  },
  addTags: (profile: any, value: any, type: string, birthDate: any, userID: string, metabaseId: string) => {
    return {
      userId: metabaseId,
      email: profile?.email,
      type: type,
      merchant: 'welle',
      metadata: {
        data: value ? Object.values(value) : [],
        fundistID: userID,
      },
    };
  },
  checkTerms: (userId: string) => {
    return {
      where: {
        type: {
          equals: 'terms',
        },
        userId: {
          equals: userId,
        },
      },
      orderBy: {
        created: 'desc',
      },
      take: 1,
      skip: 0,
    };
  },
  addToTerms: (profile: any, termsLast: string, userId: string) => {
    return {
      userId: userId,
      email: profile?.email,
      type: 'terms',
      merchant: 'welle',
      metadata: {
        termsVersion: termsLast,
      },
    };
  },
};
