import { createReducer, on } from '@ngrx/store';
import { SetUserBalances } from '../actions/user.actions';

export const UserBalanceKey = 'UserBalance';

export interface BalanceEntry {
  currency: string;
  type: string;
  amount: number;
}
export interface State {
  balances: BalanceEntry[];
  isLoaded: boolean;
}
export const initialState: State = {
  balances: [],
  isLoaded: false,
};

export const reducer = createReducer(
  initialState,
  on(SetUserBalances['[SetUserBalances]SetUserBalanceSuccess'], (state, { data }) => {
    return {
      ...state,
      balances: data,
      isLoaded: true,
    };
  }),
  on(SetUserBalances['[SetUserBalances]SetUserBalanceFailure'], (state) => {
    return {
      ...state,
      balances: [{ currency: 'USD', type: 'ACTIVE', amount: 0 }],
      isLoaded: true,
    };
  })
);
