<div class="w-full box-shadow p-2 py-3" *transloco="let t">
  <div class="w-full" *transloco="let t">
    <div class="p-2 w-full flex justify-end rounded-lg">
      <div class="cursor-pointer h-6 w-6 flex justify-center" (click)="closeModal()">
        <img
          width="16"
          height="16"
          src="https://cdn.chipychapa.com//welle/General_site/Sidebar/Cashier/Deposit/close.svg"
          alt="close modal" />
      </div>
    </div>
  </div>
  <div class="w-full flex-col flex gap-6 items-center">
    @if (type !== 'withdraw') {
    <div class="items-center flex flex-col gap-1">
      <div
        class="bg-depositCardsBgLight h-6 rounded-[4px] text-grayTextLight w-[67px] text-xs font-medium items-center flex px-[0.2rem]">
        <span>+5% {{ t('wellbet') }}</span>
      </div>
      <div class="text-mainTextLight text-sm font-normal">
        <span>{{ t('wellbet.five.percents') }}</span>
      </div>
      <div
        class="text-sm font-normal text-mainTextLight underline underline-offset-1 cursor-pointer"
        (click)="goToTerms()">
        {{ t('buttons.see more') }}
      </div>
    </div>
    <div class="items-center flex flex-col gap-1">
      <div
        class="bg-biggerContainerBgLight h-6 rounded-[4px] text-textBlueLight w-[74px] text-xs font-medium items-center flex px-[0.2rem]">
        <span>+10% {{ t('payout.wellbet') }}</span>
      </div>
      <div class="text-mainTextLight text-sm font-normal">
        <span>{{ t('wellbet.ten.percents') }}</span>
      </div>
      <div
        class="text-sm font-normal text-mainTextLight underline underline-offset-1 cursor-pointer"
        (click)="goToTerms()">
        {{ t('buttons.see more') }}
      </div>
    </div>
    <div class="items-center flex flex-col gap-1">
      <div
        class="bg-mainBtnLight h-6 rounded-[4px] text-textBrownLight w-[74px] text-xs font-medium items-center flex px-[0.2rem]">
        <span>+15% {{ t('wellbet') }}</span>
      </div>
      <div class="text-mainTextLight text-sm font-normal">
        <span>{{ t('wellbet.fifteen.percents') }}</span>
      </div>
      <div
        class="text-sm font-normal text-mainTextLight underline underline-offset-1 cursor-pointer"
        (click)="goToTerms()">
        {{ t('buttons.see more') }}
      </div>
    </div>
    }
    <div class="items-center flex flex-col">
      <img
        class="max-h-8"
        src="https://cdn.chipychapa.com//welle/General_site/Sidebar/Cashier/Deposit/instant.svg"
        alt="Recommended Deposit Method" />
      <div class="text-mainTextLight text-sm font-normal">
        <span>{{ t('sidenav.cashier.deposit.modal.info.instant') }}</span>
      </div>
      @if (type === 'withdraw') {
        <div
        class="text-sm font-normal text-mainTextLight underline underline-offset-1 cursor-pointer"
        (click)="goToTerms()">
          {{ t('buttons.see more') }}
        </div>
      }

    </div>
    <div class="items-center flex flex-col">
      <img
        class="max-h-8"
        src="https://cdn.chipychapa.com//welle/General_site/Sidebar/Cashier/Deposit/recomended.svg"
        alt="Instant Deposit" />
      <div class="text-mainTextLight text-sm font-normal">
        <span>{{ t('sidenav.cashier.deposit.modal.info.recomended') }}</span>
      </div>
      @if (type === 'withdraw') {
        <div
        class="text-sm font-normal text-mainTextLight underline underline-offset-1 cursor-pointer"
        (click)="goToTerms()">
          {{ t('buttons.see more') }}
        </div>
        <div class="p-2.5"></div>
      }

    </div>
  </div>
</div>
