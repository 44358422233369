import { Component, Input } from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-datepickers-button',
  templateUrl: './datepickers-button.component.html',
  styleUrls: ['./datepickers-button.component.scss'],
  standalone: true,
  imports: [NgIf],
})
export class DatepickersButtonComponent {
  @Input()
  name: string = '';
  @Input()
  stopSelect: boolean = false;
  @Input()
  variant: 'active-start' | 'active-end' | 'selected' | 'inactive' | 'default' = 'default';
  @Input()
  isInactive: boolean = false;

  constructor() {}

  getVariant() {
    let classes: string = '';
    if (this.stopSelect) {
      this.variant = 'inactive';
    }
    switch (this.variant) {
      case 'default':
        classes =
          ' bg-tertiary text-left rounded-md dark:text-secondaryTextMain text-mainTextLight hover:bg-blueBgColor flex flex-row truncate';
        break;
      case 'inactive':
        classes =
          ' bg-tertiary text-left rounded-md dark:text-secondaryTextMain  text-grayTextLight pointer-events-none flex flex-row truncate';
        break;
      case 'active-start':
        classes =
          ' dark:bg-minorText dark:text-mainText text-mainTextLight  bg-blueBgColor text-left flex flex-row truncate rounded-l-md';
        break;
      case 'active-end':
        classes =
          ' dark:bg-minorText dark:text-mainText text-mainTextLight bg-blueBgColor text-left flex flex-row truncate rounded-r-md';
        break;
      case 'selected':
        classes =
          ' dark:bg-mainHover  dark:text-mainText text-mainTextLight  bg-skyBtnHoverLight  text-left flex flex-row truncate';
        break;
      default:
        classes =
          ' bg-tertiary text-left rounded-md dark:text-secondaryTextMain text-mainTextLight hover:bg-blueBgColor flex flex-row truncate';
    }
    return classes;
  }
}
