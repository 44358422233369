import { Component, Input } from '@angular/core';
import { NavigationService } from 'src/app/core/services/navigation/navigation.service';
import { LoaderComponent } from '../../animations/loader/loader.component';
import { NgIf } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'app-main-button',
  templateUrl: './main-button.component.html',
  styleUrls: ['./main-button.component.scss'],
  standalone: true,
  imports: [TranslocoModule, NgIf, LoaderComponent],
})
export class MainButtonComponent {
  @Input()
  name: string = '';
  @Input()
  url: string = '';
  @Input()
  variant:
    | 'primary'
    | 'sideBarBlue'
    | 'transparent'
    | 'mainButton'
    | 'secondary'
    | 'minorText'
    | 'quaternary'
    | 'tertiary'
    | 'yellowBg'
    | 'tertiaryCenter'
    | 'joinNow'
    | 'applyBtn'
    | 'yellowBgGames'
    | 'disabled'
    | 'mainTextLight'
    | 'skyBtnDatePicker'
    | 'yellowBgSmall'
    | 'loyaltyClaim'
    | 'claimButton' = 'primary';
  @Input()
  terms: boolean = false;
  @Input()
  disabled: boolean = false;
  @Input()
  datePicker: boolean = false;
  @Input()
  type: string = 'button';
  @Input()
  svg?: string = '';
  @Input()
  enableLoading: boolean = true;
  @Input()
  isActive: boolean = false;
  isMobile: boolean = true;
  delay: ReturnType<typeof setTimeout> = setTimeout(() => {});
  @Input() isLoadingText: boolean = false;
  loading: boolean = false;

  constructor(private navigator: NavigationService) {}

  buttonClick() {
    if (this.type === 'submit') return;
    if (this.enableLoading) {
      if (this.loading) {
        return;
      }
      this.loading = true;
      clearTimeout(this.delay);
      this.delay = setTimeout(() => {
        this.loading = false;
      }, 2000);
    }
    if (this.url === '') return;

    this.navigator.navigateTo(this.url);
  }
  //todo need to be refactored!!!
  getVariant() {
    let classes: string = '';
    if (this.terms) this.variant = 'tertiary';
    switch (this.variant) {
      case 'transparent':
        classes = ' bg-none text-mainTextLight text-center underline underline-offset-8 rounded-xl font-bold   ';
        break;
      case 'claimButton':
        classes = ' bg-mainBtnLight px-7 hover:bg-mainHoverLight py-3 text-mainTextLight rounded-xl h-12 font-bold ';
        break;
      case 'mainButton':
        classes = ' bg-mainBtnLight hover:bg-mainHoverLight text-mainTextLight rounded-xl font-bold ';
        break;
      case 'primary':
        classes = ' bg-mainBtn text-mainText text-center hover:bg-mainHover rounded-xl font-bold  ';
        break;
      case 'secondary':
        classes =
          ' bg-mainBgLight dark:bg-brownMain text-white dark:text-yellowBgMain hover:bg-secondBtnHoverLight dark:hover:text-mainHover dark:hover:bg-yellowBgLight rounded-xl font-bold  ';
        break;
      case 'tertiary':
        classes =
          ' text-left text-mainTextLight dark:text-mainText hover:bg-secondBtnHoverLight hover:text-white dark:hover:bg-[#38006D] flex flex-row truncate text-base font-normal rounded-xl font-bold !p-3.5 ' +
          (this.isActive ? 'dark:bg-mainBtn bg-mainBgLight text-white' : ' dark:bg-tertiary ');
        break;
      case 'quaternary':
        classes =
          'dark:bg-mainHover bg-mainHoverLight text-mainText dark:hover:bg-[#3C0C82] flex-center truncate rounded-xl font-bold  ';
        break;
      case 'applyBtn':
        classes =
          'bg-mainBgLight hover:bg-secondBtnHoverLight active:bg-mainBgLight text-white text-lg rounded-xl font-bold !p-3.5';
        break;
      case 'minorText':
        classes =
          'dark:bg-minorText bg-smallContainerBgLight text-mainTextLight dark:text-mainText hover:dark:bg-mainHover rounded-xl font-semibold !text-base !p-3 ';
        break;
      case 'yellowBg':
        classes =
          'bg-mainBtnLight dark:bg-yellowBgMain dark:text-mainHover hover:bg-mainHoverLight hover:dark:bg-yellowBgLight rounded-xl text-base font-semibold !p-3 ';
        break;
      case 'yellowBgGames':
        classes =
          ' bg-mainBtnLight text-mainTextLight hover:bg-mainHoverLight  dark:bg-yellowBgMain dark:text-mainHover hover:dark:bg-yellowBgLight !text-lg flex flex-row-reverse items-center justify-center gap-4 rounded-xl font-bold !p-3.5';
        break;
      case 'tertiaryCenter':
        classes =
          ' bg-mainBgLight text-white hover:bg-secondBtnHoverLight dark:bg-brownMain dark:text-yellowBgMain dark:hover:text-mainHover dark:hover:bg-yellowBgLight rounded-xl font-bold !p-[1.125rem]';
        break;
      case 'joinNow':
        classes =
          ' bg-[#41809e] border-[#104a79] text-secondaryTextMain hover:text-[#e0e0e0] border-[6px] rounded-2xl text-center text-xl justify-center hover:bg-[#6699b1] flex flex-row lg:w-[200px] font-bold  ';
        break;
      case 'sideBarBlue':
        classes =
          ' bg-smallContainerBgLight text-mainTextLight text-center hover:bg-mainBgLight hover:text-mainText font-bold rounded-xl ';
        break;
      case 'disabled':
        classes = ' bg-lightGrayBorder text-mainTextLight text-center pointer-events-none font-bold rounded-[10px]  ';
        break;
      case 'mainTextLight':
        classes = ' text-mainTextLight text-center rounded-[10px] font-semibold text-sm  ';
        break;
      case 'yellowBgSmall':
        classes =
          'bg-mainBtnLight dark:bg-yellowBgMain dark:text-mainHover hover:bg-mainHoverLight hover:dark:bg-yellowBgLight rounded-[0.63rem] text-base font-semibold !p-2 w-full md:w-[7.56rem]';
        break;
      case 'skyBtnDatePicker':
        classes =
          ' text-center text-mainTextLight dark:text-mainText hover:bg-skyBtnHoverLight dark:hover:bg-[#38006D] flex flex-row truncate text-base font-normal rounded-xl !p-2  flex-center !rounded-[4px] ' +
          (this.isActive ? 'dark:bg-mainBtn bg-blueBgColor text-white' : ' dark:bg-tertiary ') +
          (this.disabled ? ' bg-lightGrayBorder ' : '');
        break;
      case 'loyaltyClaim':
        classes = ' bg-mainBtnLight hover:bg-mainHoverLight text-mainTextLight rounded-xl font-bold h-14';
        break;
      default:
        classes = ' bg-mainBtn ';
    }

    return classes;
  }
}
