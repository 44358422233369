export interface RegistrationForm {
  currency: string;
  email: string;
  login: string;
  password: string;
  phoneNumber?: string;
  phoneCode?: string;
  agreedWithTermsAndConditions?: boolean;
  ageConfirmed?: boolean;
  fingerprint?: any;
}

export class UserRegistration {
  public data: any;
  public fields!: Array<string>;
  [s: string]: string | Array<any>;

  constructor(data: any, isValidation: boolean) {
    this.data = data;
    this.fields = Object.keys(data);
    if (!isValidation) {
      this.TYPE = 'user-register';
    }
  }
}
