import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'turnoverSumFormatter',
  standalone: true,
})
export class TurnoverSumFormatterPipe implements PipeTransform {
  transform(value: number): string {
    if (value >= 1000000) {
      return `${value / 1000000}M`;
    } else if (value >= 1000) {
      return `${value / 1000}k`;
    } else {
      return `${value}`;
    }
  }
}
