@if (isLoaded) {
  <div class="w-full flex flex-col p-3 md:p-8 gap-4 py-3 md:py-4 items-center" *transloco="let t">
    <div
    *ngFor="let item of allRanks; let index = index"
    class="flex  w-full  justify-between relative p-1 flex-row gap-3">
      <div [class]="'flex flex-col sm:flex-row w-full items-center gap-1 bg-white rounded-xl p-1 relative ' + (item.isClaimed && index !== userRankIndex ? 'opacity-75' : '')">
      <div class="flex flex-row w-full items-center gap-4 relative sm:static p-1">
        <div [class]="'absolute left-0 top-0 h-full rounded-xl ' + (item.isClaimed ? 'bg-lightGrayBorder ': ' bg-sidebarLight ') + (index === userRankIndex ? 'bg-sidebarLight border-2 border-brownGoldLight' : '')" [style.width]="item.progress + '%'"></div>
        <img class="relative w-14 h-14" width="52" height="52" [ngSrc]="item.name" />
        <div class="relative flex flex-col w-full gap-1">
          <div class="flex flex-row justify-between">
            <div class="flex flex-col mL:gap-1 mL:flex-row mL:items-end">
              @if (index === userRankIndex) {
                <span [class]="'text-xs text-mainTextLight opacity-75 sm:opacity-100 sm:text-sm leading-normal font-normal'">{{ t('loyalty.ranks.current_rank') }}</span>
                <span [class]="'font-semibold text-sm text-mainTextLight line-height'">{{ t(item.name) }}</span>
              } @else {
                <span [class]="'text-sm font-semibold ' + item?.color">{{ t(item.name) }}</span>
              }
            </div>
          </div>
            <div class="flex flex-col items-start gap-2">
              <div [class]="'text-xs font-semibold flex flex-row gap-0.5 min-w-16 ' + (index !== userRankIndex ? 'text-darkBlueBgLight' : 'text-mainTextLight')">
                <span>
                  {{ (!item.progress ? '0' : item.turnoverAmount <= totalSum ? item.turnoverAmount : totalSum)}}
                </span>
                <span> {{'/' + item.turnoverAmount + " " + t('bonus.description_line.wager')}}</span>
              </div>
            </div>
        </div>
      </div>
    @if (!item.isClaimed) {
      <div
        (click)="item.isAvailable ? takeRank(item) : ''"
        [class]="
          'group rounded-[0.625rem] flex-row flex items-center justify-center gap-0.5 min-w-[100%] sm:min-w-[7.375rem] relative h-14 sm:h-8 mx-5 ' +
          (item.isAvailable ? 'bg-mainBtnLight hover:bg-mainHoverLight cursor-pointer' : 'bg-lightGrayBorder')">
        <span class="font-semibold text-sm text-mainTextLight">{{ t('buttons.add') }}</span>
        <span class="font-semibold text-sm md:max-w-8 truncate">{{ item.claimAmount }}</span>
        <img
          class="h-2 w-4"
          src="https://cdn.chipychapa.com//welle/General_site/Sidebar/Features/Loyalty/wellcoin.svg"
          alt="wellcoin" />
        <div
          class="drawer absolute hidden md:group-hover:flex flex-row text-mainText py-2 px-3 bg-tooltipLight rounded-md transform -top-8 items-center z-10 text-xs font-medium">
          <span>{{ item.claimAmount }}</span>
          <img
            class="h-2 w-4"
            src="https://cdn.chipychapa.com//welle/General_site/Sidebar/wellcoin_icon_for_pending.svg"
            alt="wellcoin" />
        </div>
      </div>
    } @else {
      <div
        class="rounded-[0.625rem] flex-row gap-0.5 sm:flex-col flex justify-around pb-1 relative text-sm font-semibold mx-5 items-center justify-center text-mainTextLight h-14 sm:h-fit">
        <span>{{ t('loyalty.ranks.achieved') }}</span>
        <div>
          <div
          class="flex flex-row items-center gap-0.5">
          <span class="text-sm text-mainTextLight">{{ item.claimAmount }}</span>
          <img
            class="h-2 w-4"
            src="https://cdn.chipychapa.com//welle/General_site/Sidebar/Features/Loyalty/wellcoin.svg"
            alt="wellcoin"
            id="wellcoin" />
        </div>
        </div>
      </div>
    }
      </div>
    </div>
  </div>
} @else {
  <div class="h-[5.5rem]"><app-inner-loader></app-inner-loader></div>
}
