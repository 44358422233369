<ng-container *transloco="let t">
  <ng-container *ngIf="currentScreenWidth <= 1124">
    <!-- buttons in mobile modal -->
    <div class="grid w-full rounded-xl text-mainText grid-cols-2 px-7 gap-3 justify-center">
      <div class="flex items-center justify-center pt-3">
        <button
          (click)="openModal('providers')"
          class="rounded-xl py-3 text-sm font-medium w-full"
          [ngClass]="{
            'bg-skyBtnHoverLight': currentFilter === 'providers',
            '': currentFilter !== 'providers'
          }">
          <div class="flex gap-2 items-center justify-center px-3">
            <img src="https://cdn.chipychapa.com//welle/General_site/Categories/providers.svg" alt="providers" />
            <h6 class="text-mainTextLight font-semibold">{{ t('modal.providers') }}</h6>
          </div>
        </button>
      </div>
      <div class="flex items-center justify-center pt-3">
        <button
          (click)="openModal('categories')"
          class="px-2 rounded-xl py-3 font-medium text-sm w-full"
          [ngClass]="{
            'bg-skyBtnHoverLight': currentFilter === 'categories',
            '': currentFilter !== 'categories'
          }">
          <div class="flex gap-2 items-center justify-center px-3">
            <img src="https://cdn.chipychapa.com//welle/General_site/Categories/categories.svg" alt="category" />
            <h6 class="text-mainTextLight font-semibold">{{ t('modal.categories') }}</h6>
          </div>
        </button>
      </div>
    </div>
  </ng-container>
  @if (isLoaded) {
    <!-- providers filter -->
    @if (currentFilter === 'providers') {
      <div
        class="md:rounded-b-xl md:relative md:shadow-lg grid grid-cols-1 md:grid-cols-2 l2lg:grid-cols-3 flex-wrap px-4 pt-6 pb-28 l2lg:pb-5 gap-4 bg-smallContainerBgLight">
        <div
          *ngFor="let item of merchantsList; let last = last"
          class="flex w-full inner-container cursor-pointer items-center pt-2 pb-3 px-4 transition-colors dark:border-line border-[#0369A11A] active:bg-lightBtn active:dark:bg-mainBtn border-b-2">
          <label
            class="flex w-full justify-between cursor-pointer items-center form-custom-check hover:dark:bg-mainHover hover:bg-sidebarLight hover:rounded-xl px-4 py-3">
            <img class="max-w-[9rem] max-h-10" [src]="item?.imageLogo" [alt]="item?.name" />
            <input
              (click)="onCheck(item)"
              [checked]="checkedIds.includes(item.id)"
              #checkboxes
              type="checkbox"
              class="form-custom-check-input"
              [id]="item.name" />
          </label>
        </div>
        <label
          *ngIf="!hideLessBtn && currentFilter === 'providers'"
          (click)="showMoreBtn ? showMore() : showLess()"
          class="cursor-pointer flex items-center">
          <div
            class="flex justify-center w-full text-sm dark:text-tertiaryTextMain text-mainBgLight gap-8 items-center p-4 rounded-lg hover:dark:bg-mainHover hover:bg-sidebarLight active:dark:bg-mainBtn active:bg-lightBtn transition-colors">
            <div *ngIf="showMoreBtn" id="providersShowMoreBtn">
              {{ t('buttons.show_more') }}
            </div>
            <div *ngIf="!showMoreBtn" id="providersShowLessBtn">
              {{ t('buttons.show_less') }}
            </div>
          </div>
        </label>
        <!-- providers if mobile -->
        @if (isMobile) {
          <div class="w-full absolute bottom-0 right-0 py-3 bg-smallContainerBgLight overflow-visible rounded-t-xl">
            <div class="grid grid-col-2 mx-5 gap-5">
              <!-- selected -->
              <div class="col-span-2 gap-2">
                <div *ngIf="selectedMerchants.length > 0" class="flex justify-between items-center col-span-2">
                  <div class="dark:text-secondaryTextMain text-mainTextLight text-sm font-semibold">
                    {{ t('filters.selected') }}:
                  </div>
                  <div
                    class="flex justify-evenly items-center w-10 rounded-lg p-[0.3rem] cursor-pointer bg-mainText"
                    (click)="removeSelected()">
                    <span
                      class="flex flex-col justify-center text-sm font-semibold dark:text-mainText text-mainBgLight">
                      {{ selectedMerchants.length }}
                    </span>
                    <img
                      src="https://cdn.chipychapa.com//welle/General_site/Categories/clean_filters.svg"
                      alt="cross" />
                  </div>
                </div>
              </div>
              <button
                class="col-span-2 bg-mainBgLight hover:bg-secondBtnHoverLight active:bg-mainBgLight text-white text-base rounded-xl font-semibold px-5 py-4"
                (click)="applyFilters()"
                id="providerFilterApply"
                [showProviders]="showProviders">
                {{ t('buttons.apply') }}
              </button>
            </div>
          </div>
          <!-- providers desktop -->
        } @else {
          <!-- show more or show less -->

          <!-- selected -->
          <div
            class="l2lg:col-span-3 absolute l2lg:static w-full items-center bottom-0 p-4 lXl:py-4 lXl:px-0 flex flex-col l2lg:flex-row l2lg:grid l2lg:grid-cols-3 l2lg:justify-between gap-4 bg-smallContainerBgLight">
            <div class="w-full">
              <div *ngIf="selectedMerchants.length > 0" class="flex justify-between items-center mx-4">
                <div class="dark:text-secondaryTextMain text-mainTextLight text-sm font-semibold">
                  {{ t('filters.selected') }}:
                </div>
                <div
                  class="flex gap-1 items-center justify-center rounded-lg py-1 px-2 cursor-pointer bg-mainText"
                  (click)="removeSelected()">
                  <span
                    class="flex flex-col justify-center text-base font-semibold dark:text-mainText text-mainBgLight">
                    {{ selectedMerchants.length }}
                  </span>
                  <img src="https://cdn.chipychapa.com//welle/General_site/Categories/clean_filters.svg" alt="cross" />
                </div>
              </div>
            </div>
            <!-- apply filter -->
            <button
              class="l2lg:col-start-3 w-full l2lg:col-end-4 l2lg:col-span-2 md:col-span-2 bg-mainBgLight hover:bg-secondBtnHoverLight active:bg-mainBgLight text-white text-base rounded-xl font-semibold px-5 py-4"
              (click)="applyFilters()"
              id="providerFilterApply"
              [showProviders]="false">
              {{ t('buttons.apply') }}
            </button>
          </div>
        }
      </div>
      <!-- category filter -->
    } @else {
      <div
        class="flex h-full pb-4 md:pb-5 flex-col dark:text-mainText text-mainTextLight p-4 md:rounded-b-xl md:shadow-lg bg-smallContainerBgLight">
        <!-- mobile defaultcategories -->
        <div *ngIf="isAuth" [class]="'grid-cols-3 gap-x-4 w-full grid mmd:hidden '">
          <ng-container *ngFor="let item of defaultCategories">
            <label (click)="selectCategory(item.slug)" class="cursor-pointer py-[0.4rem] flex gap-4 items-center">
              <div
                class="flex w-full gap-3 justify-center mmd:justify-start items-center p-4 hover:dark:bg-mainHover hover:bg-sidebarLight active:dark:bg-mainBtn active:bg-lightBtn transition-colors md:border-b-2 dark:border-line border-[#0369A11A]">
                <img class="w-7 h-7" [src]="item?.img" alt="category" />
                <span class="hidden mmd:inline">
                  {{ t('shared.categories.' + item.slug) }}
                </span>
              </div>
            </label>
          </ng-container>
        </div>
        <div class="grid-cols-1 mmd:grid-cols-2 md:grid-cols-3 gap-x-4 mwXs:grid-cols-1 w-full grid">
          @if (!isAuth) {
            <label
              (click)="selectCategory('all')"
              class="cursor-pointer py-[0.4rem] flex gap-4 items-center border-b-2 dark:border-line border-[#0369A11A]">
              <div
                class="flex w-full gap-3 items-center p-4 rounded-lg hover:dark:bg-mainHover hover:bg-sidebarLight active:dark:bg-mainBtn active:bg-lightBtn transition-colors">
                <img
                  class="w-7 h-7"
                  src="https://cdn.chipychapa.com//welle/General_site/Categories/categories/all_games.svg"
                  alt="category" />
                {{ t('shared.categories.all') }}
              </div>
            </label>
          }
          <!-- desktop defaultCategories -->
          @if (isAuth && screenWidth > 767) {
            <label
              (click)="selectCategory(item.slug)"
              *ngFor="let item of defaultCategories"
              class="cursor-pointer py-[0.4rem] flex gap-4 items-center border-b-2 dark:border-line border-[#0369A11A]">
              <div
                class="flex w-full gap-3 justify-center mmd:justify-start items-center p-4 hover:dark:bg-mainHover hover:bg-sidebarLight active:dark:bg-mainBtn active:bg-lightBtn transition-colors">
                <img class="w-7 h-7" [src]="item?.img" alt="category" />
                <span class="hidden mmd:inline">
                  {{ t('shared.categories.' + item.slug) }}
                </span>
              </div>
            </label>
          }
          <label
            (click)="selectCategory(item.slug)"
            *ngFor="let item of categoriesList; let i = index; let last = last"
            class="cursor-pointer py-[0.4rem] flex gap-4 items-center border-b-2 dark:border-line border-[#0369A11A]"
            [id]="item.slug">
            <div
              class="flex w-full gap-3 items-center p-4 rounded-lg hover:dark:bg-mainHover hover:bg-sidebarLight active:dark:bg-mainBtn active:bg-lightBtn transition-colors">
              <img class="w-7 h-7" [src]="item?.img" alt="category" />
              {{ t('shared.categories.' + item.slug) }}
            </div>
          </label>
          <!-- category if mobile -->
          @if (isMobile) {
            <div class="gap-5 mmd:col-span-2 mt-2">
              <label
                *ngIf="!hideLessBtn && currentFilter === 'categories'"
                (click)="showMoreBtn ? showMore() : showLess()"
                class="cursor-pointer flex items-center grid-cols-3 col-start-3">
                <div
                  class="flex justify-center w-full text-sm dark:text-tertiaryTextMain text-mainTextLight gap-8 items-center p-4 rounded-lg hover:dark:bg-mainHover hover:bg-sidebarLight active:dark:bg-mainBtn active:bg-lightBtn transition-colors">
                  <div *ngIf="showMoreBtn" id="providersShowMoreBtn">
                    {{ t('buttons.show_more') }}
                  </div>
                  <div *ngIf="!showMoreBtn" id="providersShowLessBtn">
                    {{ t('buttons.show_less') }}
                  </div>
                </div>
              </label>
            </div>
            <!-- category if desktop -->
          } @else {
            <label
              *ngIf="!hideLessBtn && currentFilter === 'categories'"
              (click)="showMoreBtn ? showMore() : showLess()"
              class="cursor-pointer flex items-center pt-4 grid-cols-3 col-start-3">
              <div
                class="flex justify-center w-full text-sm dark:text-tertiaryTextMain text-mainBgLight gap-8 items-center p-4 rounded-lg hover:dark:bg-mainHover hover:bg-sidebarLight active:dark:bg-mainBtn active:bg-lightBtn transition-colors">
                <div *ngIf="showMoreBtn" id="providersShowMoreBtn">
                  {{ t('buttons.show_more') }}
                </div>
                <div *ngIf="!showMoreBtn" id="providersShowLessBtn">
                  {{ t('buttons.show_less') }}
                </div>
              </div>
            </label>
          }
        </div>
      </div>
    }
    <!-- merchants or categories not loaded -->
  } @else {
    <div
      class="grid-cols-[repeat(auto-fit,minmax(290px,1fr))] grid px-3 py-5 mwMd:pb-[9.375rem] pb-8 rounded-b-xl gap-4 text-mainText bg-smallContainerBgLight">
      <div
        *ngFor="let item of decoy; let last = last"
        class="py-2 h-14 dark:bg-containerBg bg-sidebarLight rounded-lg animate-pulse"></div>
    </div>
  }

  <div
    [class]="
      ' absolute bottom-0 left-1/2 dark:bg-containerBg bg-containerBgLight -translate-x-1/2 w-full px-4 grid gap-2 pb-4 ' +
      (currentFilter !== 'categories'
        ? ' py-2 mM:grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 '
        : ' text-center grid-cols-2 md:hidden l4xl:grid-cols-4 l5xl:grid-cols-6 l5xl:text-left ')
    ">
    @if (!isMobile) {
      <label
        *ngIf="!hideLessBtn && currentFilter === 'categories'"
        (click)="showMoreBtn ? showMore() : showLess()"
        class="col-start-1 col-end-3 md:col-start-3 md:col-end-4 l4xl:col-start-3 l5xl:col-start-5 l4xl:col-span-2 cursor-pointer flex items-center">
        <div
          class="flex w-full text-sm dark:text-minorText text-mainTextLight gap-8 items-center p-4 rounded-lg hover:dark:bg-mainHover hover:bg-mainHoverLight active:dark:bg-mainBtn active:bg-lightBtn transition-colors">
          <div
            *ngIf="showMoreBtn"
            class="w-full dark:text-tertiaryTextMain text-tertiaryTextLight"
            id="categoryShowMoreBtn">
            {{ t('buttons.show_more') }}
          </div>
          <div
            *ngIf="!showMoreBtn"
            class="w-full dark:text-tertiaryTextMain text-tertiaryTextLight"
            id="categoryShowLessBtn">
            {{ t('buttons.show_less') }}
          </div>
        </div>
      </label>
    }
  </div>
</ng-container>
