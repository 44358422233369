import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextFieldComponent } from '../../forms/text-field/text-field.component';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { countryInterface } from 'src/app/core/interfaces/interfaces';
import { Store } from '@ngrx/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { selectGetCounries } from 'src/app/core/store/helpers/selector/helpers.selectors';
import { ModalsActions } from 'src/app/core/store/modals/actions/modals.actions';
import { ModalsDataTransferService } from 'src/app/core/services/modalService/modals-data-transfer.service';
import { InnerLoaderComponent } from '../../animations/inner-loader/inner-loader.component';

@Component({
  selector: 'app-select-box-country-modal',
  standalone: true,
  imports: [CommonModule, TextFieldComponent, FormsModule, ReactiveFormsModule, InnerLoaderComponent],
  templateUrl: './select-box-country-modal.component.html',
  styleUrl: './select-box-country-modal.component.scss',
})
export class SelectBoxCountryModalComponent implements OnInit {
  items: any;
  form: FormGroup = new FormGroup({});
  filtredCountries: Array<countryInterface> = [];
  unsubus$: Subject<boolean> = new Subject();
  helpers$: Observable<any> = this.store.select(selectGetCounries);
  countries: Array<countryInterface> = [];
  selectedCountry: countryInterface | undefined = {};
  countrySelected!: string | null;
  viewInit: boolean = false;
  constructor(
    private fb: FormBuilder,
    private store: Store,
    private modalDataTransferService: ModalsDataTransferService
  ) {
    setTimeout(() => {
      this.viewInit = true;
    }, 1500);
    this.items = this.modalDataTransferService.getSelectedBoxItemsCountry();
  }
  ngOnInit(): void {
    this.form = this.fb.group({
      countryName: [null],
    });
    this.helpers$.pipe(takeUntil(this.unsubus$)).subscribe((res: any) => {
      if (res.countries) {
        this.countries = res.countries;
        this.search('');
      }
    });
    this.countrySelected = this.modalDataTransferService.getSelectedItemInSelectBoxCountry();
  }
  search(value: any) {
    if (value.match(/^(?![+])+\d+(?![a-zA-Z])/)) {
      value = '+' + value;
    }
    value = value?.toLowerCase();
    this.filtredCountries = this.countries.filter((item: countryInterface) => {
      return item?.title?.toLowerCase().startsWith(value);
    });
  }
  onSubmit() {
    if (this.filtredCountries.length == 1) {
      this.selectCountry(this.filtredCountries[0]);
    }
  }
  selectCountry(item: any) {
    this.modalDataTransferService.selectItemInSelectBoxCountry(item);
    this.store.dispatch(ModalsActions['[modals]CloseModalsSuccess']());
  }
}
