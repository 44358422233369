import { Component, OnInit } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { MainButtonComponent } from '../../buttons/main-button/main-button.component';
import { Store } from '@ngrx/store';
import { HelpersActions } from 'src/app/core/store/helpers/actions/helpers.actions';
import { Observable, Subject, takeUntil } from 'rxjs';
import { checkUserExist } from 'src/app/core/store/sign-up/actions/sign-up.actions';
import { selectCheckUser } from 'src/app/core/store/sign-up/selector/sign-up.selector';

@Component({
  selector: 'app-promo-modal',
  standalone: true,
  imports: [TranslocoModule, MainButtonComponent],
  templateUrl: './promo-modal.component.html',
  styleUrl: './promo-modal.component.scss',
})
export class PromoModalComponent implements OnInit {
  checkUser$: Observable<any> = this.store.select(selectCheckUser);
  unsubus$: Subject<boolean> = new Subject();
  userData: any = {};
  constructor(private store: Store) {}

  ngOnInit() {
    this.checkUser$.pipe(takeUntil(this.unsubus$)).subscribe((res: any) => {
      if (res.isRegistered) {
        this.userData = res?.userData;
      }
    });
  }
  previousStep() {
    this.store.dispatch(HelpersActions['[helpers]SetCurrentStep']({ data: { step: 2, isBonus: false } }));
  }

  continueWithoutPromo() {
    this.userData = {
      ...this.userData,
      registrationPromoCode: '',
    };
    this.store.dispatch(checkUserExist['[checkUserExist]UpdateUserData']({ data: { ...this.userData } }));
    this.store.dispatch(HelpersActions['[helpers]SetCurrentStep']({ data: { step: 3, isBonus: false } }));
  }
}
