import { Component, Input } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { ModalsDataTransferService } from 'src/app/core/services/modalService/modals-data-transfer.service';
import { TournamentsService } from 'src/app/core/services/tournaments/tournaments.service';
import { ModalsActions } from 'src/app/core/store/modals/actions/modals.actions';

@Component({
  selector: 'app-tournament-agreement-modal',
  standalone: true,
  imports: [TranslocoModule],
  templateUrl: './tournament-agreement-modal.component.html',
  styleUrl: './tournament-agreement-modal.component.scss',
})
export class TournamentAgreementModalComponent {
  @Input() text: string = '';
  @Input() ID: string = '';
  @Input() state: boolean = false;

  isParticipant: boolean = false;
  isDisabled: boolean = true;

  unsubus$: Subject<boolean> = new Subject();

  constructor(
    private store: Store,
    private tournamentService: TournamentsService,
    private modalDataTransferService: ModalsDataTransferService
  ) {}

  setChecked() {
    this.isDisabled = !this.isDisabled;
  }

  setParticipation() {
    this.tournamentService
      .participateTournament(this.ID)
      .pipe(takeUntil(this.unsubus$))
      .subscribe(() => {
        this.modalDataTransferService.setTournamentParticipation();
      });
    this.closeModal();
  }

  leaveTournament() {
    this.tournamentService
      .leaveTournament(this.ID)
      .pipe(takeUntil(this.unsubus$))
      .subscribe(() => {
        this.modalDataTransferService.leaveTournament();
      });
    this.closeModal();
  }

  closeModal() {
    this.store.dispatch(ModalsActions['[modals]CloseModalsSuccess']());
  }
}
