import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TimeService {
  constructor() {}
  calculateTimeRemaining(targetDate: Date): number {
    const now = new Date();
    const difference = targetDate.getTime() - now.getTime();
    return difference;
  }
  calculateTimeRemainingIso(targetDate: string): number {
    const now = new Date();
    const targetDateTime = new Date(targetDate);
    const difference = targetDateTime.getTime() - now.getTime();
    return difference;
  }
  getCurrentTimestamp(): number {
    return Date.now();
  }
  setTimeToUtc(date: Date): string {
    return date.toISOString();
  }
  getTodayInUtc(): string {
    const today = new Date();
    return today.toISOString();
  }
}
