import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { selectVerification } from '../../store/verification/selectors/verification.selectors';
import { selectUser } from '../../store/user/selectors/user.selector';

@Injectable({
  providedIn: 'root',
})
export class VerificationService {
  verification$: Observable<any> = this.store.select(selectVerification);
  unsubus$: Subject<boolean> = new Subject();
  user$: Observable<any> = this.store.select(selectUser);

  constructor(
    private store: Store,
    private http: HttpClient
  ) {}
  getVerificationData(): Observable<any> {
    return new Observable((observer) => {
      this.user$.pipe(takeUntil(this.unsubus$)).subscribe((res: any) => {
        if (res?.loaded && res?.userProfile?.data?.idUser) {
          this.http
            .get(`https://verification.coduluz.com?userId=${res?.userProfile?.data?.idUser}&project=welle`)
            .subscribe((result: any) => {
              observer.next(result);
              observer.complete();
              this.unsubus$.next(true);
              this.unsubus$.complete();
            });
        }
      });
    });
  }
}
