import { QuestService } from './../../../services/quest/quest.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, combineLatest, delay, EMPTY, forkJoin, map, mergeMap, of, switchMap } from 'rxjs';
import { LoyaltyService } from 'src/app/core/services/loyalty/loyalty.service';
import { LoyaltyActions } from '../actions/loyalty.actions';
import { CalendarService } from 'src/app/pages/loyalty/calendar/calendar.service';

@Injectable()
export class loyaltyEffect {
  constructor(
    private actions$: Actions,
    private loyaltyService: LoyaltyService,
    private calendarService: CalendarService,
    private questService: QuestService
  ) {}
  notFinishedBetQuest: boolean = false;

  getWellbetAndReefback$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoyaltyActions['[Loyalty]GetWellBetAndReefback']),
      mergeMap(() =>
        forkJoin([this.loyaltyService.getWellbetBalance(), this.loyaltyService.getRakebackBalance()]).pipe(
          mergeMap(([wellbetRes, reefbackRes]) => [
            LoyaltyActions['[Loyalty]SetWellBetSuccess']({ data: wellbetRes }),
            LoyaltyActions['[Loyalty]SetReefbackSuccess']({ data: reefbackRes }),
          ]),
          catchError(() =>
            of(LoyaltyActions['[Loyalty]SetWellBetFailure'](), LoyaltyActions['[Loyalty]SetReefbackFailure']())
          )
        )
      )
    )
  );
  getCombinedLoyaltyData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoyaltyActions['[Loyalty]GetCombinedLoyaltyData']),
      switchMap(() =>
        combineLatest([
          this.loyaltyService
            .getTradableBalance()
            .pipe(
              map((tradeableBalance) =>
                tradeableBalance.balance
                  ? LoyaltyActions['[Loyalty]SetTradableBalanceSuccess']({ data: tradeableBalance })
                  : LoyaltyActions['[Loyalty]SetTradableBalanceFailure']()
              )
            ),
          this.loyaltyService
            .getUserRewardedStatistics()
            .pipe(
              map((rewardedStatistics) =>
                rewardedStatistics.totalRewarded
                  ? LoyaltyActions['[Loyalty]SetRewardedStatisticsSuccess']({ data: rewardedStatistics })
                  : LoyaltyActions['[Loyalty]SetRewardedStatisticsFailure']()
              )
            ),
        ]).pipe(
          catchError(() =>
            of([
              LoyaltyActions['[Loyalty]SetTradableBalanceFailure'](),
              LoyaltyActions['[Loyalty]SetRewardedStatisticsFailure'](),
            ])
          )
        )
      ),
      mergeMap((actions) => actions)
    )
  );
  getCalendarPendingReward$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoyaltyActions['[Loyalty]GetCalendarPendinRewards']),
      switchMap(() => {
        const currentDate = new Date();
        const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 1);
        const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 3, 0);

        return this.loyaltyService.getCalendarActions(startDate, endDate).pipe(
          map((res: any) => {
            const pendingSum = this.calendarService.calculatePendingSum(res, currentDate);
            return LoyaltyActions['[Loyalty]SetCalendarPendinRewardsSuccess']({ data: pendingSum });
          }),
          catchError(() => of(LoyaltyActions['[Loyalty]SetCalendarPendinRewardsFailure']()))
        );
      })
    )
  );
  getQuests$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoyaltyActions['[Loyalty]GetFinishedQuestsCount']),
      switchMap(() =>
        this.questService.getAllQuests().pipe(
          map((res: any) => {
            const readyToClaimCount = res.filter((quest: any) =>
              quest.quest_progress.some((progress: any) => progress.status === 'FINISHED')
            ).length;

            const readyToClaimAuthQuest = res.some(
              (quest: any) =>
                quest.slug === 'login-quest' &&
                quest.quest_progress.some((progress: any) => progress.status === 'FINISHED')
            );
            const foundBetQuest = res.find((quest: any) => quest.slug === 'bet-quest');
            this.notFinishedBetQuest = foundBetQuest.quest_progress.length === 0;
            const FinishedQuestsCount = {
              readyToClaimCount: readyToClaimCount,
              readyToClaimAuthQuest: readyToClaimAuthQuest,
            };
            return LoyaltyActions['[Loyalty]SetFinishedQuestsCountSuccess']({ data: FinishedQuestsCount });
          }),
          catchError(() => of(LoyaltyActions['[Loyalty]SetFinishedQuestsCountFailure']()))
        )
      )
    )
  );

  checkFinishedBetQuest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoyaltyActions['[Loyalty]SetUserTurnoverSuccess']),
      switchMap(() => {
        if (this.notFinishedBetQuest) {
          return of(LoyaltyActions['[Loyalty]GetFinishedQuestsCount']()).pipe(delay(600));
        }
        return EMPTY;
      })
    )
  );
}
