import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { dateValues, monthInterface } from 'src/app/core/interfaces/interfaces';
import { Store } from '@ngrx/store';
import { ModalsActions } from 'src/app/core/store/modals/actions/modals.actions';
import { MainButtonComponent } from '../../buttons/main-button/main-button.component';
import { ModalsDataTransferService } from 'src/app/core/services/modalService/modals-data-transfer.service';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'app-select-box-date-modal',
  standalone: true,
  imports: [CommonModule, MainButtonComponent, TranslocoModule],
  templateUrl: './select-box-date-modal.component.html',
  styleUrl: './select-box-date-modal.component.scss',
})
export class SelectBoxDateModalComponent implements OnInit {
  @Input()
  selectedModal: string = '';
  @Input() text: string = '';
  months: Array<monthInterface> = [
    {
      month: 'Jan',
      days: 31,
      date: '01',
    },
    {
      month: 'Feb',
      days: 28,
      date: '02',
    },
    {
      month: 'Mar',
      days: 31,
      date: '03',
    },
    {
      month: 'Apr',
      days: 30,
      date: '04',
    },
    {
      month: 'May',
      days: 31,
      date: '05',
    },
    {
      month: 'Jun',
      days: 30,
      date: '06',
    },
    {
      month: 'Jul',
      days: 31,
      date: '07',
    },
    {
      month: 'Aug',
      days: 31,
      date: '08',
    },
    {
      month: 'Sep',
      days: 30,
      date: '09',
    },
    {
      month: 'Oct',
      days: 31,
      date: '10',
    },
    {
      month: 'Nov',
      days: 30,
      date: '11',
    },
    {
      month: 'Dec',
      days: 31,
      date: '12',
    },
  ];
  years: Array<Array<number>> = [];
  adulthoodDate!: Date;
  placeholders: dateValues = {};
  selectedYearsPage: number = 1;
  currentMonth: number = 1;
  currentDay: number = 1;
  selectedCurrentYear: boolean = false;
  selectedCurrentMonth: boolean = false;
  days: Array<number> = [];
  isLeapYear: boolean = false;
  constructor(
    private store: Store,
    private modalDataTransferService: ModalsDataTransferService
  ) {}
  ngOnInit() {
    this.placeholders = this.modalDataTransferService.getPlacholders();
    this.getYears();
    this.getDays();
  }

  getYears() {
    const currentYear = new Date().getTime();
    const adulthoodTime = this.text === 'est' ? 662695992000 : 568080000000; // 18 years in miliseconds
    this.adulthoodDate = new Date(currentYear - adulthoodTime);
    let a: Array<number> = [];
    this.years = [];
    for (let i = this.adulthoodDate.getFullYear() - 99, k = 1; i <= this.adulthoodDate.getFullYear(); i++, k++) {
      a.push(i);
      if (a.length == 20) {
        this.years[(k - 20) / 20] = [...a];
        a = [];
      }
    }
    this.selectedCurrentYear = this.placeholders['Year']['selectedValue'] === this.adulthoodDate.getUTCFullYear();
  }
  selectYear(value: number) {
    this.placeholders['Year']['selectedValue'] = value;
    this.selectedCurrentYear = value === this.adulthoodDate.getUTCFullYear();
    this.closeDatePicker();
    this.chekcIsLeapYear();
    this.findDaysMoreYearChange();
    if (this.selectedCurrentYear) this.placeholders['Month']['selectedValue'] = '';
    this.getDays();
    this.modalDataTransferService.selectYear(value);
  }
  selectMonth(value: any) {
    this.placeholders['Month']['selectedValue'] = value.month;
    const selectedMonth: any = value.month;
    this.selectedCurrentMonth = Number(value.date) === this.adulthoodDate.getUTCMonth();

    this.findDaysMore(this.placeholders['Day'], selectedMonth);
    this.closeDatePicker();
    this.modalDataTransferService.selectMonth(value);
    this.getDays();
  }
  selectDay(value: any) {
    this.placeholders['Day']['selectedValue'] = value;
    this.closeDatePicker();
    this.modalDataTransferService.selectDay(value);
  }
  getDays() {
    this.currentMonth = this.adulthoodDate.getUTCMonth();
    this.currentDay = this.adulthoodDate.getUTCDate();
    this.days = [];
    for (const key in this.placeholders) {
      if (this.placeholders[key as keyof dateValues].selectedValue) {
        this.months.find((el: any) => {
          if (el.month == this.placeholders[key as keyof dateValues].selectedValue) {
            if (el.month == 'Feb') {
              this.isLeapYear ? (el.days = 29) : (el.days = 28);
            }
            for (let i = 0; i < el.days; i++) {
              this.days[i] = i + 1;
            }
            this.selectedCurrentMonth =
              Number(el.date) === this.adulthoodDate.getUTCMonth() + 1 && this.selectedCurrentYear;
          }
        });
      }
    }
  }
  findDaysMore(elem: any, selectedMonth: string) {
    if (elem.selectedValue !== undefined) {
      this.months.find((el: any) => {
        if (el.month == selectedMonth) {
          if (el.days < elem.selectedValue) {
            return (elem.selectedValue = '');
          }
        }
        return false;
      });
    }
  }
  closeDatePicker() {
    this.store.dispatch(ModalsActions['[modals]CloseModalsSuccess']());
    this.selectedModal = '';
  }
  findDaysMoreYearChange() {
    if (this.placeholders['Day']['selectedValue'] > this.days.length) this.placeholders['Day']['selectedValue'] = '';
  }
  chekcIsLeapYear() {
    this.isLeapYear = this.placeholders['Year']['selectedValue'] % 4 == 0 ? true : false;
  }
  nextPage() {
    this.selectedYearsPage--;
  }
  prevPage() {
    this.selectedYearsPage++;
  }
  handleMonthSelect(item: monthInterface, index: number): void {
    if (this.selectedCurrentYear) {
      if (index <= this.currentMonth) {
        this.selectMonth(item);
      }
    } else {
      this.selectMonth(item);
    }
  }
  handleDaySelect(item: number, index: number): void {
    if (this.selectedCurrentMonth) {
      if (index <= this.currentDay - 1) {
        this.selectDay(item);
      }
    } else {
      this.selectDay(item);
    }
  }
}
