import { Injectable } from '@angular/core';
import { TimeService } from 'src/app/core/services/time/time.service';

@Injectable({
  providedIn: 'root',
})
export class CalendarService {
  constructor(private timeService: TimeService) {}

  groupActionsByDate(actions: any[]): any[] {
    const actionsByDate: any[] = [];
    actions.sort((a, b) =>
      a.startDateOfUsage === b.startDateOfUsage
        ? new Date(a.dateOfUpdate).getTime() - new Date(b.dateOfUpdate).getTime()
        : new Date(a.startDateOfUsage).getTime() - new Date(b.startDateOfUsage).getTime()
    );

    actions.forEach((action) => {
      const actionDate = new Date(action.startDateOfUsage);
      const dateKey = actionDate.toISOString().slice(0, 10);
      let dateGroup = actionsByDate.find((item) => item.date === dateKey);
      if (!dateGroup) {
        dateGroup = { date: dateKey, actions: [] };
        actionsByDate.push(dateGroup);
      }
      if (!dateGroup.actions.some((a: any) => a.id === action.id)) {
        dateGroup.actions.push(action);
      }
    });
    return actionsByDate;
  }

  getDateKey = (year: number, month: number, day: number) => {
    return `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
  };

  checkActionsForCurrentDay(day: any): any {
    const currentDateUtc = this.timeService.getTodayInUtc();
    let readyToTakeSet = false;

    day.actions.forEach((action: any) => {
      if (action.status !== 'claimed') {
        if (
          new Date(currentDateUtc) >= new Date(action.startDateOfUsage) &&
          new Date(currentDateUtc) <= new Date(action.expirationDate)
        ) {
          action.status = 'readyToTake';
          readyToTakeSet = true;
        }
      }
    });

    for (let i = 0; i < day.actions.length - 1; i++) {
      const currentAction = day.actions[i];
      const nextAction = day.actions[i + 1];

      if (readyToTakeSet) {
        if (currentAction.status === 'readyToTake' && nextAction.status === 'active') {
          nextAction.status = 'next';
        }
      } else {
        if (
          (currentAction.status === 'readyToTake' || currentAction.status === 'claimed') &&
          nextAction.status === 'active'
        ) {
          nextAction.status = 'next';
        }
      }
    }

    return day;
  }

  calculatePendingSum(actions: any[], currentDate: Date): number {
    return actions.reduce((total: number, action: any) => {
      const actionDate = new Date(action.expirationDate);
      if (actionDate >= currentDate && action.status !== 'claimed') {
        return total + action.amount;
      }
      return total;
    }, 0);
  }
  processAction(action: any, day: any) {
    day.fullAmount += action.amount;

    if (action.status === 'claimed') {
      day.claimedAmount += action.amount;
    } else {
      day.pendingSum += action.amount;
    }
    if (action.status === 'readyToTake') {
      day.readyToClaimAmount += action.amount;
      day.readyToClaim = true;
    }
    return day;
  }
  addInformationText(actions: any[], day: any, isBonusCalendar: boolean) {
    const accumulatorSums: { [key: string]: number } = {};
    actions.forEach((action: any) => {
      if (isBonusCalendar && action.status !== 'readyToTake') return;

      if (action.fromAccumulator) {
        Object.keys(action.fromAccumulator).forEach((key) => {
          if (accumulatorSums[key]) {
            accumulatorSums[key] += action.fromAccumulator[key];
          } else {
            accumulatorSums[key] = action.fromAccumulator[key];
          }
        });
      }
      if (!isBonusCalendar) {
        day = this.processAction(action, day);
      }
    });

    day.informationText = Object.entries(accumulatorSums).map(([name, sum]) => ({ name, sum }));
  }

  checkActionsStatus(actions: any): any {
    const currentDateUtc = this.timeService.getTodayInUtc();

    actions.forEach((action: any) => {
      if (action.status !== 'claimed') {
        if (
          new Date(currentDateUtc) >= new Date(action.startDateOfUsage) &&
          new Date(currentDateUtc) <= new Date(action.expirationDate)
        ) {
          action.status = 'readyToTake';
        }
      }
    });

    return actions;
  }
}
