<ng-container *transloco="let t">
  <div
    *ngIf="openModal"
    [class]="'flex flex-row justify-between p-4 pr-6 gap-[0.625rem] ' + content[openModal]?.background">
    <div>
      <img class="min-w-6 min-h-6" [src]="content[openModal]?.img" [alt]="openModal" />
    </div>
    <div class="flex flex-col gap-[0.625rem]">
      <div class="flex flex-col">
        @if (bonusName !== '') {
          <span>{{ t(bonusName) }} {{ t(content[openModal]?.title) }}</span>
        } @else {
          <span>{{ t(content[openModal]?.title) }}</span>
        }
        <span>{{ t(content[openModal]?.text) }}</span>
      </div>
      @if (openModal === 'warningBonus') {
        <div class="flex flex-row gap-4">
          <img
            class="cursor-pointer"
            width="14"
            height="14 "
            (click)="checkBoxClick()"
            [src]="
              doNotShowAgain
                ? 'https://cdn.chipychapa.com//welle/General_site/Sidebar/Features/Loyalty/do_not_show_again_selected.svg'
                : 'https://cdn.chipychapa.com//welle/General_site/Sidebar/Features/Loyalty/do_not_show_again_unselected.svg'
            "
            alt="checkbox" />
          <span class="font-semibold text-sm">{{ t('loyalty.modal.dont_show_again') }}</span>
        </div>
      }
      <div [class]="'w-full flex flex-col sm:flex-row  gap-[0.625rem] justify-start items-center'">
        <app-main-button
          class="w-full md:w-auto"
          *ngFor="let button of content[openModal]?.Btns"
          [name]="button?.text"
          [variant]="button?.variant"
          (click)="buttonAction(button?.url)"
          [id]="button?.text + button?.title?.replace('loyalty.', '') + 'Btn'"></app-main-button>
      </div>
    </div>
    <div class="h-6 min-w-6 items-center flex justify-center cursor-pointer" (click)="close()">
      <img src="https://cdn.chipychapa.com//welle/General_site/Sidebar/Cashier/Withdrawals/close.svg" alt="close" />
    </div>
  </div>
</ng-container>
