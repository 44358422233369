import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, throwError } from 'rxjs';
import { verificationActions } from '../actions/verification.actions';
import { VerificationService } from 'src/app/core/services/verification/verification.service';
import { ConfigActions } from '../../config/actions/config.actions';

@Injectable()
export class verificationEffects {
  constructor(
    private actions$: Actions,
    private verificationService: VerificationService
  ) {}

  $verificationStatus = createEffect(() => {
    return this.actions$.pipe(
      ofType(ConfigActions['[config]ConfigsSuccess']),
      mergeMap(() =>
        this.verificationService.getVerificationData().pipe(
          map((res: any) => {
            return verificationActions['[verification]SetVerificationStatusSuccess']({
              data: res,
            });
          }),
          catchError((error: any) => {
            throwError(() => error);
            return of(verificationActions['[verification]SetVerificationStatusFailure']());
          })
        )
      )
    );
  });
}
