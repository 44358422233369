<div
  class="flex flex-col md:flex-row h-full w-full gap-8 items-center p-4 md:p-8 text-mainTextLight"
  *transloco="let t">
  <div
    class="flex flex-col rounded-xl bg-sidebarLight h-[10.313rem] max-w-[7.625rem] min-w-[7.625rem] items-center justify-center gap-2">
    <img
      src="https://cdn.chipychapa.com//welle/General_site/Sidebar/Features/Loyalty/total_reward.png"
      alt="total_reward"
      class="h-16 w-16" />
    <div class="flex flex-col items-center gap-1">
      <h3 class="text-base font-normal text-center">{{ t('loyalty.balance.total_rewarded') }}</h3>
      <div class="font-bold text-xl flex flex-row gap-1 items-center truncate">
        {{ totalSum.toFixed(2) }}
        <img
          class="h-4 w-4"
          src="https://cdn.chipychapa.com//welle/General_site/Sidebar/Features/Loyalty/wellcoin.svg"
          alt="wellcoin" />
      </div>
    </div>
  </div>
  <div class="gap-4 flex flex-col w-full">
    <div *ngFor="let item of userRewardedStatisticsArray" class="flex flex-row items-center h-6">
      <div class="w-24 text-left text-sm font-normal">
        {{ t('loyalty.balance.' + item.name) }}
      </div>
      <div class="flex flex-row gap-2 w-full items-center">
        <div class="flex-grow rounded-full overflow-hidden bg-mainText h-[0.375rem] relative">
          <div
            class="absolute top-0 left-0 h-full bg-darkBlueBgLight dark:bg-yellowBgMain rounded transition-all duration-500 ease-linear"
            [style.width]="item.progress + '%'"></div>
        </div>
        <div class="flex flex-row gap-1 items-center text-right">
          <div class="text-sm font-bold truncate">{{ item.balance.toFixed(2) }}</div>
          <div class="h-4 w-4 flex justify-center items-center">
            <img
              class="h-full"
              src="https://cdn.chipychapa.com//welle/General_site/Sidebar/Features/Loyalty/wellcoin.svg"
              alt="wellcoin" />
          </div>
        </div>
        <div class="w-12 text-sm font-semibold">
          {{ item.progress.toFixed(2) + '%' }}
        </div>
      </div>
    </div>
  </div>
</div>
