import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { appState } from 'src/app';
import { Observable, Subject, debounceTime, takeUntil } from 'rxjs';
import { selectHelpers } from 'src/app/core/store/helpers/selector/helpers.selectors';
import { Assets, Container, Rectangle, autoDetectRenderer } from 'pixi.js';
import { BunnyV8 } from './v8/Bunny-v8';
import { NgClass } from '@angular/common';
import { LocalStorageService } from 'src/app/core/services/localStorage/local-storage.service';

@Component({
  selector: 'app-particles-background',
  templateUrl: './particles-background.component.html',
  standalone: true,
  styleUrls: ['./particles-background.component.scss'],
  imports: [NgClass],
})
export class ParticlesBackgroundComponent implements OnDestroy {
  config$: Observable<any> = this.store.select(selectHelpers);
  unsubus$: Subject<boolean> = new Subject();

  renderer: any;
  pause: boolean = false;
  loaded: boolean = false;
  constructor(
    private store: Store<appState>,
    private storageService: LocalStorageService
  ) {
    const bubbleAnimStoredValue = this.storageService.getLocalStorage('playBubbleAnim');
    if (bubbleAnimStoredValue !== null && JSON.parse(bubbleAnimStoredValue) === false) {
      return;
    }
    this.storageService.setLocalStorage('playBubbleAnim', true);

    setTimeout(() => {
      this.renderer?.destroy();
      this.renderer?.view.destroy();
    });
    this.config$.pipe(takeUntil(this.unsubus$), debounceTime(500)).subscribe((res: any) => {
      if (res?.isLoaded) {
        this.pause = true;
        const particles: null | HTMLElement = document.getElementById('particles');
        if (particles && particles.childNodes.length > 0) {
          for (let i = 0; i < particles.childNodes.length; i++) {
            const child = particles.childNodes[i];
            particles.removeChild(child);
          }
          this.renderer.destroy();
        }
        const totalBunnies = res?.isMobile ? 5 : 10;
        const preference = 'webgl';
        this.bunnyMarkV8({ totalBunnies, preference });
        this.pause = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.renderer?.destroy();
    this.renderer?.view.destroy();
    this.unsubus$.next(true);
    this.unsubus$.complete();
  }

  async bunnyMarkV8({ totalBunnies, preference }: { totalBunnies: number; preference: 'webgl' | 'webgpu' }) {
    const container: HTMLElement | null = document.getElementById('particles');
    const bunnyPool: BunnyV8[] = [];

    this.renderer = await autoDetectRenderer({
      preference,
      clearBeforeRender: true,
      backgroundAlpha: 1,
      backgroundColor: 0xeff6ff,
      width: container?.clientWidth || document.body.clientWidth,
      height: container?.clientHeight || document.body.clientHeight,
      resolution: 1,
      antialias: false,
      hello: true,
    });

    const renderer = this.renderer;

    container?.appendChild(renderer.view.canvas as HTMLCanvasElement);

    const stage = new Container();

    const textures = Object.values(
      await Assets.load([
        // 'https://cdn.chipychapa.com//welle/General_site/Animation/1.png',
        // 'https://cdn.chipychapa.com//welle/General_site/Animation/2.png',
        // 'https://cdn.chipychapa.com//welle/General_site/Animation/3.png',
        // 'https://cdn.chipychapa.com//welle/General_site/Animation/4.png',
        // 'https://cdn.chipychapa.com//welle/General_site/Animation/5.png',
        'https://cdn.chipychapa.com//welle/General_site/Background/winter/1.svg',
        'https://cdn.chipychapa.com//welle/General_site/Background/winter/2.svg',
        'https://cdn.chipychapa.com//welle/General_site/Background/winter/3.svg',
        'https://cdn.chipychapa.com//welle/General_site/Background/winter/4.svg',
        'https://cdn.chipychapa.com//welle/General_site/Background/winter/5.svg',
      ])
    );

    const bounds = new Rectangle(
      0,
      0,
      container?.clientWidth || document.body.clientWidth,
      container?.clientHeight || document.body.clientHeight
    );

    const bunnies: BunnyV8[] = [];

    function addBunny() {
      const bunny = bunnyPool.pop() || new BunnyV8(textures[bunnies.length % textures.length], bounds);
      bunny.reset();
      stage.addChild(bunny.view);
      bunnies.push(bunny);
    }

    for (let i = 0; i < totalBunnies; i++) {
      addBunny();
    }

    this.renderUpdate(bunnies, renderer, stage);
    this.loaded = true;
  }

  renderUpdate(bunnies: any, renderer: any, stage: any) {
    if (!this.pause) {
      for (let i = 0; i < bunnies?.length; i++) {
        bunnies[i]?.update();
      }
      if (renderer?.renderPipes !== null) {
        renderer?.render(stage);
        requestAnimationFrame(() => this.renderUpdate(bunnies, renderer, stage));
      }
    }
  }
}
