'use strict';

(function () {
  // Start helpers
  function setCookie(name, value, days) {
    var expires = '';
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
  }

  function getCookie(name) {
    var nameEQ = name + '=';
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
  var affParams = '';

  function getQueryString(qs) {
    var url = qs || window.affiliate || location.search;
    var qs = url.substring(url.indexOf('?') + 1).split('&');
    affParams = encodeURIComponent(url.substring(url.indexOf('?') + 1));
    for (var i = 0, result = {}; i < qs.length; i++) {
      qs[i] = qs[i].split('=');
      result[qs[i][0]] = decodeURIComponent(qs[i][1]);
    }
    return result;
  }
  // End helpers

  var affCookieName = '_aff';
  var affCookieData = '';
  if ((affCookieData = getCookie(affCookieName))) {
    window.affCookie = getQueryString(affCookieData);
    return;
  }
  var qs = getQueryString();

  var affSystem = '';
  var affId = '';
  var affData = location.search;

  // Checking for affiliates
  if (qs.uid && qs.pid && qs.cid && qs.lid) {
    affSystem = 'goldtime';
    affId = qs.uid;
  } else if (qs.faff) {
    affSystem = 'faff';
    affId = qs.faff;
    affData = qs.sub || '';
    if (qs.cid) {
      affData += encodeURIComponent('&cid=') + qs.cid;
    }
  }

  if (affSystem != '') {
    setCookie('faff', affParams, 7);
    affCookieData = 'system=' + affSystem + '&id=' + affId + '&data=' + affData + '&params=' + affParams;
    window.affCookie = getQueryString(affCookieData);
    setCookie(affCookieName, encodeURIComponent(affCookieData), 7);
    setTimeout(function () {
      var img = new Image();
      img.onload = function () {};
      img.src = '/api/v1/affTrack/track.gif';
    }, 0);
  }
})();
