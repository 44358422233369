import { PointOfUnit, ResultDate, TimeUnit } from '../interfaces/interfaces';

export class DateBuilder {
  date: Date;
  type: string;
  startDate: Date;
  endDate: Date;
  constructor(type: string) {
    this.type = type;
    this.date = this.createUTCDate();
    this.startDate = new Date();
    this.endDate = new Date();
  }

  createUTCDate() {
    const now = new Date();
    return new Date(
      Date.UTC(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        now.getUTCDate(),
        now.getUTCHours(),
        now.getUTCMinutes(),
        now.getUTCSeconds(),
        now.getUTCMilliseconds()
      )
    );
  }
  returnDate(): { startDate: Date; endDate: Date } {
    return {
      startDate: this.startDate,
      endDate: this.endDate,
    };
  }

  skipByUnit(unit: number, unitType: TimeUnit) {
    if (unitType === TimeUnit.DAY) {
      this.startDate.setUTCDate(this.date.getUTCDate() - unit);
    } else if (unitType === TimeUnit.MONTH) {
      this.startDate.setUTCMonth(this.date.getUTCMonth() - unit);
    } else if (unitType === TimeUnit.YEAR) {
      this.startDate.setUTCFullYear(this.date.getUTCFullYear() - unit);
    } else if (unitType === TimeUnit.WEEK) {
      this.startDate.setUTCDate(this.date.getUTCDate() - unit * 7);
    }
    return this;
  }

  takeByUnit(unit: number, unitType: TimeUnit) {
    if (unitType === TimeUnit.DAY) {
      this.endDate.setUTCDate(this.startDate.getUTCDate() + unit);
    } else if (unitType === TimeUnit.MONTH) {
      this.endDate.setUTCMonth(this.startDate.getUTCMonth() + unit);
    } else if (unitType === TimeUnit.YEAR) {
      this.endDate.setUTCFullYear(this.startDate.getUTCFullYear() + unit);
    } else if (unitType === TimeUnit.WEEK) {
      this.endDate.setUTCDate(this.startDate.getUTCDate() + unit * 7);
    }
    return this;
  }

  setPoint(pointOfUnit: PointOfUnit, unitType: TimeUnit, resultDate: ResultDate) {
    if (unitType == TimeUnit.DAY) {
      if (pointOfUnit == PointOfUnit.START) {
        this[resultDate].setUTCHours(0, 0, 0, 0);
      } else if (pointOfUnit == PointOfUnit.END) {
        this[resultDate].setUTCHours(23, 59, 59, 999);
      }
    }

    if (unitType == TimeUnit.WEEK) {
      if (pointOfUnit == PointOfUnit.START) {
        this[resultDate].setUTCDate(this[resultDate].getUTCDate() - this[resultDate].getUTCDay());
        this[resultDate].setUTCHours(0, 0, 0, 0);
      } else if (pointOfUnit == PointOfUnit.END) {
        this[resultDate].setUTCDate(this[resultDate].getUTCDate() + (6 - this[resultDate].getUTCDay()));
        this[resultDate].setUTCHours(23, 59, 59, 999);
      }
    }

    if (unitType == TimeUnit.MONTH) {
      if (pointOfUnit == PointOfUnit.START) {
        this[resultDate].setUTCDate(1);
        this[resultDate].setUTCHours(0, 0, 0, 0);
      } else if (pointOfUnit == PointOfUnit.END) {
        this[resultDate].setUTCMonth(this[resultDate].getUTCMonth() + 1);
        this[resultDate].setUTCDate(0);
        this[resultDate].setUTCHours(23, 59, 59, 999);
      }
    }

    if (unitType == TimeUnit.YEAR) {
      if (pointOfUnit == PointOfUnit.START) {
        this[resultDate].setUTCMonth(0);
        this[resultDate].setUTCDate(1);
        this[resultDate].setUTCHours(0, 0, 0, 0);
      } else if (pointOfUnit == PointOfUnit.END) {
        this[resultDate].setUTCMonth(11);
        this[resultDate].setUTCDate(31);
        this[resultDate].setUTCHours(23, 59, 59, 999);
      }
    }
    return this;
  }
}
