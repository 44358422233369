<div class="w-full box-shadow p-2 py-3" *transloco="let t">
  <div class="w-full">
    <div class="p-2 w-full rounded-lg grid md:grid-cols-3 grid-cols-[20%,1fr,20%]">
      <h1 class="font-semibold text-sm mmd:text-lg text-center col-start-2">
        {{ t('sidenav.cashier.withdraw.limits_title') }}
      </h1>
      <div class="cursor-pointer h-6 w-6 flex justify-self-end items-center" (click)="closeModal()">
        <img
          width="16"
          height="16"
          src="https://cdn.chipychapa.com//welle/General_site/Sidebar/Cashier/Deposit/close.svg"
          alt="close modal" />
      </div>
    </div>
  </div>

  <!-- laptop, desktop and tablet view -->
  <div
    class="hidden sm:block w-full flex-col flex gap-4 lg:items-center lg:text-center py-2 mmd:px-2 text-mainTextLight dark:text-mainText">
    <p class="font-normal text-base py-2">
      <span class="font-bold">{{ withdrawLimits.byCards }}</span>
      {{ withdrawLimits.cardLimit.line1 }}, {{ withdrawLimits.cardLimit.line2 }}, {{ withdrawLimits.cardLimit.line3 }},
      {{ withdrawLimits.cardLimit.line4 }}
    </p>
    <p class="font-normal text-base py-2">
      <span class="font-bold">{{ withdrawLimits.byWallets }}</span>
      {{ withdrawLimits.walletLimit.line1 }}, {{ withdrawLimits.walletLimit.line2 }},
      {{ withdrawLimits.walletLimit.line3 }}, {{ withdrawLimits.walletLimit.line4 }}
    </p>
    <p class="font-normal text-base py-2">
      <span class="font-bold">{{ withdrawLimits.byCrypto }}</span>
      {{ withdrawLimits.cryptolimit }}
    </p>
    <p class="font-normal text-base py-2">
      {{ withdrawLimits.depByCard }}
      <span class="font-bold">{{ withdrawLimits.depByCardLimit }}</span>
    </p>
    <p class="font-normal text-base py-2">
      {{ withdrawLimits.depByCrypto }}
      <span class="font-bold">{{ withdrawLimits.depByCryptoLimit }}</span>
    </p>
    <h6 class="font-normal text-base">{{ withdrawLimits.example }}</h6>
  </div>

  <!-- mobile view -->
  <div class="sm:hidden w-full flex-col flex gap-4 items-center py-2 text-mainTextLight dark:text-mainText">
    <div class="grid grid-cols-[25%,1fr] text-sm">
      <p class="font-bold col-start-1">{{ withdrawLimits.byCards }}</p>
      <div class="flex flex-col pb-2">
        <p class="font-normal">{{ withdrawLimits.cardLimit.line1 }},</p>
        <p class="font-normal">{{ withdrawLimits.cardLimit.line2 }},</p>
        <p class="font-normal">{{ withdrawLimits.cardLimit.line3 }},</p>
        <p class="font-normal">{{ withdrawLimits.cardLimit.line4 }}</p>
      </div>
      <p class="font-bold col-start-1">{{ withdrawLimits.byWallets }}</p>
      <div class="flex flex-col pb-2">
        <p class="font-normal">{{ withdrawLimits.walletLimit.line1 }},</p>
        <p class="font-normal">{{ withdrawLimits.walletLimit.line2 }},</p>
        <p class="font-normal">{{ withdrawLimits.walletLimit.line3 }},</p>
        <p class="font-normal">{{ withdrawLimits.walletLimit.line4 }}</p>
      </div>
      <p class="font-bold col-start-1">{{ withdrawLimits.byCrypto }}</p>
      <div class="flex flex-col pb-2">
        <p class="font-normal">{{ withdrawLimits.cryptolimit }}</p>
      </div>
      <p class="font-normal col-span-2 py-2">
        {{ withdrawLimits.depByCard }}
        <span class="font-semibold">{{ withdrawLimits.depByCardLimit }}</span>
      </p>
      <p class="font-normal col-span-2 py-2">
        {{ withdrawLimits.depByCrypto }}
        <span class="font-semibold">{{ withdrawLimits.depByCryptoLimit }}</span>
      </p>
      <h6 class="font-normal col-span-2">{{ withdrawLimits.example }}</h6>
    </div>
  </div>

  <!-- img to withdrawal limits example -->
  <div class="py-1 md:py-3 flex justify-center">
    <img [src]="withdrawLimits.limitImage" alt="example_limits" class="relative" />
    <a [href]="withdrawLimits.limitImage" target="_blank">
      <img
        src="https://cdn.chipychapa.com//welle/General_site/Sidebar/Cashier/Withdrawals/zoom.svg"
        alt="zoomIn"
        class="absolute bottom-5 right-4 sm:hidden" />
    </a>
  </div>
</div>
