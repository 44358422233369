import { getClient, withScope } from '../currentScopes.js';
import { captureMessage, captureException } from '../exports.js';
import { defineIntegration } from '../integration.js';
import { addConsoleInstrumentationHandler } from '../utils-hoist/instrument/console.js';
import { CONSOLE_LEVELS } from '../utils-hoist/logger.js';
import { addExceptionMechanism } from '../utils-hoist/misc.js';
import { severityLevelFromString } from '../utils-hoist/severity.js';
import { safeJoin } from '../utils-hoist/string.js';
import { GLOBAL_OBJ } from '../utils-hoist/worldwide.js';
const INTEGRATION_NAME = 'CaptureConsole';
const _captureConsoleIntegration = (options = {}) => {
  const levels = options.levels || CONSOLE_LEVELS;
  // TODO(v9): Flip default value to `true`
  const handled = !!options.handled;
  return {
    name: INTEGRATION_NAME,
    setup(client) {
      if (!('console' in GLOBAL_OBJ)) {
        return;
      }
      addConsoleInstrumentationHandler(({
        args,
        level
      }) => {
        if (getClient() !== client || !levels.includes(level)) {
          return;
        }
        consoleHandler(args, level, handled);
      });
    }
  };
};

/**
 * Send Console API calls as Sentry Events.
 */
const captureConsoleIntegration = defineIntegration(_captureConsoleIntegration);
function consoleHandler(args, level, handled) {
  const captureContext = {
    level: severityLevelFromString(level),
    extra: {
      arguments: args
    }
  };
  withScope(scope => {
    scope.addEventProcessor(event => {
      event.logger = 'console';
      addExceptionMechanism(event, {
        handled,
        type: 'console'
      });
      return event;
    });
    if (level === 'assert') {
      if (!args[0]) {
        const message = `Assertion failed: ${safeJoin(args.slice(1), ' ') || 'console.assert'}`;
        scope.setExtra('arguments', args.slice(1));
        captureMessage(message, captureContext);
      }
      return;
    }
    const error = args.find(arg => arg instanceof Error);
    if (error) {
      captureException(error, captureContext);
      return;
    }
    const message = safeJoin(args, ' ');
    captureMessage(message, captureContext);
  });
}
export { captureConsoleIntegration };
