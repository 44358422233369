import { createReducer, on } from '@ngrx/store';
import { getCountries } from '../actions/helpers.actions';

export const getCountriesKey = 'countries';

export interface State {
  countries: any;
}

export const initialState: State = {
  countries: undefined,
};

export const reducer = createReducer(
  initialState,
  on(getCountries['[getCountries]GetCountriesSuccess'], (state, { data }) => {
    return {
      ...state,
      countries: data,
    };
  })
);
