import { createReducer, on } from '@ngrx/store';
import { Dates } from '../actions/helpers.actions';

export const datesTransactionsConfigFeatureKey = 'datesTransactions';

export interface State {
  finalDate: any;
  initialDate: any;
  selectedMonthRightPage: any;
  selectedMonthLeftPage: any;
  isLoaded: boolean;
}

export const initialState: State = {
  finalDate: undefined,
  initialDate: undefined,
  selectedMonthRightPage: undefined,
  selectedMonthLeftPage: undefined,
  isLoaded: false,
};

export const reducer = createReducer(
  initialState,
  on(
    Dates['[dates]SetDatesTransactions'],
    (state, { initialDate, finalDate, selectedMonthLeftPage, selectedMonthRightPage }) => {
      return {
        ...state,
        initialDate: initialDate,
        finalDate: finalDate,
        selectedMonthLeftPage: selectedMonthLeftPage,
        selectedMonthRightPage: selectedMonthRightPage,
        isLoaded: true,
      };
    }
  )
);
