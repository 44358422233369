import { CommonModule, NgIf } from '@angular/common';
import { Component, Input, OnDestroy } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { LocalStorageService } from 'src/app/core/services/localStorage/local-storage.service';
import { NavigationService } from 'src/app/core/services/navigation/navigation.service';
import { ModalsActions } from 'src/app/core/store/modals/actions/modals.actions';
import { MainButtonComponent } from 'src/app/shared/buttons/main-button/main-button.component';

@Component({
  selector: 'app-loyalty-page-restrictions-modal',
  standalone: true,
  imports: [CommonModule, NgIf, TranslocoModule, MainButtonComponent],
  templateUrl: './loyalty-page-restrictions-modal.component.html',
  styleUrl: './loyalty-page-restrictions-modal.component.scss',
})
export class LoyaltyPageRestrictionsModalComponent implements OnDestroy {
  @Input()
  openModal: 'warningBonus' | 'warning' | null = null;
  doNotShowAgain: boolean = false;
  @Input() bonusName: string = '';
  content: any = {
    warningBonus: {
      background: 'bg-[#FEFCE8]',
      title: 'loyalty.restriction.title.loyalty_on_pause',
      text: 'loyalty.restriction.text.loyalty_on_pause',
      img: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/Cashier/Withdrawals/allert.svg',
      Btns: [
        {
          variant: 'yellowBgSmall',
          text: 'continue_playing',
          url: '/games/all',
        },
        {
          variant: 'mainTextLight',
          text: 'active_bonuses',
          url: '/(aside:rewards/active-bonuses)',
        },
      ],
    },
    warning: {
      background: 'bg-[#FEFCE8]',
      title: 'loyalty.restriction.title.backend_error',
      text: 'loyalty.restriction.text.backend_error',
      img: 'https://cdn.chipychapa.com//welle/General_site/Sidebar/Cashier/Withdrawals/allert.svg',
      Btns: [
        {
          variant: 'yellowBgSmall',
          text: 'continue_playing',
          url: '/games/all',
        },
        {
          variant: 'mainTextLight',
          text: 'active_bonuses',
          url: '/(aside:rewards/active-bonuses)',
        },
      ],
    },
  };
  constructor(
    private store: Store,
    private navigator: NavigationService,
    private localStorage: LocalStorageService
  ) {}
  ngOnDestroy(): void {
    if (this.doNotShowAgain) this.dontShowAgain();
    this.store.dispatch(ModalsActions['[modals]CloseModalsSuccess']());
  }
  close() {
    this.localStorage.setSessionStorage('isLoyaltyPageRestrictionsModalClosed', true);
    if (this.doNotShowAgain) this.dontShowAgain();
    this.store.dispatch(ModalsActions['[modals]CloseModalsSuccess']());
  }
  buttonAction(url: string) {
    if (this.doNotShowAgain) this.dontShowAgain();
    this.navigator.navigateTo(url);
    this.store.dispatch(ModalsActions['[modals]CloseModalsSuccess']());
  }
  dontShowAgain() {
    const expires = new Date();
    expires.setHours(23, 59, 59, 999);
    document.cookie = `loyaltyOnPauseRestrictionModal=${true};expires=${expires};secure=true;path=/;`;
  }
  checkBoxClick() {
    this.doNotShowAgain = !this.doNotShowAgain;
  }
}
