<div class="w-full box-shadow p-2 py-3" *transloco="let t">
  <div class="w-full flex-col flex gap-3 items-center px-3 pb-3 pt-10">
    <div>
      <img
        width="34"
        height="34"
        src="https://cdn.chipychapa.com//welle/General_site/Notifications/warning.svg"
        alt="warning" />
    </div>
    <div>
      <p class="font-semibold text-lg text-mainTextLight">{{ t('registration.promo_title') }}</p>
    </div>
    <div>
      <p class="font-normal text-base text-mainTextLight">{{ t('registration.promo_description') }}</p>
    </div>
    <div class="flex flex-col gap-4 w-full">
      <button (click)="previousStep()" class="bg-mainBtnLight py-4 rounded-xl font-semibold text-base text-mainTextLight" id="reenterPromo">
        {{ t('buttons.promo_reenter') }}
      </button>
      <button (click)="continueWithoutPromo()" class="bg-carouselCurrent py-4 rounded-xl font-semibold text-base text-mainText" id="withoutPromo">
        {{ t('buttons.promo_without') }}
      </button>
    </div>
  </div>
</div>
