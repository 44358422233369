import { createReducer, on } from '@ngrx/store';
import { RouterViewerActions } from '../actions/router.actions';

export const configFeatureKey = 'routerViewer';

export interface State {
  routerRequest: boolean;
  routerNavigated: boolean;
}

export const initialState: State = {
  routerRequest: false,
  routerNavigated: false,
};

export const reducer = createReducer(
  initialState,
  on(RouterViewerActions['[routerViewer]RouterRequestSuccess'], (state) => {
    return {
      ...state,
      routerRequest: true,
      routerNavigated: false,
    };
  }),
  on(RouterViewerActions['[routerViewer]RouterNavigatedSuccess'], (state) => {
    return {
      ...state,
      routerRequest: false,
      routerNavigated: true,
    };
  })
);
