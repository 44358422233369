@if (keystonePage.slug === 'about') {
  <img
    class="laptopBanner w-full"
    src="https://cdn.chipychapa.com//welle/General_site/About_us/about_us_laptop.png"
    alt="laptopBanner" />
  <img
    class="desktopBanner w-full"
    src="https://cdn.chipychapa.com//welle/General_site/About_us/about_us_dektop.png"
    alt="desktopBanner" />
}

<ng-container *transloco="let t">
  <div class="flex flex-col h-full max-w-[80rem] mx-auto">
    <div
      *ngIf="isMobile && !isLanding"
      [class]="
        'w-full text-xl text-center bg-biggerContainerBgLight' +
        (keystonePage.slug === 'about' ? ' mt-24 font-bold ' : ' pt-24 ')
      ">
      {{ t('sidenav.title.' + keystonePage.slug) }}
    </div>
    @if (keystonePage.slug === 'about') {
      <img
        class="mobileBanner w-full"
        src="https://cdn.chipychapa.com//welle/General_site/About_us/about_us_mob.png"
        alt="mobileBanner" />
      <img
        class="tabletBanner w-full"
        src="https://cdn.chipychapa.com//welle/General_site/About_us/about_us_tablet.png"
        alt="tabletBanner" />
    }
    <div
      [class]="
        'px-2 md:p-4 w-full flex flex-col pb-5 dark:bg-containerBg rounded-xl h-full ' +
        (keystonePage.slug === 'about' ? ' lg:-mt-28 l3xl:-mt-36 ' : ' bg-inputBackground ') +
        (isLanding ? ' md:max-h-[80vh] ' : '  ') +
        (isTermsModal ? ' py-2 gap-4 ' : ' mt-4 md:mt-10 md:gap-4 ')
      ">
      <div
        *ngIf="!isMobile && !isLanding"
        [class]="
          'w-full pt-4 ' +
          (keystonePage.slug === 'about' ? ' text-left pl-5 text-4xl font-bold ' : ' text-center text-xl ')
        ">
        {{ t('sidenav.title.' + keystonePage.slug) }}
      </div>
      <div
        *ngIf="isTerms"
        [class]="
          'flex w-full relative justify-between gap-4' +
          (isTermsModal ? ' pt-2 flex-col-reverse sm:flex-row ' : ' py-4 flex-row ')
        ">
        <div
          (click)="openVersions()"
          [class]="
            'cursor-pointer flex-center w-full duration-100 relative dark:bg-mainBtn bg-smallContainerBgLight dark:text-mainText text-mainTextLight ' +
            (isOpenVersions ? '' : 'md:delay-500 ') +
            (isTermsModal ? ' relative h-[3.75rem] sm:h-[4.5rem] rounded-2xl ' : ' rounded-lg ')
          "
          [id]="isOpenVersions ? 'openVersions' : 'closedVersions'">
          <div class="flex items-center justify-between w-full">
            <div class="flex-1 flex justify-center">
              <span
                [class]="'text-base font-normal leading-5 text-center dark:text-mainText text-mainTextLight ' 
                + (!isTermsModal ?  'text-sm font-semibold' : 'text-base font-normal')">
                {{ 'Versions' }}
              </span>
            </div>
            <div class="pr-5">
              <img
                [class]="(isOpenVersions ? 'rotate-180' : '') + ' transition-all duration-75 ease-in-out'"
                src="https://cdn.chipychapa.com//welle/General_site/Terms/open_versions.svg"
                alt="open" />
            </div>
          </div>
          <div
            [class]="
              (isOpenVersions ? 'block' : 'hidden') +
              (isTermsModal ? ' top-12 ' : ' top-full ') +
              ' absolute max-h-screen pb-2 pt-4 rounded-lg overflow-y-auto mL:px-2 z-10 w-full gap-2 flex transition-all ease duration-500 dark:bg-mainBtn bg-smallContainerBgLight dark:text-mainText text-mainTextLight flex-col no-scrollbar'
            ">
            <div class="border-t-2 pt-2 dark:border-borderViolet border-mainBgLighter">
              <label
                *ngFor="let item of termsVersions"
                class="py-3 px-2 mL:px-2 flex justify-between items-center cursor-pointer rounded-lg form-custom-check hover:dark:bg-mainHover hover:bg-sidebarLight transition-colors">
                <div [class]="'items-center pr-2 md:pr-5' + (isTermsModal ? ' lg:w-full ' : ' w-full ')">
                  <div
                    [class]="
                      'text-center items-center text-sm leading-5 md:text-left dark:text-mainText text-mainTextLight flex flex-col md:flex-row md:justify-between' +
                      (isTermsModal ? ' font-normal ' : ' font-semibold ')
                    ">
                    <span *ngIf="isTermsModal" class="text-center items-center md:block md:mr-2">
                      {{ 'Version ' + item.title }}
                    </span>
                    <span *ngIf="!isTermsModal" class="text-center items-center md:block md:mr-2">
                      {{ isMobile ? item.title.replace('ersion', '') : item.title }}
                    </span>
                    <span class="md:block">
                      {{ item.Name }}
                    </span>
                  </div>
                </div>
                <input
                  (click)="selectTerms(item)"
                  type="radio"
                  [checked]="item?.title === selected"
                  class="form-custom-check-input"
                  [id]="item?.title?.toLowerCase().replaceAll('.', '_')" />
              </label>
            </div>
          </div>
        </div>
        <a
          [href]="keystonePage?.opengraphKeywords"
          target="_blank"
          [class]="
            'cursor-pointer w-full p-3 gap-4 bg-smallContainerBgLight dark:text-secondaryTextMain text-mainTextLight text-base font-normal ' +
            (isTermsModal
              ? ' flex items-center pl-7 rounded-2xl h-[3.75rem] sm:h-[4.5rem] '
              : ' flex-center rounded-xl ')
          "
          id="pdfDownload">
          <img src="https://cdn.chipychapa.com//welle/General_site/Terms/pdf.svg" alt="download" />
          <span *ngIf="!isTermsModal">{{ t('terms.pdf' + (isMobile ? '' : '.long')) }}</span>
          <span *ngIf="isTermsModal">{{ t('terms.pdf.long') }}</span>
        </a>
      </div>

      <div
        *ngIf="keystonePage?.description"
        [class]="
          ' child ' +
          (keystonePage.slug === 'about' ? '  ' : ' overflow-auto ') +
          (isTermsModal ? ' md:px-6 px-4 ' : ' md:p-6 sm:p-4 p-4 pb-2 md:pb-16 ')
        "
        #childDiv
        [innerHtml]="keystonePage?.description"></div>
    </div>
  </div>
</ng-container>
