import { createFeatureSelector } from '@ngrx/store';
import { State, configFeatureKey } from '../reducers/helpers.reducer';
import { getCountriesKey } from '../reducers/getCountries.reducer';
import { datesTransactionsConfigFeatureKey } from '../reducers/dates.reducer';
import { datesStoreHistoryConfigFeatureKey } from '../reducers/datesStoreHistory.reducer';

export const selectHelpers = createFeatureSelector<State>(configFeatureKey);
export const selectGetCounries = createFeatureSelector(getCountriesKey);
export const selectDatesTransactions = createFeatureSelector(datesTransactionsConfigFeatureKey);
export const selectDatesStoreHistory = createFeatureSelector(datesStoreHistoryConfigFeatureKey);
