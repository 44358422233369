import { Component, OnDestroy } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { selectHelpers } from 'src/app/core/store/helpers/selector/helpers.selectors';
import { ModalsActions } from 'src/app/core/store/modals/actions/modals.actions';
import { selectUser } from 'src/app/core/store/user/selectors/user.selector';

@Component({
  selector: 'app-loyalty-video-modal',
  standalone: true,
  imports: [TranslocoModule],
  templateUrl: './loyalty-video-modal.component.html',
  styleUrl: './loyalty-video-modal.component.scss',
})
export class LoyaltyVideoModalComponent implements OnDestroy {
  user$: Observable<any> = this.store.select(selectUser);
  helpers$: Observable<any> = this.store.select(selectHelpers);
  unsubus$: Subject<boolean> = new Subject();

  isAuth: boolean = false;
  isMobile: boolean = true;

  constructor(private store: Store) {
    this.helpers$.pipe(takeUntil(this.unsubus$)).subscribe((res) => {
      if (res.isLoaded) {
        this.isMobile = res.isMobile;
      }
    });
    this.user$.pipe(takeUntil(this.unsubus$)).subscribe((res: any) => {
      this.isAuth = res.isAuth;
    });
  }

  closeModal() {
    this.store.dispatch(ModalsActions['[modals]CloseModalsSuccess']());
  }

  ngOnDestroy(): void {
    this.unsubus$.next(true);
    this.unsubus$.complete();
  }
}
