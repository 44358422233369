import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { AuthUser, ResetPassword } from '../models/user.model';
import { UserBalances } from 'src/app/core/interfaces/interfaces';

// export const UserResetPass = createAction('[Reset Password Component] User Reset Password', props<{ email: any }>());

export const GetUser = createActionGroup({
  source: 'GetUser',
  events: {
    '[GetUser] GetUser': emptyProps(),
  },
});

export const SetUser = createActionGroup({
  source: 'SetUser',
  events: {
    '[SetUser] Set User Info Success': props<{
      data: any;
    }>(),
    '[SetUser] Set User Info Failure': emptyProps(),
    '[SetUser] Set User Profile Success': props<{
      data: any;
    }>(),
    '[SetUser] Set User Profile Failure': emptyProps(),
  },
});

export const SetUserBalances = createActionGroup({
  source: 'SetUserBalances',
  events: {
    '[SetUserBalances] Set User Balance Success': props<{
      data: UserBalances;
    }>(),
    '[SetUserBalances] Set User Balance Failure': emptyProps(),
  },
});

export const authUser = createActionGroup({
  source: 'authUser',
  events: {
    '[authUser] authUser': props<AuthUser>(),
    '[authUser] authUser Success': props<{
      data: any;
    }>(),
    '[authUser] authUser Failure': props<{
      data: any;
    }>(),
    '[authUser] authUser Clear': emptyProps(),
    '[authUser] Clear Errors': emptyProps(),
  },
});
export const UserResetPass = createActionGroup({
  source: 'UserResetPass',
  events: {
    '[UserResetPass] userResetPass': props<{
      data: ResetPassword;
    }>(),
    '[UserResetPass] userResetPass Success': props<{
      data: any;
    }>(),
    '[UserResetPass] userResetPass Failure': emptyProps(),
  },
});

export const restoreUserDefault = createActionGroup({
  source: 'UserResetPass',
  events: {
    '[restoreUserDefault] restoreUserDefault': emptyProps(),
  },
});
