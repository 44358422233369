<app-modal
  *ngIf="currentModal"
  [isLandingTerms]="isLandingTerms"
  [isUnClosable]="isUnClosable"
  [isGameCard]="isGameCard"
  [closeBar]="closeBar"
  [loyaltyModals]="loyaltyModals"
  [isFilter]="isFilter"
  [isSmall]="isSmall"
  [background]="background"
  [title]="title"
  [modalWidth]="modalWidth"
  [appBarTitleToLeft]="appBarTitleToLeft"
  [isTermsRenew]="isTermsRenew">
  <ng-container class="w-full" *ngComponentOutlet="currentModal; inputs: inputs"></ng-container>
</app-modal>
