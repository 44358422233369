import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class QuestService {
  constructor(private http: HttpClient) {}

  getAllQuests() {
    return this.http.get(`/api/v2/loyalty/quest/get-quest`);
  }
  getQuestProgress() {
    return this.http.get(`/api/v2/loyalty/quest/get-user-progress`);
  }
  collectAward(id: string) {
    return this.http.post(`/api/v2/loyalty/quest/collect-award`, { questId: id });
  }
  // questUnsubscribe() {
  //   return this.http.get(`/api/v2/loyalty/quest/unsubscribe-to-quest`);
  // }
  // questSubscribe() {
  //   return this.http.get(`/api/v2/loyalty/quest/subscribe-to-quest`);
  // }
}
