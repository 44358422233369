<ng-container *transloco="let t">
  <div class="flex flex-col w-full bg-inputBackground">
    <div class="flex justify-between w-full p-5 gap-4 flex-col pb-32 md:pb-5 md:flex-row">
      <!-- from -->
      <div class="flex flex-col w-full gap-2">
        <span class="text-base font-semibold leading-5 text-left dark:text-mainText text-mainTextLight w-full ps-2">
          {{ t('sidenav.cashier.transactions.from') }}
        </span>
        <div class="flex flex-col w-full flex-grow">
          <div class="flex justify-between items-center dark:bg-mainHover bg-mainBgLight p-2 px-3 w-full rounded-t-xl">
            <div class="flex justify-start items-center gap-2">
              <img
                src="https://cdn.chipychapa.com//welle/General_site/Sidebar/Cashier/Transactions/date.svg"
                class="w-4 h-4"
                alt="calendar" />
              <span class="text-sm font-semibold leading-5 text-left dark:text-mainText text-white">
                {{
                  initialDate === ''
                    ? t('sidenav.cashier.transactions.starting_date')
                    : t('month.transactions_month-' + initialDate.slice(0, 3)) + initialDate.slice(3)
                }}
              </span>
            </div>
            <div>
              <img
                alt="cross"
                class="cursor-pointer"
                *ngIf="initialDate !== ''"
                (click)="clearDateAll()"
                src="https://cdn.chipychapa.com//welle/General_site/Sidebar/Cashier/Transactions/clear_date.svg"
                id="cleaFromDate" />
            </div>
          </div>
          <div class="flex flex-col p-4 pt-0 dark:bg-biggerContainerBg bg-smallContainerBgLight rounded-b-xl flex-grow">
            <div class="flex justify-between px-1 py-5">
              <div class="w-8">
                <div
                  *ngIf="activeMonths.indexOf(activeMonths[selectedMonthLeftPage]) !== 0"
                  class="cursor-pointer"
                  (click)="prevPageLeft()">
                  <img
                    alt="arrow left"
                    src="https://cdn.chipychapa.com//welle/General_site/Sidebar/Cashier/Transactions/previous_month.svg"
                    id="fromDatePreviousMonth" />
                </div>
              </div>
              <div class="dark:text-mainText text-mainTextLight text-sm font-semibold leading-5 text-center">
                {{ t('month.transactions_month-' + activeMonths[selectedMonthLeftPage]?.month) }}
                {{ activeMonths[selectedMonthLeftPage]?.year }}
              </div>
              <div class="w-8">
                <div
                  *ngIf="activeMonths.indexOf(activeMonths[selectedMonthLeftPage]) !== activeMonths.length - 1"
                  class="cursor-pointer"
                  (click)="nextPageLeft()">
                  <img
                    alt="arrow right"
                    src="https://cdn.chipychapa.com//welle/General_site/Sidebar/Cashier/Transactions/next_month.svg"
                    id="fromDateNextMonth" />
                </div>
              </div>
            </div>
            <div class="grid grid-cols-7 pb-5">
              <span
                *ngFor="let item of daysOfWeek"
                class="flex-center text-xs font-semibold leading-4 text-center dark:text-secondaryTextMain text-secondaryTextLight">
                {{ t('day.transactions-' + item) }}
              </span>
            </div>
            <div class="grid grid-cols-7">
              <app-datepickers-button
                (click)="item.stopSelect ? '' : selectInitialDate(item, activeMonths[selectedMonthLeftPage])"
                *ngFor="let item of activeMonths[selectedMonthLeftPage]?.days"
                [name]="item.day"
                [stopSelect]="item.stopSelect"
                [variant]="selectedInitialDate.day ? item.variant : ''"></app-datepickers-button>
            </div>
          </div>
        </div>
      </div>
      <!-- To -->
      <div class="flex flex-col w-full gap-2">
        <span class="text-base font-semibold leading-5 text-left dark:text-mainText text-mainTextLight w-full ps-2">
          {{ t('sidenav.cashier.transactions.to') }}
        </span>
        <div class="flex flex-col w-full flex-grow">
          <div class="flex justify-between items-center dark:bg-mainHover bg-mainBgLight p-2 px-3 w-full rounded-t-xl">
            <div class="flex justify-start items-center gap-2">
              <img
                alt="calendar"
                src="https://cdn.chipychapa.com//welle/General_site/Sidebar/Cashier/Transactions/date.svg"
                class="w-4 h-4" />
              <span class="text-sm font-semibold leading-5 text-left dark:text-mainText text-white">
                {{
                  finalDate === ''
                    ? t('sidenav.cashier.transactions.starting_date')
                    : t('month.transactions_month-' + finalDate.slice(0, 3)) + finalDate.slice(3)
                }}
              </span>
            </div>
            <div>
              <img
                alt="cross"
                class="cursor-pointer"
                *ngIf="finalDate !== ''"
                (click)="clearDateRight()"
                src="https://cdn.chipychapa.com//welle/General_site/Sidebar/Cashier/Transactions/clear_date.svg"
                id="clearToDate" />
            </div>
          </div>
          <div class="flex flex-col p-4 pt-0 dark:bg-biggerContainerBg bg-smallContainerBgLight rounded-b-xl flex-grow">
            <div class="flex justify-between px-1 py-5">
              <div class="w-8">
                <div
                  *ngIf="activeMonths.indexOf(activeMonths[selectedMonthRightPage]) !== 0"
                  class="cursor-pointer"
                  (click)="prevPageRight()">
                  <img
                    alt="arrow left"
                    src="https://cdn.chipychapa.com//welle/General_site/Sidebar/Cashier/Transactions/previous_month.svg"
                    id="toDatePreviousMonth" />
                </div>
              </div>
              <div class="dark:text-mainText text-mainTextLight text-sm font-semibold leading-5 text-center">
                {{ t('month.transactions_month-' + activeMonths[selectedMonthRightPage]?.month) }}
                {{ activeMonths[selectedMonthRightPage]?.year }}
              </div>
              <div class="w-8">
                <div
                  *ngIf="activeMonths.indexOf(activeMonths[selectedMonthRightPage]) !== activeMonths.length - 1"
                  class="cursor-pointer"
                  (click)="nextPageRight()">
                  <img
                    alt="arrow right"
                    src="https://cdn.chipychapa.com//welle/General_site/Sidebar/Cashier/Transactions/next_month.svg"
                    id="toDateNextMonth" />
                </div>
              </div>
            </div>
            <div class="grid grid-cols-7 pb-5">
              <span
                *ngFor="let item of daysOfWeek"
                class="flex-center text-xs font-semibold leading-4 text-center dark:text-secondaryTextMain text-mainTextLight">
                {{ t('day.transactions-' + item) }}
              </span>
            </div>
            <div class="grid grid-cols-7">
              <app-datepickers-button
                (click)="item.stopSelect ? '' : selectFinalDate(item, activeMonths[selectedMonthRightPage])"
                *ngFor="let item of activeMonths[selectedMonthRightPage]?.days"
                [name]="item.day"
                [stopSelect]="item.stopSelect"
                [variant]="selectedFinalDate.day ? item.variant : ''"></app-datepickers-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="flex justify-end items-center pb-4 absolute md:relative bottom-0 md:bottom-[unset] flex-col-reverse md:flex-row w-full md:w-[unset] gap-2 md:gap-4 px-5">
      <button
        (click)="closeModal()"
        id="cancelSetDateBtn"
        class="p-[0.875rem] w-full mmd:w-[4.875rem] bg-smallContainerBgLight text-mainTextLight text-center hover:bg-mainBgLight hover:text-mainText text-sm font-semibold rounded-xl">
        {{ t('buttons.Cancel') }}
      </button>
      <button
        (click)="!(selectedInitialDate.selected || selectedFinalDate.selected) ? '' : sendDates()"
        id="cancelSetDateBtn"
        [class]="
          'p-[0.875rem] w-full mmd:w-[8.625rem] text-mainTextLight text-center hover:bg-mainHoverLight hover:text-mainText font-semibold text-sm rounded-xl' +
          (!selectedInitialDate.selected || !selectedFinalDate.selected
            ? ' pointer-events-none bg-lightGrayBorder '
            : ' bg-mainBtnLight ')
        ">
        {{ t('buttons.Set dates') }}
      </button>
    </div>
  </div>
</ng-container>
