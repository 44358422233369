import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const GetBonuses = createActionGroup({
  source: 'GetBonuses',
  events: {
    '[GetBonuses] Get Bonuses': emptyProps(),
  },
});

export const SetBonuses = createActionGroup({
  source: 'SetBonuses',
  events: {
    '[SetBonuses] Set Bonuses Success': props<{
      data: [];
    }>(),
    '[SetBonuses] Set Bonuses Failure': emptyProps(),
  },
});
