<ng-container *transloco="let t">
  <div
    id="app-bar-close"
    [class]="
      'flex w-full  md:rounded-t-xl text-base' +
      (isSideNav ? ' pl-4 md:pl-0 ' : ' px-2 ') +
      (title === 'terms' || title === 'privacy' ? ' py-4 ' : '') +
      (loyaltyModals || isSmall ? ' py-3 ' : ' py-6 ') +
      (backgroundColor === '' ? ' bg-skyBtnHoverLight' : backgroundColor)
    "
    [style]="back ? 'justify-content:space-between;' : 'justify-content:end'">
    <div class="grid grid-cols-[20%,1fr,20%] w-full items-center px-3">
      <div *ngIf="back || sideNavStepBack" class="flex justify-start">
        <div
          class="px-2 py-1 gap-4 flex cursor-pointer rounded-lg hover:dark:bg-mainHover hover:bg-skyBtnHoverLight items-center"
          (click)="routerBack()"
          [id]="title + 'GoBackModal'">
          <img
            class="h-6 w-6"
            width="17.58"
            height="11.17"
            src="https://cdn.chipychapa.com//welle/General_site/Sidebar/Cashier/Deposit/back.svg"
            alt="arrow left"
            id="back" />
          <p *ngIf="isSideNav && !isMobile" class="font-semibold text-sm">{{ t('back') }}</p>
        </div>
      </div>
      <div
        [class]="
          'dark:text-mainText text-mainTextLight font-bold leading-7 text-xl flex ' +
          (appBarTitleToLeft ? '  col-span-2 justify-start' : 'col-start-2 flex-center text-center')
        ">
        <span
          *ngIf="title && !isMobile"
          [class]="
            title === 'welcome' ? ' md:text-base md:max-w-[18.75rem] xl:max-w-[28.125rem] l2xl:max-w-[37.5rem] ' : ''
          ">
          {{ t('sidenav.title.' + title) }}
        </span>
        <span *ngIf="title === 'providers' && isMobile" class="text-wrap text-center">
          {{ t('buttons.Game Providers') }}
        </span>
        <span
          *ngIf="title !== 'providers' && isMobile && title"
          [class]="
            'text-wrap text-center' +
            (title === 'welcome' ? ' hidden mL:inline text-sm max-w-[13.125rem] mmd:max-w-[22rem] ' : '')
          ">
          {{ t('sidenav.title.' + title) }}
        </span>
      </div>
      <div
        *ngIf="!isTermsRenew"
        [class]="'flex justify-end  ' + (appBarTitleToLeft ? 'col-start-3' : '')"
        (click)="close()">
        <div
          class="gap-3 flex items-center justify-center py-3 px-4 cursor-pointer rounded-lg hover:dark:bg-mainHover hover:bg-skyBtnHoverLight"
          [id]="title + 'CloseModal'">
          <span *ngIf="isMobile && unableCloseTitle" class="dark:text-mainText text-mainTextLight">
            {{ t('close') }}
          </span>
          <p *ngIf="isSideNav && !isMobile" class="font-semibold text-sm">{{ t('close') }}</p>
          <img
            src="https://cdn.chipychapa.com//welle/General_site/Autharization/close_page.svg"
            width="16"
            height="16"
            alt="cross"
            id="close" />
        </div>
      </div>
    </div>
  </div>
</ng-container>
