import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subject } from 'rxjs';
import { currencyInterface } from 'src/app/core/interfaces/interfaces';
import { TranslocoModule } from '@ngneat/transloco';
import { ModalsDataTransferService } from 'src/app/core/services/modalService/modals-data-transfer.service';
import { LocalStorageService } from 'src/app/core/services/localStorage/local-storage.service';

@Component({
  selector: 'app-select-box-currency-modal',
  standalone: true,
  imports: [CommonModule, TranslocoModule],
  templateUrl: './select-box-currency-modal.component.html',
  styleUrl: './select-box-currency-modal.component.scss',
})
export class SelectBoxCurrencyModalComponent implements OnInit {
  unsubus$: Subject<boolean> = new Subject();
  currencies: Array<currencyInterface> = [];
  currencyCountryMap: {
    [key: string]: string;
  } = {
    EUR: 'eun',
    RUB: 'rus',
    USD: 'usa',
    NOK: 'nor',
    CAD: 'can',
    TRY: 'tur',
    BRL: 'bra',
    MXN: 'mex',
    AUD: 'aus',
  };
  selectedCurrency: string = 'EUR';
  localCurrency: string = '';

  constructor(
    private modalDatatransferDataService: ModalsDataTransferService,
    private storage: LocalStorageService
  ) {
    this.localCurrency = this.modalDatatransferDataService.getLocalCurrency();
  }
  ngOnInit() {
    const sortCurrencies = this.storage.getSessionStorage('sortCurrencies');
    if (sortCurrencies) {
      this.currencies = JSON.parse(sortCurrencies);
      this.currencies.forEach((el: currencyInterface) => {
        if (el.value === this.localCurrency) {
          this.selectedCurrency = el.value;
          return;
        }
      });
    }
  }

  selectCurrency(item: currencyInterface) {
    this.modalDatatransferDataService.setOnSelectCurrency(item);
  }
}
