import { Component } from '@angular/core';
import { MainButtonComponent } from '../../buttons/main-button/main-button.component';
import { TranslocoModule } from '@ngneat/transloco';
import { LadeskChatService } from 'src/app/core/services/ladeskChat/ladesk-chat.service';

@Component({
  selector: 'app-bonus-restricted-modal',
  standalone: true,
  imports: [MainButtonComponent, TranslocoModule],
  templateUrl: './bonus-restricted-modal.component.html',
  styleUrl: './bonus-restricted-modal.component.scss',
})
export class BonusRestrictedModalComponent {
  constructor(private liveChat: LadeskChatService) {}
  openLiveChat() {
    this.liveChat.openLiveAgent({ type: 'liveChat' });
  }
}
