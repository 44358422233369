import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { possibleLang, selectedDepositData, stepperData } from 'src/app/core/interfaces/interfaces';

export const HelpersActions = createActionGroup({
  source: 'helper',
  events: {
    '[helpers] Helpers': emptyProps(),
    '[helpers] Helpers Success': props<{
      data: unknown;
    }>(),
    '[helpers] Set browser name': props<{
      data: string;
    }>(),
    '[helpers] Set isMobile status': props<{
      data: boolean;
    }>(),
    '[helpers] Set screen width': props<{
      data: number;
    }>(),
    '[helpers] Set Current Country': props<{
      data: string;
    }>(),
    '[helpers] Set Current Language': props<{
      data: string;
    }>(),
    '[helpers] Set Current Deposit Amount': props<{
      data: selectedDepositData;
    }>(),
    '[helpers] Set Possible Languages': props<{
      data: Array<possibleLang>;
    }>(),
    '[helpers] Set Current Info Page': props<{
      data: string;
    }>(),
    '[helpers] Get browser prefered languages': props<{
      data: Array<string>;
    }>(),
    '[helpers] Helpers Failure': emptyProps(),
    '[helpers] Set Current Step': props<{
      data: stepperData;
    }>(),
  },
});

export const Dates = createActionGroup({
  source: 'Date',
  events: {
    '[dates] Set dates transactions': props<{
      initialDate: any;
      finalDate: any;
      selectedMonthLeftPage: any;
      selectedMonthRightPage: any;
    }>(),
    '[dates] Set dates store history': props<{
      initialDate: any;
      finalDate: any;
      selectedMonthLeftPage: any;
      selectedMonthRightPage: any;
    }>(),
  },
});
