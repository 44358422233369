import { Injectable } from '@angular/core';
import { io } from 'socket.io-client';
import { lastWithdrawals } from '../../interfaces/interfaces';
import { Store } from '@ngrx/store';
import { WithdrawTransactionActions } from '../../store/withdraw-transactions/actions/withdraw-transactions.actions';

@Injectable({
  providedIn: 'root',
})
export class PayoutService {
  decoyWithdrawal: lastWithdrawals = {
    email: 'b******pi@gmail.com',
    amount: '1450$',
    time: {
      title: 'time',
      value: {
        d: 0,
        h: 0,
        m: 6,
        s: 54,
      },
    },
    method: {
      title: 'method',
      value: 'mastercard',
      img: 'https://cdn.cuatroespada.net//images/royspins/payments/master.svg',
    },
    status: {
      title: 'status',
      value: 'Paid',
    },
  };

  constructor(private store: Store) {}

  startListeningSocketEvents() {
    const socket = io('https://aggregator.wazbee.casino/withdraw-transaction', {
      reconnection: false,
    });
    socket.on('connect', () => {});
    socket.on('connect_error', () => {
      this.dispatchStorage(this.decoyWithdrawals(4));
    });
    socket.on('withdraw-transactions', (data) => {
      this.dispatchStorage(this.parseWithdraws(JSON.parse(data)));
    });
  }

  dispatchStorage(data: any) {
    this.store.dispatch(
      WithdrawTransactionActions['[withdraw-transactions]GetWithdrawTransactionSuccess']({
        data: data,
      })
    );
  }

  transformTime(time: number) {
    time = time / 1000;
    const timeTransformed = {
      d: Math.floor(time / (3600 * 24)),
      h: Math.floor((time % (3600 * 24)) / 3600),
      m: Math.floor((time % 3600) / 60),
      s: Math.floor(time % 60),
    };
    return timeTransformed;
  }
  handleWithdraws(data: any, amountOfWithdraws: number) {
    let lastPayouts = this.parseWithdraws(data.data.splice(0, amountOfWithdraws)); // data.data change to data prop
    if (lastPayouts.length == 0) {
      lastPayouts = this.decoyWithdrawals(amountOfWithdraws);
    }
    return lastPayouts;
  }
  parseWithdraws(lastWithdrawals: any) {
    return lastWithdrawals.map((elem: any) => {
      return {
        email: elem.userEmail,
        amount: elem.amount,
        currency: elem.currency,
        group: elem.group,
        time: {
          title: 'time',
          value: this.transformTime(elem.timeToComplete),
        },
        method: {
          title: 'method',
          value: elem.method,
          img: elem?.img,
        },
        status: {
          title: 'status',
          value: elem.status,
        },
      };
    });
  }
  decoyWithdrawals(times: number) {
    const result: Array<lastWithdrawals> = [];
    for (let i = 0; i < times; i++) {
      result.push(this.decoyWithdrawal);
    }
    return result;
  }
}
