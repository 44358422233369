import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { PayoutService } from 'src/app/core/services/payout/payout.service';

@Injectable()
export class WithdrawTransactionsEffects {
  constructor(
    private actions$: Actions,
    private payoutService: PayoutService
  ) {}

  // $startInitPayoutService = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(BrowserReloadsActions['[BrowserActions]BrowserReloadss']),
  //     delay(5000),
  //     mergeMap(async () => {
  //       this.payoutService.startListeningSocketEvents();
  //       return WithdrawTransactionActions['[withdraw-transactions]GetWithdrawTransactionInitialization']();
  //     })
  //   );
  // });
}
