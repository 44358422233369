import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { LocalStorageService } from '../services/localStorage/local-storage.service';

@Injectable()
export class UrlInterceptor implements HttpInterceptor {
  private urlPrefix = '/api/v1';

  excludeRules(url: string) {
    let xpateHelperUrl = '';

    if (
      url.indexOf('https://helper.coduluz.com') > -1 ||
      url.indexOf('https://redoby.com/charge') > -1 ||
      url.indexOf('https://verification.coduluz.com') > -1
    ) {
      xpateHelperUrl = url;
    }
    const listOfExcludeUrlsPatterns = [
      'https://cashforo.com/withdraw-flat/create',
      'https://cashforo.com/main',
      'ws://176.57.189.135:81/',
      'https://welle-api.coduluz.com',
      'https://test.welle.games/graphql',
      '/content/content-restapi.php',
      'https://api.bigdatacloud.net/data/client-ip',
      'https://welle.games/',
      'https://prod.welle.games/graphql',
      '/assets',
      'https://welle.homes/api',
      // test keystone (poka podstavlju prod dlaj 1):
      'https://welle.homes/api',
      'https://welle.homes/admin/signin',
      // test keystone (poka podstavlju prod dlja 1):
      'https://welle.homes/admin/signin',
      'https://welle.homes/admin/api',
      'http://test.welle.games/',
      'https://cashforo.com/zcolibrix-adapter/charge',
      'https://tagsync.coduluz.com/',
      '/api/v2/',
      '/api/v3/',
      '/auth/',
      'https://test.welle.homes/',
      'https://sentry.coduluz.com',
      'https://sentry.coduluz.com/2/',
      xpateHelperUrl,
    ];

    return !!listOfExcludeUrlsPatterns.find((value) => {
      if (url.indexOf(value) > -1) {
        return value;
      }
      return;
    });
  }

  constructor(private storage: LocalStorageService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let url = this.urlPrefix + request.url;
    if (this.excludeRules(url)) {
      url = request.url;
    }
    const newReq = {
      url,
    };
    if ((url.includes('/api/v2') || url.includes('/api/v3')) && !url.includes('/auth/access-token')) {
      const accessToken = this.storage.getSessionStorage('loyalty_access_token');
      const refreshToken = this.storage.getSessionStorage('loyalty_refresh_token');

      if (accessToken && refreshToken) {
        request = request.clone({
          setHeaders: {
            Authorization: accessToken,
            REFRESH_TOKEN: refreshToken,
          },
        });
      }
    }
    return next.handle(request.clone(newReq)).pipe(
      map((event: HttpEvent<any>) => {
        let result = event;
        if (event instanceof HttpResponse && event.body) {
          const newEvent = {
            body: event.body.data,
          };
          result = event.clone(newEvent);
        }
        return result;
      })
    );
  }
}
