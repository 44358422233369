import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const ConfigActions = createActionGroup({
  source: 'Config',
  events: {
    '[config] Configs': emptyProps(),
    '[config] Configs Success': props<{
      data: unknown;
    }>(),
    '[config] Configs Failure': props<{
      data: unknown;
    }>(),
  },
});
