<div
  [class]="
    'h-full w-full md:p-8 p-5 overflow-hidden left-0 top-0 z-[990] fixed  ' +
    (loyaltyModals ? 'justify-center flex ' : 'flex-center')
  ">
  <div
    [@modalInit]
    *ngIf="isActive"
    [class]="
      ' dark:bg-containerBg bg-smallContainerBgLight flex flex-col z-[100] justify-center relative overflow-hidden h-min max-h-full rounded-2xl w-full ' +
      (modalWidth ? modalWidth : ' max-w-[40.125rem] ')
    ">
    <app-bar-close
      [isSmall]="isSmall"
      *ngIf="closeBar"
      (closeEmitter)="close()"
      [unableCloseTitle]="false"
      [title]="isLandingTerms ? 'terms' : title"
      [backgroundColor]="isMobile ? 'mainBtn' : ''"
      [backgroundColor]="isLandingTerms ? 'mainBg' : background ? background : ''"
      [loyaltyModals]="loyaltyModals"
      [appBarTitleToLeft]="appBarTitleToLeft"
      [isTermsRenew]="isTermsRenew"></app-bar-close>
    <div
      class="w-full h-full overflow-y-auto flex flex-col gap-8 no-scrollbar"
      [class]="(isLandingTerms ? ' bg-smallContainerBgLight ' : '') + (isUnClosable && !isTermsRenew ? ' p-3 ' : '')">
      <ng-content></ng-content>
    </div>
  </div>
  <div
    [@backDropInit]
    *ngIf="isActive"
    (click)="close()"
    class="w-full backdrop-blur h-full z-[50] fixed left-0 top-0 bg-tooltipLight opacity-70 no-scrollbar"></div>
</div>
