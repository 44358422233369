import { createReducer, on } from '@ngrx/store';
import { ToasterActions } from '../actions/toaster.actions';

export const configFeatureKey = 'toaster';

export interface State {
  title?: string;
  message?: string;
  type: 'success' | 'error' | 'warning' | 'info' | '';
  img?: string;
  url?: string;
  isLoaded: boolean;
}

export const initialState: State = {
  title: '',
  message: '',
  type: '',
  img: '',
  url: '',
  isLoaded: false,
};

export const reducer = createReducer(
  initialState,
  on(ToasterActions['[toaster]SetToasterMessgageSuccess'], (state, action) => {
    return {
      ...state,
      title: action.data?.title,
      message: action.data?.message,
      type: action.data?.type,
      img: action.data?.img,
      place: action.data?.place,
      url: action.data?.url,
      isLoaded: true,
    };
  }),
  on(ToasterActions['[toaster]RemoveToasterMessageSuccess'], (state: any) => {
    return {
      ...state,
      title: '',
      message: '',
      type: '',
      place: '',
      img: '',
      url: '',
      isLoaded: false,
    };
  })
);
