import { createReducer, on } from '@ngrx/store';
import { FilterActions } from '../actions/filter.actions';

export const configFeatureKey = 'Filters';

export interface State {
  providers: Array<string>;
  category: string;
  names: Array<string> | undefined;
  search: string | undefined;
  clear: boolean;
  isLoaded: boolean;
}

export const initialState: State = {
  providers: [],
  search: '',
  category: '',
  names: [],
  clear: false,
  isLoaded: false,
};

export const reducer = createReducer(
  initialState,
  on(FilterActions['[SetFilter]SetFilterSearchSuccess'], (state, action) => {
    return {
      ...state,
      search: action.data,
      clear: false,
      isLoaded: true,
    };
  }),
  on(FilterActions['[SetFilter]SetFilterProvidersSuccess'], (state, action) => {
    return {
      ...state,
      providers: action.data,
      names: action.names,
      clear: false,
      isLoaded: true,
    };
  }),
  on(FilterActions['[SetFilter]SetFilterCategorySuccess'], (state, action) => {
    return {
      ...state,
      category: action.data,
      clear: false,
      isLoaded: true,
    };
  }),
  on(FilterActions['[ClearFilter]ResetFiltersSuccess'], (state) => {
    return {
      ...state,
      category: '',
      search: '',
      providers: [],
      names: [],
      clear: true,
      isLoaded: true,
    };
  })
);
