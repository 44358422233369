import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const FilterActions = createActionGroup({
  source: 'Filters',
  events: {
    '[Set Filter] Set Filter Search Success': props<{
      data: string;
    }>(),
    '[Set Filter] Set Filter Providers Success': props<{
      data: Array<string>;
      names?: string[];
    }>(),
    '[Set Filter] Set Filter Category Success': props<{
      data: string;
    }>(),

    '[Clear Filter] Reset Filters Success': emptyProps(),
    '[Get Filter app] Get Filter': emptyProps(),
  },
});
