import { createActionGroup, props } from '@ngrx/store';

export const PaymentMethodsfilterActions = createActionGroup({
  source: 'Filters',
  events: {
    '[filters]SetCurrentFilterMethod': props<{
      slug: string;
      isCryptoBonus?: boolean;
    }>(),
  },
});
