import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { selectHelpers } from 'src/app/core/store/helpers/selector/helpers.selectors';
import { BarCloseComponent } from '../buttons/bar-close/bar-close.component';
import { NgIf } from '@angular/common';
import { animate, keyframes, style, transition, trigger } from '@angular/animations';
import { ModalsActions } from 'src/app/core/store/modals/actions/modals.actions';
import { HelpersService } from 'src/app/core/services/helpers/helpers.service';

const modalInOut = trigger('modalInit', [
  transition(':leave', [
    animate(
      '150ms',
      keyframes([
        style({
          opacity: 1,
          top: '0%',
        }),
        style({
          opacity: 0,
          top: '20%',
        }),
      ])
    ),
  ]),
  transition(':enter', [
    animate(
      `150ms`,
      keyframes([
        style({
          opacity: 0,
          top: '20%',
        }),
        style({
          opacity: 1,
          top: '0%',
        }),
      ])
    ),
  ]),
]);

const backDropInOut = trigger('backDropInit', [
  transition(':leave', [
    animate(
      '150ms',
      keyframes([
        style({
          opacity: 1,
        }),
        style({
          opacity: 0,
        }),
      ])
    ),
  ]),
  transition(':enter', [
    animate(
      `300ms`,
      keyframes([
        style({
          opacity: 0,
        }),
        style({
          opacity: 0.7,
        }),
      ])
    ),
  ]),
]);

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  standalone: true,
  animations: [modalInOut, backDropInOut],
  imports: [NgIf, BarCloseComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent implements OnDestroy, OnInit {
  helper$: Observable<any> = this.store.select(selectHelpers);
  unsubus$: Subject<boolean> = new Subject();

  @Input()
  isMobile: boolean = true;
  @Input()
  isTermsRenew: boolean = false;
  @Output()
  viewStatus: EventEmitter<any> = new EventEmitter<any>();
  @Input()
  isLandingTerms: boolean = false;
  @Input()
  title: string = '';
  @Input()
  closeBar: boolean = true;
  @Input()
  isSmall: boolean = false;
  @Input() background: string = '';
  @Input()
  isGameCard: boolean = false;
  @Input()
  isUnClosable: boolean = false;
  @Input()
  isFilter: boolean = false;
  @Input() loyaltyModals: boolean = false;
  @Input() modalWidth: string = '';
  @Input() appBarTitleToLeft: boolean = false;
  stopScroll: string = '';

  isActive: boolean = true;
  constructor(
    private store: Store,
    private helper: HelpersService
  ) {
    if (this.helper.detectBrowser() === 'Safari') {
      this.stopScroll = 'stop-scroll-safari';
    } else {
      this.stopScroll = 'stop-scroll';
    }
  }
  ngOnInit() {
    setTimeout(() => {
      this.viewStatus.emit(true);
      document.body.classList.add(this.stopScroll);
    }, 200);
  }

  ngOnDestroy() {
    document.body.classList.remove(this.stopScroll);
    this.viewStatus.emit(false);
  }

  close() {
    if (this.isUnClosable) return;
    this.isActive = false;
    setTimeout(() => {
      this.store.dispatch(ModalsActions['[modals]CloseModalsSuccess']());
    }, 200);
  }

  @HostListener('document:keydown.escape')
  onEscapeKeydown() {
    this.close();
  }
}
