import { createReducer, on } from '@ngrx/store';
import { ModalsActions } from '../actions/modals.actions';

export const configFeatureKey = 'modals';

export interface State {
  modalId: string;
  isOpen: boolean;
  isLoaded: boolean;
  unClosable?: boolean;
}

export const initialState: State = {
  modalId: '',
  isOpen: false,
  isLoaded: false,
};

export const reducer = createReducer(
  initialState,
  on(ModalsActions['[modals]OpenModalSuccess'], (state, action) => {
    return {
      ...state,
      state: action.state,
      unClosable: action.unClosable,
      dateSelectedModal: action.dateSelectedModal,
      modalId: action.id,
      isOpen: true,
      isLoaded: true,
      amount: action.amount,
      currency: action.currency,
      text: action.text,
      country: action.country,
      bankrates: action.bankrates,
      ID: action.ID,
    };
  }),
  on(ModalsActions['[modals]CloseModalsSuccess'], (state) => {
    return {
      ...state,
      modalId: '',
      isOpen: false,
      isLoaded: true,
    };
  })
);
