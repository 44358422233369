import { createReducer, on } from '@ngrx/store';
import { PaymentMethodsfilterActions } from '../actions/paymentFilter.actions';

export const configFeatureKey = 'paymentMethodsFilter';

export interface State {
  slug: string;
  isLoaded: boolean;
  isCryptoBonus: boolean | undefined;
}

export const initialState: State = {
  slug: '',
  isCryptoBonus: false,
  isLoaded: false,
};

export const reducer = createReducer(
  initialState,
  on(PaymentMethodsfilterActions['[filters]SetCurrentFilterMethod'], (state, action) => {
    return {
      ...state,
      slug: action.slug,
      isCryptoBonus: action.isCryptoBonus,
      isLoaded: true,
    };
  })
);
