import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor() {}

  setLocalStorage(key: string, value: any) {
    localStorage.setItem(key, value);
  }

  getLocalStorage(key: string) {
    return localStorage.getItem(key);
  }

  removeLocalStorage(key: string) {
    return localStorage.removeItem(key);
  }

  setSessionStorage(key: string, value: any) {
    sessionStorage.setItem(key, value);
  }

  getSessionStorage(key: string) {
    return sessionStorage.getItem(key);
  }

  removeSessionStorage(key: string) {
    return sessionStorage.removeItem(key);
  }

  getItemFromLocalStorage(nameLocalStorage?: string) {
    let array: any = [];
    array = localStorage.getItem(`${nameLocalStorage}`);
    array = JSON.parse(array);
    return array;
  }

  setItemTolocalStorage(id?: any, nameLocalStorage?: string) {
    let storageArray: any = [];
    if (typeof localStorage.getItem(`${nameLocalStorage}`) != 'string') {
      storageArray.push(id);
      localStorage.setItem(`${nameLocalStorage}`, JSON.stringify(storageArray));
      return;
    }
    storageArray = this.getItemFromLocalStorage(nameLocalStorage);
    if (storageArray.includes(id)) return;

    storageArray.unshift(id);
    localStorage.setItem(`${nameLocalStorage}`, JSON.stringify(storageArray));
  }
}
