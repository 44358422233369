import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { RegistrationForm } from '../models/sign-up.model';

export const checkUserExist = createActionGroup({
  source: 'CheckUserExist',
  events: {
    '[checkUserExist] checkUserExist': props<RegistrationForm>(),
    '[checkUserExist] checkUserExist Success': props<{
      data: any;
    }>(),
    '[checkUserExist]UpdateUserData': props<{
      data: any;
    }>(),
  },
});
export const restoreDefault = createActionGroup({
  source: 'RestoreDefault',
  events: {
    '[restoreDefault] restoreDefault': emptyProps(),
  },
});
