import { CommonModule, NgIf, NgOptimizedImage } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { forkJoin, Observable, Subject, takeUntil } from 'rxjs';
import { Ranks, userTurnover } from 'src/app/core/interfaces/interfaces';
import { TurnoverSumFormatterPipe } from 'src/app/core/pipes/wagerBalancePipe/turnover-sum-formatter.pipe';
import { LoyaltyService } from 'src/app/core/services/loyalty/loyalty.service';
import { selectHelpers } from 'src/app/core/store/helpers/selector/helpers.selectors';
import { LoyaltyActions, transferLoyaltydataActions } from 'src/app/core/store/loyalty/actions/loyalty.actions';
import { selectLoyalty } from 'src/app/core/store/loyalty/selector/loyalty.selector';
import { ModalsActions } from 'src/app/core/store/modals/actions/modals.actions';
import { selectUser } from 'src/app/core/store/user/selectors/user.selector';
import { InnerLoaderComponent } from 'src/app/shared/animations/inner-loader/inner-loader.component';

@Component({
  selector: 'app-all-ranks-modal',
  standalone: true,
  imports: [CommonModule, NgIf, TranslocoModule, TurnoverSumFormatterPipe, NgOptimizedImage, InnerLoaderComponent],
  templateUrl: './all-ranks-modal.component.html',
  styleUrl: './all-ranks-modal.component.scss',
})
export class AllRanksModalComponent implements OnInit, OnDestroy {
  user$: Observable<any> = this.store.select(selectUser);
  helpers$: Observable<any> = this.store.select(selectHelpers);
  loyalty$: Observable<any> = this.store.select(selectLoyalty);
  isLoaded: boolean = false;
  unsubus$: Subject<boolean> = new Subject();
  totalSum: number = 0;
  allRanks: Ranks[] = [];
  claimedRanks: Ranks[] = [];
  isMobile: boolean = false;
  userCurrency: string = '';
  userRank!: Ranks;
  userRankIndex: number = -1;
  constructor(
    private store: Store,
    private loyaltyService: LoyaltyService
  ) {}
  ngOnDestroy(): void {
    this.unsubus$.next(true);
    this.unsubus$.complete();
  }

  ngOnInit(): void {
    this.helpers$.pipe(takeUntil(this.unsubus$)).subscribe((res) => {
      if (res.isLoaded) {
        this.isMobile = res.isMobile;
      }
    });

    forkJoin([
      this.loyaltyService.getUserTurnover(),
      this.loyaltyService.getRanks(),
      this.loyaltyService.getCollectedUserRanks(),
      this.loyaltyService.getUserRank(),
    ])
      .pipe(takeUntil(this.unsubus$))
      .subscribe(([userTurnover, allRanks, claimedRanks, userRank]: [userTurnover, any, any, any]) => {
        if (userTurnover.balance) this.totalSum = Math.floor(userTurnover.balance);
        if (userRank && userRank?.rankid !== null) this.userRank = userRank;
        this.allRanks = allRanks ? allRanks.sort((a: Ranks, b: Ranks) => a.turnoverAmount - b.turnoverAmount) : [];
        this.claimedRanks = claimedRanks ? claimedRanks : [];
        this.allRanks.forEach((rank, index) => {
          rank.isAvailable = rank.turnoverAmount <= this.totalSum;
          rank.progress = this.calculateProgress(rank.turnoverAmount);
          rank.isClaimed = this.isClaimedRank(rank.id);
          rank.color = this.loyaltyService.getRankColor(rank.name);
          rank.progress = this.calculateProgress(rank.turnoverAmount);
          if (rank.id === this.userRank?.rankid) {
            this.userRankIndex = index;
          }
        });
        this.isLoaded = true;
      });
  }
  isClaimedRank(rankId: number): boolean {
    if (!Array.isArray(this.claimedRanks) || this.claimedRanks.length === 0) {
      return false;
    }
    return this.claimedRanks.some((claimedRank: any) => claimedRank?.rankId === rankId);
  }
  calculateProgress(turnoverAmount: number) {
    if (turnoverAmount === 0) return 100;
    const percentage = Math.min(this.totalSum / turnoverAmount, 1) * 100;
    return percentage;
  }
  closeModal() {
    this.store.dispatch(ModalsActions['[modals]CloseModalsSuccess']());
  }
  takeRank(item: Ranks): void {
    this.loyaltyService.collectRank(item.id).subscribe(() => {
      item.isClaimed = true;
      this.store.dispatch(
        transferLoyaltydataActions['[TransferData]SetRequesDataSuccess']({
          data: { sidenavdata: false, calendardata: false, ranksdata: true },
        })
      );
      setTimeout(() => {
        this.store.dispatch(LoyaltyActions['[Loyalty]GetCalendarPendinRewards']());
      }, 700);
    });
  }
}
