export const states: { [key: string]: Array<{ title: string }> } = {
  mex: [
    { title: 'Aguascalientes' },
    { title: 'Baja California' },
    { title: 'Baja California Sur' },
    { title: 'Campeche' },
    { title: 'Chiapas' },
    { title: 'Chihuahua' },
    { title: 'Coahuila' },
    { title: 'Colima' },
    { title: 'Durango' },
    { title: 'Guanajuato' },
    { title: 'Guerrero' },
    { title: 'Hidalgo' },
    { title: 'Jalisco' },
    { title: 'Mexico City' },
    { title: 'Michoacan' },
    { title: 'Morelos' },
    { title: 'Nayarit' },
    { title: 'Nuevo Leon' },
    { title: 'Oaxaca' },
    { title: 'Puebla' },
    { title: 'Queretaro' },
    { title: 'Quintana Roo' },
    { title: 'San Luis Potosi' },
    { title: 'Sinaloa' },
    { title: 'Sonora' },
    { title: 'Tabasco' },
    { title: 'Tamaulipas' },
    { title: 'Tlaxcala' },
    { title: 'Veracruz' },
    { title: 'Yucatan' },
    { title: 'Zacatecas' },
  ],
  bra: [
    { title: 'Acre' },
    { title: 'Alagoas' },
    { title: 'Amapa' },
    { title: 'Amazonas' },
    { title: 'Bahia' },
    { title: 'Ceara' },
    { title: 'Federal District' },
    { title: 'Espirito Santo' },
    { title: 'Goias' },
    { title: 'Maranhao' },
    { title: 'Mato Grosso' },
    { title: 'Mato Grosso do Sul' },
    { title: 'Minas Gerais' },
    { title: 'Para' },
    { title: 'Paraiba' },
    { title: 'Parana' },
    { title: 'Pernambuco' },
    { title: 'Piaui' },
    { title: 'Rio de Janeiro' },
    { title: 'Rio Grande do Norte' },
    { title: 'Rio Grande do Sul' },
    { title: 'Rondonia' },
    { title: 'Roraima' },
    { title: 'Santa Catarina' },
    { title: 'Sao Paulo' },
    { title: 'Sergipe' },
    { title: 'Tocantins' },
  ],
  aus: [
    { title: 'New South Wales' },
    { title: 'Queensland' },
    { title: 'South Australia' },
    { title: 'Tasmania' },
    { title: 'Victoria' },
    { title: 'Western Australia' },
    { title: 'Australian Capital Territory' },
    { title: 'Northern Territory' },
    { title: 'Jervis Bay Territory' },
    { title: 'Ashmore and Cartier Islands' },
    { title: 'Cocos (Keeling) Islands Territory' },
    { title: 'Coral Sea Islands Territory' },
    { title: 'Heard Island and McDonald Islands Territory' },
    { title: 'Norfolk Island Territory' },
  ],
  ind: [
    { title: 'Andhra Pradesh' },
    { title: 'Arunachal Pradesh' },
    { title: 'Assam' },
    { title: 'Bihar' },
    { title: 'Chhattisgarh' },
    { title: 'Goa' },
    { title: 'Gujarat' },
    { title: 'Haryana' },
    { title: 'Himachal Pradesh' },
    { title: 'Jharkhand' },
    { title: 'Karnataka' },
    { title: 'Kerala' },
    { title: 'Madhya Pradesh' },
    { title: 'Maharashtra' },
    { title: 'Manipur' },
    { title: 'Meghalaya' },
    { title: 'Mizoram' },
    { title: 'Nagaland' },
    { title: 'Odisha' },
    { title: 'Punjab' },
    { title: 'Rajasthan' },
    { title: 'Sikkim' },
    { title: 'Tamil Nadu' },
    { title: 'Telangana' },
    { title: 'Tripura' },
    { title: 'Uttar Pradesh' },
    { title: 'Uttarakhand' },
    { title: 'West Bengal' },
    { title: 'Andaman and Nicobar Islands' },
    { title: 'Chandigarh' },
    { title: 'Dadra and Nagar Haveli and Daman and Diu' },
    { title: 'Lakshadweep' },
    { title: 'Ladakh' },
    { title: 'Jammu and Kashmir' },
    { title: 'National Capital Territory of Delhi' },
    { title: 'Puducherry' },
  ],
};
