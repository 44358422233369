import { isDevMode } from '@angular/core';
import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import * as fromConfig from './core/store/config/reducers/config.reducer';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { UrlInterceptor } from './core/interseptors/url.interceptor';
import * as fromSignUp from './core/store/sign-up/reducers/sign-up.reducer';
import * as fromHelpers from './core/store/helpers/reducers/helpers.reducer';
import * as fromAuthUser from './core/store/user/reducers/auth.reducer';
import * as fromSaveUser from './core/store/user/reducers/saveUser.reducer';
import * as fromUserBalances from './core/store/user/reducers/userBalance.reducer';
import * as fromModal from './core/store/modals/reducers/modals.reducer';
import * as fromAnimation from './core/store/animation/reducers/animation.reducer';
import * as fromDates from './core/store/helpers/reducers/dates.reducer';
import * as fromRouter from './core/store/router/reducers/router.reducer';
import * as fromToaster from './core/store/toaster/reducers/toaster.reducer';
import * as fromFilters from './core/store/filters/reducers/filter.reducer';
import * as fromVerification from './core/store/verification/reducers/verification.reducers';
import * as fromDatesStoreHistory from './core/store/helpers/reducers/datesStoreHistory.reducer';
import * as fromBonuses from './core/store/bonuses/reducers/bonuses.reducer';
import * as withdrawTransactions from './core/store/withdraw-transactions/reducers/withdraw-transactions.reducer';
import * as fromFiltersInPayments from './core/store/paymentFilters/reducers/paymentFilter.reducers';
import * as fromLoyalty from './core/store/loyalty/reducers/loyalty.reducer';
import * as fromLoyaltyDataTransfer from './core/store/loyalty/reducers/transferLoyaltyData.reducer';
import { RouterReducerState, routerReducer } from '@ngrx/router-store';
export interface appState {
  router: RouterReducerState<any>;
  [fromToaster.configFeatureKey]: fromToaster.State;
  [fromRouter.configFeatureKey]: fromRouter.State;
  [fromAnimation.configFeatureKey]: fromAnimation.State;
  [fromModal.configFeatureKey]: fromModal.State;
  [fromConfig.configFeatureKey]: fromConfig.State;
  [fromSignUp.checkUserExitFeatureKey]: fromSignUp.State;
  [fromAuthUser.UserAuth]: fromAuthUser.State;
  [fromHelpers.configFeatureKey]: fromHelpers.State;
  [fromSaveUser.saveUserKey]: fromSaveUser.State;
  [fromUserBalances.UserBalanceKey]: fromUserBalances.State;
  [fromDates.datesTransactionsConfigFeatureKey]: fromDates.State;
  [fromDatesStoreHistory.datesStoreHistoryConfigFeatureKey]: fromDatesStoreHistory.State;
  [fromFilters.configFeatureKey]: fromFilters.State;
  [fromVerification.verificationData]: fromVerification.State;
  [fromBonuses.configFeatureKey]: fromBonuses.State;
  [withdrawTransactions.configFeatureKey]: withdrawTransactions.State;
  [fromFiltersInPayments.configFeatureKey]: fromFiltersInPayments.State;
  [fromLoyalty.configFeatureKey]: fromLoyalty.State;
  [fromLoyaltyDataTransfer.transferDataFeatureKey]: fromLoyaltyDataTransfer.State;
}

export const reducers: ActionReducerMap<appState> = {
  router: routerReducer,
  [fromToaster.configFeatureKey]: fromToaster.reducer,
  [fromRouter.configFeatureKey]: fromRouter.reducer,
  [fromAnimation.configFeatureKey]: fromAnimation.reducer,
  [fromModal.configFeatureKey]: fromModal.reducer,
  [fromHelpers.configFeatureKey]: fromHelpers.reducer,
  [fromConfig.configFeatureKey]: fromConfig.reducer,
  [fromSignUp.checkUserExitFeatureKey]: fromSignUp.reducer,
  [fromAuthUser.UserAuth]: fromAuthUser.reducer,
  [fromSaveUser.saveUserKey]: fromSaveUser.reducer,
  [fromUserBalances.UserBalanceKey]: fromUserBalances.reducer,
  [fromDates.datesTransactionsConfigFeatureKey]: fromDates.reducer,
  [fromDatesStoreHistory.datesStoreHistoryConfigFeatureKey]: fromDatesStoreHistory.reducer,
  [fromFilters.configFeatureKey]: fromFilters.reducer,
  [fromVerification.verificationData]: fromVerification.reducer,
  [fromBonuses.configFeatureKey]: fromBonuses.reducer,
  [withdrawTransactions.configFeatureKey]: withdrawTransactions.reducer,
  [fromFiltersInPayments.configFeatureKey]: fromFiltersInPayments.reducer,
  [fromLoyalty.configFeatureKey]: fromLoyalty.reducer,
  [fromLoyaltyDataTransfer.transferDataFeatureKey]: fromLoyaltyDataTransfer.reducer,
};

export const metaReducers: MetaReducer<appState>[] = isDevMode() ? [] : [];

export const httpInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: UrlInterceptor,
    multi: true,
  },
];
