import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, mergeMap, of, timer } from 'rxjs';
import { GetUser, SetUser, SetUserBalances, UserResetPass, authUser } from '../actions/user.actions';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserService } from 'src/app/core/services/user/user.service';
import { GetUserInfo } from '../actions/userInfo.action';

@Injectable()
export class auhtUserEffect {
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private user: UserService
  ) {}
  $checkUserAuth = createEffect(() => {
    return this.actions$.pipe(
      ofType(authUser['[authUser]AuthUser']),
      mergeMap((action) =>
        this.http
          .put<any>('/auth', action, { headers: new HttpHeaders().set('X-UA-Fingerprint', action?.fingerprint) })
          .pipe(
            map((res: any) => authUser['[authUser]AuthUserSuccess']({ data: res })),
            catchError((error: any) => of(authUser['[authUser]AuthUserFailure']({ data: error.error })))
          )
      )
    );
  });
  $userResetPassword = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserResetPass['[UserResetPass]UserResetPass']),
      mergeMap((action) =>
        this.http
          .post<any>('/userPassword', {
            email: action.data,
          })
          .pipe(
            map((res: any) =>
              UserResetPass['[UserResetPass]UserResetPassSuccess']({
                data: {
                  ...res,
                  action,
                },
              })
            ),
            catchError(() => of(UserResetPass['[UserResetPass]UserResetPassFailure']()))
          )
      )
    );
  });

  $getUserInfo = createEffect(() => {
    return this.actions$.pipe(
      ofType(GetUser['[GetUser]GetUser']),
      mergeMap(() =>
        this.user.getUserInfo().pipe(
          map((res: any) =>
            SetUser['[SetUser]SetUserInfoSuccess']({
              data: res,
            })
          ),
          catchError(() => of(SetUser['[SetUser]SetUserInfoFailure']()))
        )
      )
    );
  });

  $timerUserInfo = of(GetUserInfo['[GetUserInfo]GetUserInfo']());

  $getCurrenUserInfo = createEffect(() => {
    return this.actions$.pipe(
      ofType(GetUserInfo['[GetUserInfo]GetUserInfo']),
      mergeMap(() =>
        this.user.getUserInfo().pipe(
          map((res: any) =>
            SetUser['[SetUser]SetUserInfoSuccess']({
              data: res,
            })
          ),
          catchError(() => timer(5000).pipe(exhaustMap(() => this.$timerUserInfo)))
        )
      )
    );
  });

  $getUserProfile = createEffect(() => {
    return this.actions$.pipe(
      ofType(GetUser['[GetUser]GetUser']),
      mergeMap(() =>
        this.user.getUserProfile().pipe(
          map((res: any) =>
            SetUser['[SetUser]SetUserProfileSuccess']({
              data: res,
            })
          ),
          catchError(() => of(SetUser['[SetUser]SetUserProfileFailure']()))
        )
      )
    );
  });

  $getUserInitialBalance = createEffect(() => {
    return this.actions$.pipe(
      // ofType(SetUser['[SetUser]SetUserProfileSuccess']), - Need to change after socket will fixed(Important)!
      ofType(SetUser['[SetUser]SetUserInfoSuccess']),
      exhaustMap(() =>
        this.user.getUserBalanceFromRequest().pipe(
          map((res: any) =>
            SetUserBalances['[SetUserBalances]SetUserBalanceSuccess']({
              data: res,
            })
          )
        )
      )
    );
  });
}
