import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { appState } from 'src/app';
import { HelpersService } from 'src/app/core/services/helpers/helpers.service';
import { KeystoneService } from 'src/app/core/services/keystone/keystone.service';
import { ModalsActions } from 'src/app/core/store/modals/actions/modals.actions';

@Component({
  selector: 'app-withdraw-limits-modal',
  standalone: true,
  imports: [TranslocoModule],
  templateUrl: './withdraw-limits-modal.component.html',
  styleUrls: ['./withdraw-limits-modal.component.scss'],
})
export class WithdrawLimitsModalComponent implements OnInit, OnDestroy {
  image: string = '';
  unsubus$: Subject<boolean> = new Subject();
  limits: string = '';
  mobileLimits: string = '';
  withdrawLimits: Array<string> = [];
  constructor(
    private store: Store<appState>,
    private keystone: KeystoneService,
    private helpers: HelpersService
  ) {}

  ngOnInit() {
    this.fillWithdrawLimits();
  }

  fillWithdrawLimits() {
    this.keystone
      .getBannerPage('withdraw-limits')
      .pipe(takeUntil(this.unsubus$))
      .subscribe((response: any) => {
        this.withdrawLimits = this.helpers.jsonConvert(response[0].content);
      });
  }

  closeModal() {
    this.store.dispatch(ModalsActions['[modals]CloseModalsSuccess']());
  }

  ngOnDestroy(): void {
    this.unsubus$.next(true);
    this.unsubus$.complete();
  }
}
