import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { appState } from 'src/app';
import { Game } from 'src/app/core/interfaces/interfaces';
import { GameLaunchService } from 'src/app/core/services/gameLaunch/game-launch.service';
import { LocalStorageService } from 'src/app/core/services/localStorage/local-storage.service';
import { ModalsActions } from 'src/app/core/store/modals/actions/modals.actions';
import { MainButtonComponent } from '../../buttons/main-button/main-button.component';
import { TranslocoModule } from '@ngneat/transloco';
import { GamesService } from 'src/app/core/services/gameCatalog/games.service';
import { NgIf } from '@angular/common';
import { selectUser } from 'src/app/core/store/user/selectors/user.selector';

@Component({
  selector: 'app-mobile-game-launch-card',
  templateUrl: './mobile-game-launch-card.component.html',
  styleUrls: ['./mobile-game-launch-card.component.scss'],
  standalone: true,
  imports: [MainButtonComponent, TranslocoModule, NgIf],
})
export class MobileGameLaunchCardComponent implements OnInit, OnDestroy {
  unsubus$: Subject<boolean> = new Subject();
  user$: Observable<any> = this.store.select(selectUser);
  game: Game | null = null;
  isFavorite: boolean = false;
  favoriteGames: Array<string> = [];
  hasDemo: boolean | undefined = false;
  isAuth: boolean = false;

  constructor(
    private storage: LocalStorageService,
    private store: Store<appState>,
    private gameLaunch: GameLaunchService,
    private games: GamesService
  ) {
    this.user$.pipe(takeUntil(this.unsubus$)).subscribe((res) => {
      if (res.loaded) {
        this.isAuth = res.isAuth;
      }
    });
  }
  ngOnInit(): void {
    this.game = JSON.parse(this.storage.getSessionStorage('mobile-game') || '');
    this.hasDemo = this.game?.hasDemo;
    this.isFavorite = false;
    this.findGameInFavoriteList();
  }
  ngOnDestroy(): void {
    this.unsubus$.next(true);
    this.unsubus$.complete();
  }

  closeModal() {
    this.store.dispatch(ModalsActions['[modals]CloseModalsSuccess']());
  }

  findGameInFavoriteList() {
    if (!this.game) return;
    this.getFavoriteGames();
    this.isFavorite = this.favoriteGames?.some((item: any) => item === this.game?.id.toString());
  }

  getFavoriteGames() {
    const favGames = this.storage.getSessionStorage('favoriteGames');
    if (favGames) {
      this.favoriteGames = JSON.parse(favGames);
      return;
    }
    this.getFavoriteGamesFromRequest();
  }

  getFavoriteGamesFromRequest() {
    this.favoriteGames = this.storage.getItemFromLocalStorage('favGames');
  }

  playGame(demo: '1' | '0') {
    this.closeModal();
    if (demo === '0') {
      this.gameLaunch.checkUserProfile(`game/hot/${demo}/${this.game?.id}`);
      return;
    }
    this.gameLaunch.navigateToGame(`game/hot/${demo}/${this.game?.id}`);
  }

  saveGame() {
    if (!this.game) return;
    this.isFavorite = !this.isFavorite;
    this.games.setGameToFavoriteList(this.game?.id);
    this.getFavoriteGamesFromRequest();
  }
}
