import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of } from 'rxjs';
import { authUser, GetUser, SetUser, SetUserBalances, UserResetPass } from '../actions/user.actions';
import { UserService } from 'src/app/core/services/user/user.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class auhtUserEffect {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private user: UserService,
    private store: Store,
    private http: HttpClient
  ) {}
  $checkUserAuth = createEffect(() =>
    this.actions$.pipe(
      ofType(authUser['[authUser]AuthUser']),
      mergeMap((action) => {
        const authData = action.email
          ? { email: action.email, password: action.password }
          : { phone: action.phone, password: action.password };
        return this.authService.login(authData).pipe(
          mergeMap(() => [authUser['[authUser]GetAccessToken']()]),
          catchError((error: any) => of(authUser['[authUser]AuthUserFailure']({ data: error.error })))
        );
      })
    )
  );

  $accessToken = createEffect(() =>
    this.actions$.pipe(
      ofType(authUser['[authUser]GetAccessToken']),
      mergeMap(() =>
        this.authService.getAccessToken().pipe(
          map((res: any) => {
            sessionStorage.setItem('ACCESS_TOKEN', res);

            const token = sessionStorage.getItem('ACCESS_TOKEN');
            if (token) {
              const decodedToken = JSON.parse(atob(token.split('.')[1]));
              this.store.dispatch(authUser['[authUser]SetDataFromToken'](decodedToken));
            }

            return authUser['[authUser]SetAccessToken']({ data: true });
          }),
          catchError((error: any) =>
            of(
              authUser['[authUser]SetAccessToken']({ data: false }),
              authUser['[authUser]AuthUserFailure']({ data: error.error })
            )
          )
        )
      )
    )
  );

  // $checkUserAuth = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(authUser['[authUser]AuthUser']),
  //     mergeMap((action) =>
  //       this.http.post<any>('auth/login', action).pipe(
  //         map((res: any) => authUser['[authUser]AuthUserSuccess']({ data: res })),
  //         catchError((error: any) => of(authUser['[authUser]AuthUserFailure']({ data: error.error })))
  //       )
  //     )
  //   );
  // });

  $userResetPassword = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserResetPass['[UserResetPass]UserResetPass']),
      mergeMap((action) =>
        this.http
          .post<any>('/v1/user/password/forgot', {
            email: action.data,
          })
          .pipe(
            map((res: any) =>
              UserResetPass['[UserResetPass]UserResetPassSuccess']({
                data: {
                  ...res,
                  action,
                },
              })
            ),
            catchError(() => of(UserResetPass['[UserResetPass]UserResetPassFailure']()))
          )
      )
    );
  });

  // $getUserInfo = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(GetUser['[GetUser]GetUser']),
  //     mergeMap(() =>
  //       this.user.getUserInfo().pipe(
  //         map((res: any) =>
  //           SetUser['[SetUser]SetUserInfoSuccess']({
  //             data: res,
  //           })
  //         ),
  //         catchError(() => of(SetUser['[SetUser]SetUserInfoFailure']()))
  //       )
  //     )
  //   );
  // });

  // $timerUserInfo = of(GetUserInfo['[GetUserInfo]GetUserInfo']());

  // $getCurrenUserInfo = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(GetUserInfo['[GetUserInfo]GetUserInfo']),
  //     mergeMap(() =>
  //       this.user.getUserInfo().pipe(
  //         map((res: any) =>
  //           SetUser['[SetUser]SetUserInfoSuccess']({
  //             data: res,
  //           })
  //         ),
  //         catchError(() => timer(5000).pipe(exhaustMap(() => this.$timerUserInfo)))
  //       )
  //     )
  //   );
  // });

  $getUserProfile = createEffect(() => {
    return this.actions$.pipe(
      ofType(GetUser['[GetUser]GetUser']),
      mergeMap(() =>
        this.user.getUserProfile().pipe(
          mergeMap((res: any) => {
            return [
              SetUser['[SetUser]SetUserProfileSuccess']({
                data: res,
              }),
              authUser['[authUser]SetAccessToken']({ data: true }),
            ];
          }),
          catchError(() =>
            of(SetUser['[SetUser]SetUserProfileFailure'](), authUser['[authUser]SetAccessToken']({ data: false }))
          )
        )
      )
    );
  });

  $getUserInitialBalance = createEffect(() => {
    return this.actions$.pipe(
      ofType(SetUser['[SetUser]SetUserProfileSuccess']),
      mergeMap(() =>
        this.user.getUserBalanceFromRequest().pipe(
          map((res: any) => {
            return SetUserBalances['[SetUserBalances]SetUserBalanceSuccess']({
              data: res,
            });
          }),
          catchError(() => of(SetUserBalances['[SetUserBalances]SetUserBalanceFailure']()))
        )
      )
    );
  });
}
