<Button
  type="button"
  [class]="
    'p-4 py-2 w-full flex-center text-xs cursor-pointer font-semibold ' +
    getVariant() +
    (stopSelect || (isInactive && variant === 'default') ? ' pointer-events-none opacity-50 ' : '')
  ">
  <span *ngIf="!stopSelect" id="selectedDay">
    {{ name }}
  </span>
</Button>
