import { createReducer, on } from '@ngrx/store';
import { GetUser, SetUser } from '../actions/user.actions';

export const saveUserKey = 'saveUser';

export interface State {
  userProfile: any;
  userInfo: any;
  loaded: boolean;
  isAuth: boolean;
}

export const initialState: State = {
  userProfile: null,
  userInfo: null,
  loaded: false,
  isAuth: false,
};
export const reducer = createReducer(
  initialState,
  on(GetUser['[GetUser]GetUser'], (state) => {
    return {
      ...state,
      loaded: true,
    };
  }),
  on(SetUser['[SetUser]SetUserProfileSuccess'], (state, action) => {
    return {
      ...state,
      isAuth: true,
      loaded: true,
      userProfile: action,
    };
  }),
  on(SetUser['[SetUser]SetUserInfoSuccess'], (state, action) => {
    return {
      ...state,
      isAuth: true,
      loaded: true,
      userInfo: action,
    };
  }),
  on(SetUser['[SetUser]SetUserInfoFailure'], (state) => {
    return {
      ...state,
      isAuth: false,
      userInfo: null,
    };
  }),
  on(SetUser['[SetUser]SetUserInfoFailure'], (state) => {
    return {
      ...state,
      isAuth: false,
      userProfile: null,
    };
  })
);
