import { Component, Input } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'app-tournament-rules-modal',
  standalone: true,
  imports: [TranslocoModule],
  templateUrl: './tournament-rules-modal.component.html',
  styleUrl: './tournament-rules-modal.component.scss',
})
export class TournamentRulesModalComponent {
  @Input() text: string = '';
}
