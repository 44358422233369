import { createReducer, on } from '@ngrx/store';
import { ConfigActions } from '../actions/config.actions';

export const configFeatureKey = 'config';

export interface State {
  config: any;
  isLoaded: boolean;
}

export const initialState: State = {
  config: undefined,
  isLoaded: false,
};

export const reducer = createReducer(
  initialState,
  on(ConfigActions['[config]ConfigsSuccess'], (state, action) => {
    return {
      ...state,
      config: action,
      isLoaded: true,
    };
  }),
  on(ConfigActions['[config]ConfigsFailure'], (state, action) => {
    return {
      ...state,
      config: action,
      isLoaded: true,
    };
  })
);
