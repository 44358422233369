import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const BrowserReloadsActions = createActionGroup({
  source: 'BrowserReloads',
  events: {
    '[BrowserActions] BrowserReloadss': emptyProps(),
    '[BrowserActions] BrowserReloadss Success': props<{
      data: unknown;
    }>(),
    '[BrowserActions] BrowserReloadss Failure': props<{
      error: unknown;
    }>(),
  },
});
