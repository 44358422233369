import { Injectable } from '@angular/core';
import {
  DateColection,
  dateInterface,
  monthInterface,
  selectedDate,
  selectedDay,
  selectedMonth,
} from '../../interfaces/interfaces';

@Injectable({
  providedIn: 'root',
})
export class SelectBoxService {
  months: Array<monthInterface> = [
    {
      month: 'Jan',
      days: 31,
      date: '01',
    },
    {
      month: 'Feb',
      days: 28,
      date: '02',
    },
    {
      month: 'Mar',
      days: 31,
      date: '03',
    },
    {
      month: 'Apr',
      days: 30,
      date: '04',
    },
    {
      month: 'May',
      days: 31,
      date: '05',
    },
    {
      month: 'Jun',
      days: 30,
      date: '06',
    },
    {
      month: 'Jul',
      days: 31,
      date: '07',
    },
    {
      month: 'Aug',
      days: 31,
      date: '08',
    },
    {
      month: 'Sep',
      days: 30,
      date: '09',
    },
    {
      month: 'Oct',
      days: 31,
      date: '10',
    },
    {
      month: 'Nov',
      days: 30,
      date: '11',
    },
    {
      month: 'Dec',
      days: 31,
      date: '12',
    },
  ];

  emptyDay = {
    day: 0,
    variant: '',
    stopSelect: false,
  };
  constructor() {}
  getActiveMonths(currentParsedDate: dateInterface, registeredTime: dateInterface, activeMonths: any) {
    const activeYears: Array<object> = [];
    const date = new Date();
    const years = currentParsedDate.year - Number(registeredTime.year);

    for (let i = 0; i <= years; i++) {
      activeYears[i] = {
        year: Number(registeredTime.year) + i,
      };
    }
    activeYears.forEach((el: any) => {
      this.months.forEach((elem: any) => {
        if (el.year === registeredTime.year && elem.date < registeredTime.month) {
          return;
        }

        if (el.year === currentParsedDate.year && elem.date > currentParsedDate.month) {
          return;
        }

        const indexOfMonth = this.months.findIndex((month: any) => {
          return month.month == elem.month;
        });
        let a: any = [];
        let days = elem.days;

        if (el.year % 4 == 0) {
          elem.month == 'Feb' ? (days = 29) : (days = elem.days);
        }
        for (let i = 0; i < days; i++) {
          a[i] = {
            day: i + 1,
            variant: 'default',
          };
        }

        a = this.fillPrevMonthDays(date, el, elem, indexOfMonth, a);
        a = this.fillNextMonthDays(a);
        activeMonths.push({
          year: el.year,
          month: elem.month,
          monthDate: elem.date,
          days: a,
        });
      });
    });
    return activeMonths;
  }

  fillPrevMonthDays(date: Date, el: any, elem: any, indexOfMonth: number, a: any) {
    //get days of previous month, for example month started from wednesday, and this function fills days before wednesday

    const prevMonthDays: any = [];
    for (let i = 0; i < this.months[indexOfMonth].days; i++) {
      prevMonthDays.push(i + 1);
    }
    let addPrevMonthDays: any = [];
    date.setFullYear(el.year, elem.date - 1, 1);
    for (let i = 0, k = 1; i < date.getDay(); i++, k++) {
      addPrevMonthDays[i] = {
        day: prevMonthDays[prevMonthDays.length - k],
        variant: 'inactive',
        stopSelect: true,
      };
    }
    addPrevMonthDays = addPrevMonthDays.reverse();
    return addPrevMonthDays.concat(a);
  }

  fillNextMonthDays(a: any) {
    //same as fillPrevMonthDays() but for next month

    const addNextMonthDays: any = [];
    for (let i = 0, k = 1; i < 42 - a.length; i++, k++) {
      addNextMonthDays[i] = {
        day: i + 1,
        variant: 'inactive',
        stopSelect: true,
      };
    }
    return a.concat(addNextMonthDays);
  }

  checkForActiveDaysInitial(dateColection: DateColection) {
    for (let i = dateColection.selectedMonthLeftPage; i < dateColection.activeMonths.length; i++) {
      dateColection.activeMonths[i].days.forEach((el: selectedDay) => {
        if (el?.stopSelect) {
          el.variant = 'inactive';
        }
        if (i == dateColection.selectedMonthLeftPage && el.day == dateColection.item.day) {
          el.variant = 'active-start';
        } else if (dateColection.selectedMonthRightPage == i) {
          if (JSON.stringify(dateColection.selectedFinalDate.day) !== JSON.stringify(this.emptyDay)) {
            if (el.day == dateColection.selectedFinalDate.day.day) {
              el.variant = 'active-end';
            } else if (el.day > dateColection.selectedFinalDate.day.day) {
              el.variant = 'default';
            } else {
              el.variant = 'selected';
            }
          } else {
            el.variant = 'selected';
          }
        } else if (
          el.day > dateColection.selectedInitialDate.day.day ||
          (i > dateColection.selectedMonthLeftPage && i < dateColection.selectedMonthRightPage)
        ) {
          el.variant = 'selected';
        }
        return false;
      });
    }
    return dateColection.activeMonths;
  }
  checkForActiveDaysFinal(dateColection: DateColection) {
    for (let i = dateColection.selectedMonthLeftPage; i <= dateColection.selectedMonthRightPage; i++) {
      dateColection.activeMonths[i].days.forEach((el: any) => {
        if (el.stopSelect) {
          el.variant = 'inactive';
          return;
        }
        if (i == dateColection.selectedMonthRightPage) {
          if (el.day == dateColection.item.day && dateColection.selectedMonthRightPage == i) {
            el.variant = 'active-end';
          } else {
            el.variant = 'selected';
          }
          if (dateColection.selectedMonthRight == dateColection.selectedMonthLeft) {
            if (el.day == dateColection.selectedInitialDate.day.day && i == dateColection.selectedMonthLeft) {
              el.variant = 'active-start';
            }
            if (el.day == dateColection.selectedFinalDate.day.day && i == dateColection.selectedMonthRight) {
              el.variant = 'active-end';
            }
            if (el.day < dateColection.selectedInitialDate.day.day && i == dateColection.selectedMonthLeft) {
              el.variant = 'default';
            }
          }
        } else if (el.day == dateColection.selectedInitialDate.day.day && i == dateColection.selectedMonthLeft) {
          el.variant = 'active-start';
        } else if (
          (el.day < dateColection.selectedInitialDate.day.day && i == dateColection.selectedMonthLeft) ||
          i < dateColection.selectedMonthLeft
        ) {
          el.variant = 'default';
        } else {
          el.variant = 'selected';
        }
        return false;
      });
    }
    return dateColection.activeMonths;
  }
  clearLeftDate(activeMonths: Array<selectedMonth>, selectedMonthLeftPage: number, selectedInitialDate: selectedDate) {
    for (let i = 0; i <= selectedMonthLeftPage; i++) {
      activeMonths[i].days.forEach((el: any) => {
        if (selectedInitialDate.day.day > el.day || i < selectedMonthLeftPage) {
          el.variant = 'default';
        }
      });
    }
    return activeMonths;
  }
  clearRightDate(activeMonths: Array<selectedMonth>, selectedMonthRightPage: number, selectedFinalDate: selectedDate) {
    for (let i = selectedMonthRightPage; i < activeMonths.length; i++) {
      activeMonths[i].days.forEach((el: any) => {
        if (selectedFinalDate.day.day < el.day || i > selectedMonthRightPage) {
          el.variant = 'default';
        }
      });
    }
    return activeMonths;
  }

  getActiveMonthCalendar(currentParsedDate: dateInterface) {
    const currentYear = currentParsedDate.year;
    const currentMonthIndex = currentParsedDate.month - 1;
    const elem = this.months[currentMonthIndex];
    const date = new Date(currentYear, currentMonthIndex, 1);
    const currentDate = new Date();
    const thisMonth = currentDate.getMonth() < currentParsedDate.month ? true : false;
    let days = elem.days;

    if (currentYear % 4 === 0 && elem.month === 'Feb') {
      days = 29;
    }

    let a: any = [];
    for (let i = 0; i < days; i++) {
      const dayDate = new Date(currentYear, currentMonthIndex, i + 1);
      const isTomorrow =
        dayDate.getDate() === currentDate.getDate() + 1 &&
        dayDate.getMonth() === currentDate.getMonth() &&
        dayDate.getFullYear() === currentDate.getFullYear();
      a[i] = {
        day: i + 1,
        informationText: [],
        readyToClaim: false,
        readyToClaimAmount: 0,
        monthVariant: 'currentMonth',
        fullAmount: 0,
        claimedAmount: 0,
        thisMonth: thisMonth,
        isTommorow: isTomorrow,
        isPassedDay: this.checkIsDayPassed(currentMonthIndex, i + 1),
        variant: this.getVariant(currentParsedDate, i, currentDate),
      };
    }

    a = this.fillPrevMonthDaysCalendar(date, currentYear, currentMonthIndex, a);
    a = this.fillNextMonthDaysCalendar(a, currentParsedDate.month);

    return {
      year: currentYear,
      month: elem.month,
      monthDate: elem.date,
      days: a,
    };
  }

  fillPrevMonthDaysCalendar(date: Date, currentYear: number, currentMonthIndex: number, a: any[]) {
    const prevMonthIndex = currentMonthIndex === 0 ? 11 : currentMonthIndex - 1;
    const prevMonthDays = this.months[prevMonthIndex].days;

    date.setDate(1);
    date.setMonth(currentMonthIndex);
    date.setFullYear(currentYear);

    const startDay = date.getDay();

    const addPrevMonthDays = [];
    for (let i = 0; i < startDay; i++) {
      const day = prevMonthDays - (startDay - i - 1);
      addPrevMonthDays.push({
        day: day,
        informationText: [],
        readyToClaim: false,
        readyToClaimAmount: 0,
        monthVariant: 'previousMonth',
        variant: 'previousDay',
        fullAmount: 0,
        collectPreviousDay: false,
        claimedAmount: 0,
        isPassedDay: this.checkIsDayNotReached(currentMonthIndex),
      });
    }

    return addPrevMonthDays.concat(a);
  }

  getVariant(currentParsedDate: dateInterface, i: number, currentDate: Date) {
    if (
      currentParsedDate.year < currentDate.getFullYear() ||
      (currentParsedDate.year === currentDate.getFullYear() && currentParsedDate.month < currentDate.getMonth() + 1) ||
      (currentParsedDate.year === currentDate.getFullYear() &&
        currentParsedDate.month === currentDate.getMonth() + 1 &&
        currentParsedDate.day > i + 1)
    ) {
      return 'previousDay';
    } else if (
      currentParsedDate.year === currentDate.getFullYear() &&
      currentParsedDate.month === currentDate.getMonth() + 1 &&
      currentParsedDate.day === i + 1
    ) {
      return 'today';
    } else {
      return 'nextDay';
    }
  }

  fillNextMonthDaysCalendar(a: any[], monthIndex: number) {
    const daysInCurrentCalendar = a.length;
    const remainder = daysInCurrentCalendar % 7;

    if (remainder > 0) {
      const daysToAdd = 7 - remainder;

      const addNextMonthDays: any[] = [];
      for (let i = 0; i < daysToAdd; i++) {
        addNextMonthDays.push({
          day: i + 1,
          informationText: [],
          fullAmount: 0,
          claimedAmount: 0,
          readyToClaim: false,
          readyToClaimAmount: 0,
          monthVariant: 'nextMonth',
          variant: 'nextDay',
          isPassedDay: this.checkIsDayPassed(monthIndex, i + 1),
        });
      }
      return a.concat(addNextMonthDays);
    } else {
      return a;
    }
  }
  checkIsDayPassed(monthIndex: number, day: number): boolean {
    const currentDate = new Date();
    const currentMonthIndex = currentDate.getMonth();
    const currentDay = currentDate.getDate();

    if (monthIndex < currentMonthIndex) {
      return true;
    } else if (monthIndex === currentMonthIndex) {
      return day < currentDay;
    } else {
      return false;
    }
  }
  checkIsDayNotReached(monthIndex: number): boolean {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    if (monthIndex < currentMonth + 1) {
      return true;
    }
    return false;
  }
}
