import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { FilterActions } from '../actions/filter.actions';
import { tap } from 'rxjs/operators';
import { LocalStorageService } from 'src/app/core/services/localStorage/local-storage.service';

@Injectable()
export class FilterEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private storage: LocalStorageService
  ) {}

  saveFilterProviders$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FilterActions['[SetFilter]SetFilterProvidersSuccess']),
        tap((action) => {
          this.storage.setSessionStorage('filter', JSON.stringify({ data: action.data, names: action.names }));
        })
      );
    },
    { dispatch: false }
  );

  saveFilterSearch$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FilterActions['[SetFilter]SetFilterSearchSuccess']),
        tap((action) => {
          this.storage.setSessionStorage('search', JSON.stringify({ data: action.data }));
        })
      );
    },
    { dispatch: false }
  );

  ramoveFilter$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FilterActions['[ClearFilter]ResetFiltersSuccess']),
        tap(() => {
          this.storage.removeSessionStorage('search');
          this.storage.removeSessionStorage('filter');
        })
      );
    },
    { dispatch: false }
  );
}
