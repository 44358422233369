import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationService } from '../navigation/navigation.service';
import { Observable, Subject } from 'rxjs';
import { selectUser } from '../../store/user/selectors/user.selector';
import { appState } from 'src/app';
import { Store } from '@ngrx/store';
import { LocalStorageService } from '../localStorage/local-storage.service';
import { HelpersService } from '../helpers/helpers.service';

@Injectable({
  providedIn: 'root',
})
export class GameLaunchService {
  unsubus$: Subject<boolean> = new Subject();
  user$: Observable<any> = this.store.select(selectUser);

  constructor(
    private http: HttpClient,
    private navigator: NavigationService,
    private store: Store<appState>,
    private storage: LocalStorageService,
    private helpers: HelpersService
  ) {}

  launchGame(demo: '1' | '0', game: any, isMobile: boolean) {
    // const lang = this.helpers.getCookie('userlang');
    // 998 - evosw  / 983 - ezugi
    console.log(game);
    const launchCode =
      isMobile && game?.merchantId !== 998 && game?.merchantId !== 421
        ? game.pageCodes.mobileUrl.split('/')[1]
        : game.softgamingId;
    return this.http.post('/api/v2/games/launch', { gameId: launchCode, userIp: '65.108.149.23', currency: 'eur' });
  }

  checkUserProfile(gameUrl: string) {
    this.navigateToGame(gameUrl);

    // this.user$
    //   .pipe(takeUntil(this.unsubus$))
    //   .subscribe((res: any) => {
    //     if (res.isAuth && res.loaded) {
    //       if (Object.keys(res?.userInfo?.data?.loyalty?.ActiveBonuses || {}).length > 0) {
    //         const gameCategories = this.storage.getSessionStorage('preLoginGameCategories');
    //         const parsedGameCategories = gameCategories ? JSON.parse(gameCategories) : [];
    //         this.storage.removeSessionStorage('preLoginGameCategories');
    //         if (this.checkGameCategories(parsedGameCategories)) {
    //           this.navigator.navigateTo(`/`);
    //           return;
    //         }
    //       }
    //       if (
    //         (res.userProfile.data.lastName === '' && res.userProfile.data.firstName.indexOf('srwelle') > -1) ||
    //         (res.userProfile.data.lastName.indexOf('srwelle') > -1 && res.userProfile.data.countryCode !== 'rus')
    //       ) {
    //         this.navigator.navigateTo(`/(aside:account/profile)`);
    //         return;
    //       }
    //       if (res.userInfo.data.balance == 0 && res.userInfo.data.freerounds?.length <= 0) {
    //         if (gameUrl.includes('1477472')) {
    //           this.storage.setSessionStorage('preGameUrl', this.navigator.getCuretRoute());
    //           this.navigator.navigateByOrder(gameUrl, `/(aside:cashier/deposit)`);
    //           return;
    //         } else {
    //           this.navigator.navigateTo(`/(aside:cashier/deposit)`);
    //           return;
    //         }
    //       }
    //       this.storage.setSessionStorage('preGameUrl', this.navigator.getCuretRoute());
    //       this.navigateToGame(gameUrl);
    //       return;
    //     }
    //     if (!res.isAuth && !res.loaded) {
    //       this.storage.setSessionStorage('preLoginGameUrl', gameUrl);
    //       this.storage.setSessionStorage('preGameUrl', this.navigator.getCuretRoute());
    //       this.navigator.navigateTo(`/auth/sign-in`);
    //       return;
    //     }
    //   })
    //   .unsubscribe();
  }

  navigateToGame(gameUrl: string) {
    this.storage.setSessionStorage('preGameUrl', this.navigator.getCuretRoute());
    this.navigator.navigateTo(gameUrl);
  }

  unsubscribe() {
    this.unsubus$.next(true);
    this.unsubus$.complete();
  }
  checkGameCategories(categories: any[]) {
    const forbiddenCategories = ['bonus_risk', 'progression_feature', 'savingsgames', 'bonusrestricted'];
    return categories.some((item: any) => forbiddenCategories.includes(item.slug));
  }
}
