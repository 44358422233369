<ng-container *transloco="let t">
  <div class="countries-container dark:text-mainText text-mainTextLight w-full h-full flex flex-col px-2 pb-6">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <app-text-field
        [label]="t('auth.prefix')"
        formControlName="countryName"
        [showLabel]="false"
        [isSearch]="true"
        [isLight]="true"
        (searchEmiter)="search($event)"
        id="prefixField"></app-text-field>
    </form>
    <ng-container *ngIf="viewInit; else loader">
      <div
        *ngFor="let item of searchSuccess ? filtredCountries : countries; let last = last"
        class="country relative w-full p-2 border-b-[1px] border-mainBgLighter dark:border-line">
        <div class="absolute w-16 h-[2px] left-0 -bottom-[2px] dark:bg-containerBg bg-containerBgLight"></div>
        <label
          [class]="
            'inner-container flex items-center gap-4 rounded-lg px-2 hover:dark:bg-mainHover hover:bg-skyBtnHoverLight active:dark:bg-blueBgColor active:bg-blueBgColor transition-colors' +
            (item.iso3.includes(this.currentPrefix)
              ? ' bg-blueBgColor '
              : ' hover:dark:bg-mainHover hover:bg-skyBtnHoverLight ')
          ">
          <div>
            <div
              class="rounded-full bg-[50%] w-8 h-8 bg-cover bg-no-repeat border-2 border-[#dbdce4]"
              [ngClass]="'flag-icon-rounded flag-icon-' + item.iso3"></div>
          </div>
          <label class="flex justify-between items-center w-full rounded-lg cursor-pointer pl-4 py-3">
            <span class="text-sm font-semibold leading-6">
              {{ item.name }}
            </span>
            <div class="flex gap-4 items-center">
              <span>+{{ item.phoneCode }}</span>
              <input
                (click)="selectPrefix(item, true)"
                type="checkbox"
                class="form-custom-check-input"
                [checked]="item.iso3.includes(this.currentPrefix)" />
            </div>
          </label>
        </label>
      </div>
    </ng-container>
    <ng-template #loader>
      <div class="min-h-20">
        <app-inner-loader></app-inner-loader>
      </div>
    </ng-template>
  </div>
</ng-container>
