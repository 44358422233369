import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Location, NgClass, NgIf } from '@angular/common';
import { Store } from '@ngrx/store';
import { NavigationService } from 'src/app/core/services/navigation/navigation.service';
import { Subject, Observable, takeUntil } from 'rxjs';
import { selectHelpers } from 'src/app/core/store/helpers/selector/helpers.selectors';
import { TranslocoModule } from '@ngneat/transloco';
import { FinancesService } from 'src/app/core/services/finances/finances.service';
import { HelpersActions } from 'src/app/core/store/helpers/actions/helpers.actions';

@Component({
  selector: 'app-bar-close',
  templateUrl: './bar-close.component.html',
  styleUrls: ['./bar-close.component.scss'],
  standalone: true,
  imports: [TranslocoModule, NgClass, NgIf],
})
export class BarCloseComponent implements OnInit, OnDestroy {
  unsubus$: Subject<boolean> = new Subject();
  helpers$: Observable<any> = this.store.select(selectHelpers);

  @Output()
  closeEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Input()
  back: boolean = false;
  @Input()
  isTermsRenew: boolean = false;
  @Input()
  sideNavStepBack: boolean = false;
  @Input()
  isSideNav: boolean = false;
  @Input()
  unableCloseTitle: boolean = true;
  @Input()
  isSmall: boolean = true;
  @Input()
  backgroundColor: string = '';
  @Input()
  backLink: string = '';
  @Input()
  title: string = '';
  isMobile: boolean = true;
  @Input()
  loyaltyModals: boolean = false;
  @Input()
  appBarTitleToLeft: boolean = false;
  @Input()
  barClosePageType: string = '';
  activePayment: any;
  currentStep: number = 1;
  constructor(
    private location: Location,
    private navigation: NavigationService,
    private store: Store,
    private finances: FinancesService
  ) {}
  ngOnInit() {
    this.helpers$.pipe(takeUntil(this.unsubus$)).subscribe((res: any) => {
      if (res.isLoaded) {
        this.isMobile = res.isMobile;
        this.currentStep = res.stepperData.step;
        this.activePayment = res.stepperData?.selectedPayment;
        if (
          (this.currentStep > 1 && this.barClosePageType === 'withdraw') ||
          (this.currentStep > 2 && this.barClosePageType === 'deposit')
        ) {
          this.sideNavStepBack = true;
        } else {
          this.sideNavStepBack = false;
        }
      }
    });
  }
  ngOnDestroy(): void {
    this.unsubus$.next(true);
    this.unsubus$.complete();
  }
  close() {
    this.closeEmitter.emit(true);
  }
  routerBack() {
    if (this.sideNavStepBack) {
      this.finances.sendPaymentMethod(this.activePayment);
      this.store.dispatch(
        HelpersActions['[helpers]SetCurrentStep']({
          data: { step: this.barClosePageType === 'withdraw' ? 1 : 2, isBonus: false, selectedPayment: null },
        })
      );
      return;
    }
    if (this.backLink !== '') {
      this.navigation.navigateTo(this.backLink);
    } else {
      this.location.back();
    }
  }
}
