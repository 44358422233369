<ng-container *transloco="let t">
  <div class="text-mainTextLight dark:text-mainText w-full h-full flex flex-col px-2 bg-smallContainerBgLight py-2">
    <div *ngFor="let item of currencies; let last = last" class="py-2 mx-2 border-b-[1px] border-mainBgLighter">
      <label
        [class]="
          'inner-container flex justify-between items-center w-full rounded-xl cursor-pointer px-3 md:px-4 py-3 transition-colors ' +
          (this.selectedCurrency.includes(item?.iso3)
            ? 'bg-blueBgColor'
            : 'hover:dark:bg-mainHover hover:bg-skyBtnHoverLight')
        ">
        <div class="flex items-center gap-4">
          <div
            class="rounded-full bg-[50%] w-8 h-8 bg-cover bg-no-repeat"
            [ngClass]="'flag-icon-rounded flag-icon-' + currencyCountryMap[item.iso3]"></div>
          <span class="text-sm font-semibold leading-6">
            {{ item.iso3 }}
          </span>
        </div>
        <div class="flex gap-4 items-center">
          <span class="text-right">
            {{ t('shared.currencies.' + item.iso3) }}
          </span>
          <input
            (click)="selectCurrency(item)"
            type="checkbox"
            class="form-custom-check-input"
            [checked]="this.selectedCurrency.includes(item.iso3)"
            [id]="item.iso3.toLowerCase() + 'Checkbox'" />
        </div>
      </label>
    </div>
  </div>
</ng-container>
