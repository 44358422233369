import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap } from 'rxjs';
import { HelpersService } from 'src/app/core/services/helpers/helpers.service';
import { getCountries } from '../actions/helpers.actions';

@Injectable()
export class HelpersEffects {
  constructor(
    private actions$: Actions,
    private helper: HelpersService
  ) {}

  $checkUserExist = createEffect(() => {
    return this.actions$.pipe(
      ofType(getCountries['[getCountries]GetCountries']),
      mergeMap(() =>
        this.helper.getCountries().pipe(
          map((res: any) =>
            getCountries['[getCountries]GetCountriesSuccess']({
              data: res,
            })
          )
        )
      )
    );
  });
}
