<ng-container *transloco="let t">
  <Button
    [type]="type"
    (click)="buttonClick()"
    [disabled]="loading"
    [class]="
      (disabled ? 'pointer-events-none opacity-50 ' : '') +
      'p-4 w-full cursor-pointer text-mainTextLight ' +
      (terms ? ' flex-center gap-2 ' : ' ') +
      getVariant() +
      (variant === 'mainButton' ? ' !p-3 ' : '') +
      (variant === 'mainButton' && disabled ? ' bg-lightGrayBorder ' : '') +
      (variant === 'yellowBg' ? ' text-base ' : ' text-sm ') +
      (variant === 'sideBarBlue' ? ' p-[1.125rem] ' : '')
    ">
    <ng-container *ngIf="!loading; else Loader">
      @if (datePicker) {
        {{ name }}
      } @else {
        {{ t('buttons.' + name) }}
      }

      <img *ngIf="svg" [class]="' w-5 h-5 '" [src]="svg" [alt]="name + 'redirect to new tab'" />
    </ng-container>
  </Button>

  <ng-template #Loader>
    <div class="flex flex-row justify-center items-center text-mainTextLight h-5 gap-2">
      <span *ngIf="isLoadingText">{{ t('buttons.loader') }}</span>
      <app-loader></app-loader>
    </div>
  </ng-template>
</ng-container>
