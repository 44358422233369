import { Injectable } from '@angular/core';
import { UserService } from '../user/user.service';
import { UserProfile, dateValues, datekeys, notificationsInterface } from '../../interfaces/interfaces';
import { Subject } from 'rxjs';
import { Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  unsubus$: Subject<boolean> = new Subject();
  dateKeys: Array<datekeys> = [
    {
      boxKey: 'Year',
      formKey: 'birthYear',
    },
    {
      boxKey: 'Month',
      formKey: 'birthMonth',
    },
    {
      boxKey: 'Day',
      formKey: 'birthDay',
    },
  ];
  notifications: Array<notificationsInterface> = [
    {
      formKey: 'emailAgree',
      name: 'By Email',
      checked: false,
    },
    {
      formKey: 'smsAgree',
      name: 'By SMS',
      checked: false,
    },
  ];
  dateValues: dateValues = {};

  constructor(private user: UserService) {}

  submitLimitForm(formValue: { days: string; email: string }) {
    if (formValue.days === 'forever') {
      this.user.pauseAccountPermanently();
      return;
    }

    this.user.pauseUserAccaunt(Number(formValue.days));
  }

  formCheckLockExceptions(key: string, form: any, isNotDisabled: boolean) {
    if (isNotDisabled && (key == 'email' || key == 'phoneCode' || key == 'phoneNumber')) {
      form.get(key)?.disable();
    } else {
      form.get(key)?.enable();
    }
  }

  formCheckForLock(profile: UserProfile, key: string, form: any, isNotDisabled: boolean) {
    if (profile[key as keyof UserProfile] == '' || profile[key as keyof UserProfile] == '0' || isNotDisabled) {
      this.formCheckLockExceptions(key, form, isNotDisabled);
    } else {
      form.get(key)?.disable();
    }
    return form;
  }
  parseDateOfBirthFromDate(dateOfBirth: string): { birthDay: number; birthMonth: number; birthYear: number } {
    const dateObj = new Date(dateOfBirth);
    const birthDay = dateObj.getDate();
    const birthMonth = dateObj.getMonth() + 1;
    const birthYear = dateObj.getFullYear();

    return { birthDay, birthMonth, birthYear };
  }
  formatDateOfBirthToDate(birthDay?: string, birthMonth?: string, birthYear?: string): Date {
    const day = Number(birthDay);
    const month = Number(birthMonth) - 1;
    const year = Number(birthYear);

    const dateObj = new Date(year, month, day);

    return dateObj;
  }

  saveDataValues(key: string, profile: UserProfile, isNotDisabled: boolean): dateValues {
    this.dateKeys.find((e) => {
      if (e.formKey === key) {
        this.dateValues[e.boxKey as keyof dateValues] = {
          selectedValue: profile[key as keyof UserProfile],
          value: profile[key as keyof UserProfile],
          isLocked: profile[key as keyof UserProfile] !== '' && !isNotDisabled ? true : false,
          isError: false,
        };
        return true;
      }
      return false;
    });
    return this.dateValues;
  }

  getStateFieldExceptions(country: string): { country: string; exception: boolean } {
    const exceptions = ['mex', 'bra', 'aus', 'ind'];
    return { country, exception: exceptions.includes(country) };
  }

  validatorSetter(control: any, validatorList: any, state: boolean): void {
    if (state) {
      control?.setValidators(Validators.compose([...validatorList]));
      control?.updateValueAndValidity();
      return;
    }
    control?.clearValidators();
    control?.updateValueAndValidity();
    control?.patchValue(null);
  }

  saveNotifications(profile: UserProfile, key: string, form: any) {
    this.notifications.find((elem: any) => {
      if (elem.formKey == key) {
        elem.checked =
          profile.extProfile[key as keyof UserProfile['extProfile']] !== undefined
            ? profile.extProfile[key as keyof UserProfile['extProfile']]
            : true;
        return this.patchNotification(elem, form);
      }
    });
    return form;
  }

  getNotifications() {
    return this.notifications;
  }

  patchNotification(data: notificationsInterface, form: any) {
    this.notifications.find((elem: any) => {
      if (elem.name == data.name) {
        elem.checked = data.checked;
        form.get(elem.formKey)?.patchValue(elem.checked ? true : false);
      }
    });
    return form;
  }
  setUserProfile(f: any, profile: UserProfile) {
    return (profile = {
      ...profile,
      ...f,
    });
  }
}
