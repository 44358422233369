import { createReducer, on } from '@ngrx/store';
import { LoyaltyActions } from '../actions/loyalty.actions';
import { ReadyToClaimQuest } from 'src/app/core/interfaces/interfaces';

export const configFeatureKey = 'loyalty';

export interface State {
  wellbet: any;
  reefback: any;
  isLoaded: boolean;
  tradableBalance: any;
  rewardedStatistics: any;
  userTurnover: any;
  calendarPendingRewards: any;
  readyToClaimQuest: ReadyToClaimQuest;
}

export const initialState: State = {
  wellbet: null,
  reefback: null,
  isLoaded: false,
  tradableBalance: null,
  rewardedStatistics: null,
  userTurnover: null,
  calendarPendingRewards: null,
  readyToClaimQuest: { readyToClaimCount: 0, readyToClaimAuthQuest: false },
};

export const reducer = createReducer(
  initialState,

  on(LoyaltyActions['[Loyalty]SetWellBetSuccess'], (state, action) => {
    return {
      ...state,
      wellbet: action.data,
      isLoaded: true,
    };
  }),
  on(LoyaltyActions['[Loyalty]SetReefbackSuccess'], (state, action) => {
    return {
      ...state,
      reefback: action.data,
      isLoaded: true,
    };
  }),
  on(LoyaltyActions['[Loyalty]SetWellBetFailure'], (state) => ({
    ...state,
    wellbet: null,
    isLoaded: false,
  })),
  on(LoyaltyActions['[Loyalty]SetReefbackFailure'], (state) => ({
    ...state,
    reefback: null,
    isLoaded: false,
  })),

  on(LoyaltyActions['[Loyalty]SetTradableBalanceSuccess'], (state, action) => {
    return {
      ...state,
      tradableBalance: action.data,
      isLoaded: true,
    };
  }),
  on(LoyaltyActions['[Loyalty]SetTradableBalanceFailure'], (state) => ({
    ...state,
    tradableBalance: null,
    isLoaded: false,
  })),

  on(LoyaltyActions['[Loyalty]SetRewardedStatisticsSuccess'], (state, action) => {
    return {
      ...state,
      rewardedStatistics: action.data,
      isLoaded: true,
    };
  }),
  on(LoyaltyActions['[Loyalty]SetRewardedStatisticsFailure'], (state) => ({
    ...state,
    rewardedStatistics: null,
    isLoaded: false,
  })),

  on(LoyaltyActions['[Loyalty]SetUserTurnoverFailure'], (state) => ({
    ...state,
    userTurnover: null,
    isLoaded: false,
  })),

  on(LoyaltyActions['[Loyalty]GetCombinedLoyaltyData'], (state) => {
    return {
      ...state,
    };
  }),
  on(LoyaltyActions['[Loyalty]SetUserTurnoverSuccess'], (state, action) => ({
    ...state,
    userTurnover: action.data,
    isLoaded: true,
  })),
  on(LoyaltyActions['[Loyalty]SetCalendarPendinRewardsSuccess'], (state, action) => ({
    ...state,
    calendarPendingRewards: action.data,
    isLoaded: true,
  })),

  on(LoyaltyActions['[Loyalty]SetCalendarPendinRewardsFailure'], (state) => ({
    ...state,
    calendarPendingRewards: null,
    isLoaded: false,
  })),
  on(LoyaltyActions['[Loyalty]SetFinishedQuestsCountSuccess'], (state, action) => ({
    ...state,
    readyToClaimQuest: action.data,
    isLoaded: true,
  })),
  on(LoyaltyActions['[Loyalty]SetFinishedQuestsCountFailure'], (state) => ({
    ...state,
    readyToClaimQuest: { readyToClaimCount: 0, readyToClaimAuthQuest: false },
    isLoaded: true,
  }))
);
