import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
// import { map, mergeMap } from 'rxjs';
// import { getCountries } from '../actions/helpers.actions';
import { HelpersService } from 'src/app/core/services/helpers/helpers.service';

@Injectable()
export class HelpersEffects {
  constructor(
    private actions$: Actions,
    private helper: HelpersService
  ) {}

  // $checkUserExist = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(getCountries['[getCountries]GetCountries']),
  //     mergeMap(() =>
  //       this.helper.getCountries().pipe(
  //         map((res: any) =>
  //           getCountries['[getCountries]GetCountriesSuccess']({
  //             data: res,
  //           })
  //         )
  //       )
  //     )
  //   );
  // });
}
