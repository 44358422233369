import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const ModalsActions = createActionGroup({
  source: 'Modals',
  events: {
    '[modals] Modals': emptyProps(),
    '[modals] Open Modal Success': props<{
      id: string;
      dateSelectedModal?: string;
      unClosable?: boolean;
      state?: any;
      amount?: number;
      currency?: string;
      text?: string;
      country?: string;
      bankrates?: any;
      ID?: string;
    }>(),
    '[modals] Close Modals Success': emptyProps(),
  },
});
