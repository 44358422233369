import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BonusesService } from 'src/app/core/services/bonuses/bonuses.service';
import { GetBonuses, SetBonuses } from '../actions/bonus.actions';
import { catchError, map, mergeMap, of } from 'rxjs';

@Injectable()
export class bonusesEffect {
  constructor(
    private actions$: Actions,
    private bonusService: BonusesService
  ) {}

  $getBonuses = createEffect(() => {
    return this.actions$.pipe(
      ofType(GetBonuses['[GetBonuses]GetBonuses']),
      mergeMap(() =>
        this.bonusService.getBonuses().pipe(
          map((res: any) =>
            SetBonuses['[SetBonuses]SetBonusesSuccess']({
              data: res,
            })
          ),
          catchError(() => of(SetBonuses['[SetBonuses]SetBonusesFailure']()))
        )
      )
    );
  });
  $updateActiveBonus = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SetBonuses['[SetBonuses]SetBonusesSuccess']),
        map((res: any) => this.bonusService.getCurrentBonusBalance(res?.data))
      );
    },
    {
      dispatch: false,
    }
  );
}
