import { createReducer, on } from '@ngrx/store';
import { HelpersActions } from '../actions/helpers.actions';
import { possibleLang, selectedDepositData, stepperData } from 'src/app/core/interfaces/interfaces';

export const configFeatureKey = 'helpers';

export interface State {
  helpers: any;
  isMobile: boolean;
  screenWidth: number;
  currentCountry: string;
  possibleLanguages: Array<possibleLang>;
  currentLanguage: string;
  browserName: string;
  browserLanguages: string[];
  isLoaded: boolean;
  isTopButton: boolean;
  selectedDepositData: selectedDepositData;
  stepperData: stepperData;
}

export const initialState: State = {
  helpers: undefined,
  isMobile: true,
  browserName: '',
  screenWidth: 0,
  possibleLanguages: [],
  currentCountry: '',
  currentLanguage: '',
  browserLanguages: [],
  isLoaded: false,
  isTopButton: false,
  selectedDepositData: {},
  stepperData: { step: 1, isBonus: false, selectedPayment: null },
};

export const reducer = createReducer(
  initialState,
  on(HelpersActions['[helpers]HelpersSuccess'], (state, action) => {
    return {
      ...state,
      helpers: action,
      isLoaded: true,
    };
  }),
  on(HelpersActions['[helpers]SetIsMobileStatus'], (state, action) => {
    return {
      ...state,
      isMobile: action.data,
      isLoaded: true,
    };
  }),
  on(HelpersActions['[helpers]SetScreenWidth'], (state, action) => {
    return {
      ...state,
      screenWidth: action.data,
      isLoaded: true,
    };
  }),
  on(HelpersActions['[helpers]SetCurrentCountry'], (state, action) => {
    return {
      ...state,
      currentCountry: action.data,
      isLoaded: true,
    };
  }),
  on(HelpersActions['[helpers]SetCurrentInfoPage'], (state, action) => {
    return {
      ...state,
      currentPage: action.data,
      isLoaded: true,
    };
  }),
  on(HelpersActions['[helpers]SetBrowserName'], (state, action) => {
    return {
      ...state,
      browserName: action.data,
      isLoaded: true,
    };
  }),
  on(HelpersActions['[helpers]SetCurrentLanguage'], (state, action) => {
    return {
      ...state,
      currentLanguage: action.data,
      isLoaded: true,
    };
  }),
  on(HelpersActions['[helpers]SetPossibleLanguages'], (state, action) => {
    return {
      ...state,
      possibleLanguages: action.data,
      isLoaded: true,
    };
  }),
  on(HelpersActions['[helpers]GetBrowserPreferedLanguages'], (state, action) => {
    return {
      ...state,
      browserLanguages: action.data,
      isLoaded: true,
    };
  }),
  on(HelpersActions['[helpers]SetCurrentDepositAmount'], (state, action) => {
    return {
      ...state,
      selectedDepositData: action.data,
      isLoaded: true,
    };
  }),
  on(HelpersActions['[helpers]HelpersFailure'], (state) => {
    return {
      ...state,
      helpers: undefined,
      isLoaded: true,
    };
  }),
  on(HelpersActions['[helpers]SetCurrentStep'], (state, action) => {
    return {
      ...state,
      stepperData: action.data,
    };
  })
);
