<div class="flex flex-col items-center text-mainTextLight p-4 pt-0 gap-6" *transloco="let t">
    <img src="https://cdn.chipychapa.com//welle/General_site/Terms/information.svg" alt="Warning" />

    <div class="flex flex-col gap-3 text-base font-normal text-center pb-5">
        <div>{{ t('block.modal.are_you_shure') }}</div>
        <div>{{ t('block.modal.info') }}</div>
    </div>
    <div class="flex flex-col-reverse md:flex-row gap-3 w-full">

      <app-main-button
      (click)="userBlock()"
      variant="secondary"
      name="yes"
      class="w-full"
      id="blockYes"></app-main-button>

      <app-main-button
      (click)="closeModal()"
      variant="yellowBg"
      name="no"
      class="w-full"
      id="blockNo"></app-main-button>
    </div>
</div>
  