<ng-container *transloco="let t">
  <div class="w-full flex flex-col gap-4 justify-center items-center text-mainTextLight p-4">
    <img src="https://cdn.chipychapa.com//welle/Logo_n_fav/welle_logo.svg" alt="welle_logo" width="135" height="38" />
    <div class="pt-6">
      <img src="https://cdn.chipychapa.com//welle/General_site/Terms/information.svg" width="35" alt="Warning" />
    </div>
    <div class="flex flex-col gap-5">
      <div class="flex gap-2">
        <div>&bull;</div>
        <div class="font-normal">{{ t('terms.renew.justUpdated') }}</div>
      </div>
      <div class="flex gap-2">
        <div>&bull;</div>
        <div class="font-normal">{{ t('terms.renew.accept') }}</div>
      </div>
    </div>
    <label class="w-full items-center cursor-pointer flex justify-start" (click)="checkTerms()">
      <div class="flex-center w-11 h-11">
        @if (checked) {
          <img src="https://cdn.chipychapa.com//welle/General_site/Autharization/tc_confirmed.svg" alt="selected" />
        } @else {
          <object
            alt="loader"
            class="w-11 pointer-events-none"
            type="image/svg+xml"
            data="https://cdn.chipychapa.com//welle/General_site/Autharization/chekbox_for_tc.svg">
            <img src="https://cdn.chipychapa.com//welle/General_site/Autharization/chekbox_for_tc.svg" alt="loader" />
          </object>
        }
      </div>
      <span
        class="font-normal text-base leading-5 p-2 dark:text-secondaryTextMain text-secondaryTextLight hover:dark:text-mainText hover:text-mainTextLight"
        id="checkTerms">
        {{ t('auth.confirm_all_metioned') }}
      </span>
    </label>

    <div class="w-full flex justify-center items-center flex-col gap-4" id="termsAndConditions">
      <app-main-button class="w-full" (click)="openTerms()" variant="secondary" name="T&C"></app-main-button>
      <app-main-button
        variant="yellowBg"
        class="w-full"
        (click)="acceptTerms()"
        [disabled]="!checked"
        name="accept_continue"
        id="acceptContinue"></app-main-button>
    </div>
    <span class="text-center font-normal text-xs leading-4 dark:text-secondaryTextMain text-secondaryTextLight">
      {{ t('terms.renew.protected') }}
    </span>
  </div>
</ng-container>
