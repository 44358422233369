import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class QueryService {
  constructor() {}

  getAllSlugQuery(lang: any) {
    return {
      query: `{ category { slug cSort cSubSort text {name_${lang} name_en} categoryTags{ name }}}`,
    };
  }

  getAllMerchants(country: string) {
    return {
      query: `{ merchant (orderby: {weight: desc} ,where: {games: {some: {countryRestriction: {is: {countries: {none: {country: {equals: "${country}", mode: insensitive}}}}}}}}) { id, name, weight, menuId, alias, image, merchantCurrencies {name}  } }`,
    };
  }
  getGameIdsByCategory(slug: string) {
    return {
      query: `{gamesByCategory(slug:"${slug}"){id}}`,
    };
  }
  getAllPromotions(lang: string, country: string) {
    return {
      query: `{ allPromotions (sortBy: order_ASC, where: { AND: [{isVisible: true}, {restrictedCountries_none: {name_i: "${country}"}}, { OR: [{allowedCountries_some: {name_i: "${country}"}}, {allowedCountries_every: {name: null}}]}] } ){ id , img, slug, description_l_${lang}, name_l_${lang}, short_l_${lang}, opengraphTitle_l_${lang}, opengraphDescription_l_${lang}, opengraphKeywords, opengraphImage, buttonClass, buttonAction } }`,
    };
  }
  getGamesBySlugAndFilter(take: number, skip: string, categories?: any, merchants?: any, country?: string) {
    return {
      query: `{game (take: ${take}, skip: ${skip},orderby: [{merchantSort: {sort:desc}}, {sort: {sort:desc}}], where: {AND: [{ merchantId: { in: [${merchants}] }},{ categories: { some: { category: { is: {slug: {equals: "${categories}"}}}}}},{countryRestriction: {is: {countries: {none: {country: {equals: "${country}", mode: insensitive}}}}}} ]}) {id hasDemo isVirtual merchant{ name, alias } gameCategories{ slug } image imageFullPath merchantId sort pageCodes { launch, mobileUrl, url, default, external, mobileExternal, mobileAndroid, mobileExternal, mobileWindows, }, parentMerchantId  text {name_en} Likes { gameId, likes } } }`,
    };
  }
  getGamesByMerchantId(take: number, skip: string, merchantId: any, country?: string) {
    return {
      query: `{ game (take: ${take}, skip: ${skip},orderby: [{merchantSort: {sort:desc}}, {sort: {sort:desc}}], where: {AND: [{merchant: {is: {id: {in: [${merchantId}]}}}},{countryRestriction: {is: {countries: {none: {country: {equals: "${country}", mode: insensitive}}}}}}]}) {id isVirtual hasDemo gameCategories{ slug } image imageFullPath, merchantId, merchant{ name, alias }, pageCodes { launch, mobileUrl, url, default, external,  mobileExternal, mobileAndroid, mobileExternal, mobileWindows,}, parentMerchantId merchant{ name, alias } text {name_en} Likes { gameId, likes } }}`,
    };
  }
  searchGameByNameQuery(take: number, skip: string, value: string, country?: string) {
    return {
      query: `{game(take: ${take}, skip: ${skip}, orderby: {sort: {sort:desc}},where: {AND: [{text:{is: {name_en: {contains: "${value}", mode: insensitive}}}},{countryRestriction: {is: {countries: {none: {country: {equals: "${country}", mode: insensitive}}}}}} ]}) { id hasDemo gameCategories{ slug } image imageFullPath isVirtual merchantId   text { name_en } merchant{ name, alias }  pageCodes { launch, mobileUrl, url, default, external,  mobileExternal, mobileAndroid, mobileExternal, mobileWindows,}, merchantId parentMerchantId Likes { gameId, likes } } }`,
    };
  }
  getGamesByFilterAndName(take: number, skip: string, merchants: any, search: string, country?: string) {
    return {
      query: `{game (take: ${take}, skip: ${skip}, orderby: [{merchantSort: {sort:desc}}, {sort: {sort:desc}}], where: {AND: [{ text: { is: { name_en: { contains: "${search}", mode: insensitive }}}},{ merchantId: { in: [${merchants}] }},{countryRestriction: {is: {countries: {none: {country: {equals: "${country}", mode: insensitive}}}}}} ]}) {id hasDemo isVirtual merchant{ name, alias } gameCategories{ slug }  image imageFullPath merchantId sort pageCodes { launch, mobileUrl, url, default, external, mobileExternal, mobileAndroid, mobileExternal, mobileWindows, }, parentMerchantId  text {name_en} Likes { gameId, likes } } }`,
    };
  }
  getGamesBySlugAndName(take: number, skip: string, categories: any, search: string, country?: string) {
    return {
      query: `{game (take: ${take}, skip: ${skip}, orderby: [{merchantSort: {sort:desc}}, {sort: {sort:desc}}], where: {AND: [{ text: { is: { name_en: { contains: "${search}", mode: insensitive }}}},{ categories: { some: { category: { is: {slug: {equals: "${categories}"}}}}}},{countryRestriction: {is: {countries: {none: {country: {equals: "${country}", mode: insensitive}}}}}} ]}) {id hasDemo isVirtual merchant{ name, alias } gameCategories{ slug } image imageFullPath merchantId sort pageCodes { launch, mobileUrl, url, default, external, mobileExternal, mobileAndroid, mobileExternal, mobileWindows, }, parentMerchantId  text {name_en} Likes { gameId, likes } } }`,
    };
  }
  getGamesByAllFilters(take: number, skip: string, categories: any, merchants: any, country: string, search: string) {
    return {
      query: `{game (take: ${take}, skip: ${skip}, orderby: [{merchantSort: {sort:desc}}, {sort: {sort:desc}}], where: {AND: [{ text: { is: { name_en: { contains: "${search}", mode: insensitive }}}},{ merchantId: { in: [${merchants}] }},{ categories: { some: { category: { is: {slug: {equals: "${categories}"}}}}}},{countryRestriction: {is: {countries: {none: {country: {equals: "${country}", mode: insensitive}}}}}} ]}) {id hasDemo isVirtual merchant{ name, alias } gameCategories{ slug } image imageFullPath merchantId sort pageCodes { launch, mobileUrl, url, default, external, mobileExternal, mobileAndroid, mobileExternal, mobileWindows, }, parentMerchantId  text {name_en} Likes { gameId, likes } } }`,
    };
  }
  getGameByIdsQuery(take: number, skip: string, array: string, country?: string) {
    let idsList: any = array?.split(',');
    idsList = '"' + idsList?.join('","') + '"';
    return {
      query: `{game(take: ${take}, skip: ${skip}, where: {AND: [ {id: {in: [${idsList}]}},{countryRestriction: {is: {countries: {none: {country: {equals: "${country}", mode: insensitive}}}}}}]}) { id hasDemo isVirtual image merchant{ name, alias } gameCategories{ slug } text { name_en } imageFullPath pageCodes {  launch, mobileUrl, url, default, external,  mobileExternal, mobileAndroid, mobileExternal, mobileWindows,,  }, merchantId parentMerchantId Likes { gameId, likes } } }`,
    };
  }
  getGameByIdsQueryAndFilter(take: number, skip: string, array: string, filter: any, country?: string) {
    let idsList: any = array?.split(',');
    idsList = '"' + idsList?.join('","') + '"';
    return {
      query: `{game(take: ${take}, skip: ${skip}, where: {AND: [ { merchantId: { in: [${filter}] }},{id: {in: [${idsList}]}},{countryRestriction: {is: {countries: {none: {country: {equals: "${country}", mode: insensitive}}}}}}]}) { id hasDemo isVirtual image merchant{ name, alias } gameCategories{ slug } text { name_en } imageFullPath pageCodes {  launch, mobileUrl, url, default, external,  mobileExternal, mobileAndroid, mobileExternal, mobileWindows,,  }, merchantId parentMerchantId Likes { gameId, likes } } }`,
    };
  }
  getGameByIdsQueryAndSearch(take: number, skip: string, array: string, search: string, country?: string) {
    let idsList: any = array?.split(',');
    idsList = '"' + idsList?.join('","') + '"';
    return {
      query: `{game(take: ${take}, skip: ${skip}, where: {AND: [ { text: { is: { name_en: { contains: "${search}", mode: insensitive }}}},{id: {in: [${idsList}]}},{countryRestriction: {is: {countries: {none: {country: {equals: "${country}", mode: insensitive}}}}}}]}) { id hasDemo isVirtual image merchant{ name,alias } gameCategories{ slug } text { name_en } imageFullPath pageCodes {  launch, mobileUrl, url, default, external,  mobileExternal, mobileAndroid, mobileExternal, mobileWindows,,  }, merchantId parentMerchantId Likes { gameId, likes } } }`,
    };
  }
  getGameByIdsQueryAndAllFilter(
    take: number,
    skip: string,
    array: string,
    search: string,
    filter: any,
    country?: string
  ) {
    let idsList: any = array?.split(',');
    idsList = '"' + idsList?.join('","') + '"';
    return {
      query: `{game(take: ${take}, skip: ${skip}, where: {AND: [ { text: { is: { name_en: { contains: "${search}", mode: insensitive }}}},{id: {in: [${idsList}]}}, { merchantId: { in: [${filter}] }}, {countryRestriction: {is: {countries: {none: {country: {equals: "${country}", mode: insensitive}}}}}}]}) { id hasDemo isVirtual image merchant{ name, alias } gameCategories{ slug } text { name_en } imageFullPath pageCodes {  launch, mobileUrl, url, default, external,  mobileExternal, mobileAndroid, mobileExternal, mobileWindows,,  }, merchantId parentMerchantId Likes { gameId, likes } } }`,
    };
  }
  getBannerPage(lang: string, slug: string) {
    return {
      query: `{ allBannerPages(where: {name:"${slug}"}) {id,content_l_${lang}}}`,
    };
  }
  getPromotionBySlug(slug: string, lang: string) {
    return {
      query: `{ allPromotions(where: {slug: "${slug}"}) { id , img, slug, description_l_${lang}, name_l_${lang}, short_l_${lang}, opengraphTitle_l_${lang}, opengraphDescription_l_${lang}, opengraphKeywords, opengraphImage, buttonClass, buttonAction } }`,
    };
  }
  getVerificationBySlug(slug: string, lang: string) {
    return {
      query: `{allVerifications(where:{slug: "${slug}"}){title_l_${lang}, code_l_${lang}} }`,
    };
  }
  getPageBySlugQuery(slug: string, lang: string) {
    return {
      query: `{allPages(where: {slug: "${slug}"}) { id,  name, slug,  isVisible, description_l_${lang}, opengraphTitle,   opengraphDescription,   opengraphKeywords,  opengraphImage } }`,
    };
  }
  getTopFaq(lang: string) {
    return {
      query: `{allFAQGroups(where: { isVisible: true }) {slug, order, title_l_${lang}, FAQs{slug, description_l_${lang}, title_l_${lang},}} }`,
    };
  }
  getFaqBySlugQuery(slug: string, lang: string) {
    return {
      query: `{allFAQGroups(where: {slug: "${slug}"}) {slug, order, title_l_${lang}, FAQs{slug, description_l_${lang}, title_l_${lang},}} }`,
    };
  }
  getGameByIdQuery(value: string, country?: string) {
    return {
      query: `{game(where: {AND: [{id: {equals: "${value}"}},{countryRestriction: {is: {countries: {none: {country: {equals: "${country}", mode: insensitive}}}}}} ]}) { id hasDemo isVirtual merchant{ name,alias } text { name_en } pageCodes {  launch, mobileUrl, url, default, external,  mobileExternal, mobileAndroid, mobileExternal, mobileWindows, }, merchantId parentMerchantId  }  }`,
    };
  }
  getIBansBySlug(slug: string) {
    return {
      query: `{ allAllowedIbans(where: {slug: "${slug}"}) {id , slug, AllowedIbans } }`,
    };
  }
  getAllTermsPages() {
    return {
      query: `{ allTermsPages(where: { isVisible: true }){ id, slug, isVisible, content, version, visibleName, pdf } }`,
    };
  }

  getAllPaymentMethods() {
    return {
      query: `{ allPaymentMethods(sortBy: order_ASC, where: { isVisible: true }){ name, image, depositMethod, depositLimit, withdrawMethod, withdrawLimit, country, depositFee } }`,
    };
  }

  getGeoCurrenciesBySlug(slug: string) {
    return {
      query: `{ allGeoCurrencies(where: {slug: "${slug}"}) { slug, priorCurrencies, excludeCurrencies } }`,
    };
  }
}
