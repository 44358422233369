import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from './core/guards/authGuard/auth.guard';
import { redirectGuard } from './core/guards/redirectGuard/redirect.guard';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () => import('./pages/pages.component').then((m) => m.PagesComponent),
    children: [
      {
        path: '',
        loadComponent: () => import('./pages/main/main.component').then((m) => m.MainComponent),
      },
      {
        path: 'providers',
        loadChildren: () => import('./pages/providers-page/providers-page.module').then((m) => m.ProvidersPageModule),
      },
      {
        path: 'game',
        loadChildren: () => import('./pages/game/game.module').then((m) => m.GameModule),
      },
      {
        path: 'games/:category',
        loadComponent: () => import('./pages/games/games.component').then((m) => m.GamesComponent),
      },
      {
        path: 'info',
        loadChildren: () => import('./pages/information/information.module').then((m) => m.InformationModule),
      },
      {
        path: 'cashier',
        loadChildren: () => import('./pages/cashier/cashier.module').then((m) => m.CashierModule),
        outlet: 'aside',
        canActivate: [authGuard],
      },
      {
        path: 'alerts',
        loadComponent: () => import('./pages/alerts/alerts.component').then((m) => m.AlertsComponent),
        outlet: 'aside',
        canActivate: [authGuard],
      },
      {
        path: 'account',
        loadChildren: () => import('./pages/account/account.module').then((m) => m.AccountModule),
        outlet: 'aside',
        canActivate: [authGuard],
      },
      {
        path: 'rewards',
        loadChildren: () => import('./pages/rewards/rewards.module').then((m) => m.RewardsModule),
        outlet: 'aside',
      },
      {
        path: 'promotions',
        loadComponent: () =>
          import('./pages/rewards/promotions/promotions.component').then((m) => m.PromotionsComponent),
      },
      {
        path: 'loyalty',
        loadChildren: () => import('./pages/loyalty/loyalty.module').then((m) => m.LoyaltyModule),
      },
      {
        path: 'tournaments',
        loadComponent: () => import('./pages/tournaments/tournaments.component').then((m) => m.TournamentsComponent),
        canActivate: [authGuard],
      },
      {
        path: 'support',
        loadChildren: () => import('./pages/support/support.module').then((m) => m.SupportModule),
      },
      {
        path: 'support',
        loadChildren: () => import('./pages/support/support.module').then((m) => m.SupportModule),
        outlet: 'aside',
      },
      {
        path: 'languages',
        loadChildren: () => import('./pages/languages/laguages.module').then((m) => m.LaguagesModule),
        outlet: 'aside',
      },
      {
        path: 'page-not-found',
        loadComponent: () =>
          import('./pages/page-not-found/page-not-found.component').then((m) => m.PageNotFoundComponent),
      },
      {
        path: '**',
        outlet: 'aside',
        redirectTo: '/page-not-found',
      },
    ],
  },
  {
    path: 'country-restriction',
    loadComponent: () =>
      import('./pages/country-restriction/country-restriction.component').then((m) => m.CountryRestrictionComponent),
  },
  {
    path: 'auth',
    loadChildren: () => import('./pages/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'welcome',
    loadChildren: () => import('./pages/landing/landing.module').then((m) => m.LandingModule),
  },
  {
    path: ':lang',
    loadComponent: () => import('./pages/main/main.component').then((m) => m.MainComponent),
    canActivate: [redirectGuard],
  },
  {
    path: '**',
    redirectTo: '/page-not-found',
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
