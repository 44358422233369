import { Component } from '@angular/core';
import { CommonModule, NgComponentOutlet } from '@angular/common';
import { Observable, Subject, takeUntil } from 'rxjs';
import { selectModal } from 'src/app/core/store/modals/selector/modals.selectors';
import { Store } from '@ngrx/store';
import { LandingModalComponent } from '../landing-modal/landing-modal.component';
import { ModalComponent } from '../../modal/modal.component';
import { SelectBoxModalGenderComponent } from '../select-box-modal-gender/select-box-modal-gender.component';
import { SelectBoxModalPepComponent } from '../select-box-modal-pep/select-box-modal-pep.component';
import { SelectBoxIntervalDateComponent } from '../../forms/select-box-interval-date/select-box-interval-date.component';
import { SelectBoxCountryModalComponent } from '../select-box-country-modal/select-box-country-modal.component';
import { MobileGameLaunchCardComponent } from '../../games/mobile-game-launch-card/mobile-game-launch-card.component';
import { FiltersComponent } from '../../filters/filters.component';
import { LocalStorageService } from 'src/app/core/services/localStorage/local-storage.service';
import { TermsAndConditionRenewComponent } from 'src/app/pages/terms-and-condition-renew/terms-and-condition-renew.component';
import { PaymentStatusModalComponent } from '../../finances/payment-status-modal/payment-status-modal.component';
import { SelectBoxCurrencyModalComponent } from '../select-box-currency-modal/select-box-currency-modal.component';
import { SelectBoxDateModalComponent } from '../select-box-date-modal/select-box-date-modal.component';
import { SelectBoxPrefixModalComponent } from '../select-box-prefix-modal/select-box-prefix-modal.component';
import { DepositCharacteristicsMethodsModalComponent } from 'src/app/pages/cashier/deposit/deposit-characteristics-methods-modal/deposit-characteristics-methods-modal.component';
import { AllRanksModalComponent } from 'src/app/pages/loyalty/ranks-and-bonus/modals/all-ranks-modal/all-ranks-modal.component';
import { UserLoyaltyBalanceModalComponent } from 'src/app/pages/loyalty/ranks-and-bonus/modals/user-loyalty-balance-modal/user-loyalty-balance-modal.component';
import { LoyaltyPageRestrictionsModalComponent } from 'src/app/pages/loyalty/ranks-and-bonus/modals/loyalty-page-restrictions-modal/loyalty-page-restrictions-modal.component';
import { BonusRestrictedModalComponent } from '../bonus-restricted-modal/bonus-restricted-modal.component';
import { WithdrawLimitsModalComponent } from 'src/app/pages/cashier/withdraw/withdraw-limits-modal/withdraw-limits-modal.component';
import { SelectBoxStateModalComponent } from '../select-box-state-modal/select-box-state-modal.component';
import { BankModalsComponent } from '../bank-withdrawal-modal/bank-modals/bank-modals.component';
import { PromoModalComponent } from '../promo-modal/promo-modal.component';
import { QuestNotificationModalComponent } from '../quest-notification-modal/quest-notification-modal.component';
import { TournamentRulesModalComponent } from 'src/app/pages/tournaments/tournament-rules-modal/tournament-rules-modal.component';
import { TournamentAgreementModalComponent } from 'src/app/pages/tournaments/tournament-agreement-modal/tournament-agreement-modal.component';
import { BlockModalComponent } from '../block-modal/block-modal.component';
import { LoyaltyVideoModalComponent } from '../loyalty-video-modal/loyalty-video-modal.component';

@Component({
  selector: 'app-modal-selector',
  standalone: true,
  imports: [CommonModule, ModalComponent, NgComponentOutlet],
  templateUrl: './modal-selector.component.html',
  styleUrl: './modal-selector.component.scss',
})
export class ModalSelectorComponent {
  currentModal: any;
  modal$: Observable<any> = this.store.select(selectModal);
  unsubus$: Subject<boolean> = new Subject();
  isLandingTerms: boolean = false;
  inputs: any = {};
  title: string = '';
  isFilter: boolean = false;
  isGameCard: boolean = false;
  closeBar: boolean = false;
  isUnClosable: boolean = false;
  loyaltyModals: boolean = false;
  modalWidth: string = '';
  isSmall: boolean = false;
  appBarTitleToLeft: boolean = false;
  background: string = '';
  isTermsRenew: boolean = false;
  constructor(
    private store: Store,
    private localStorage: LocalStorageService
  ) {
    this.modal$.pipe(takeUntil(this.unsubus$)).subscribe((res) => {
      if (res?.isLoaded) {
        //document.body.className = 'stop-scroll';
        if (res?.modalId == '') {
          this.closeModal();
          return;
        }
        this.getCurrentModalMethod(res?.modalId, res);
      }
    });
  }
  getCurrentModalMethod(id: string, res?: any) {
    const openModalMethods: {
      [key: string]: any;
    } = {
      'terms-acceptance': this.openTermsRenew,
      selectBoxCountry: this.openSelectBoxCountryModal,
      selectBoxState: this.openSelectBoxStateModal,
      selectBoxPep: this.openSelectBoxPepModal,
      selectBoxGender: this.openSelectBoxGenderModal,
      'prefix-modal': this.openSelectBoxPrefixModal,
      selectBoxDate: this.openSelectBoxDate,
      currencyModal: this.openSelectBoxCurrencyModal,
      dateSelectionStoreHistory: this.openSelectBoxDateInterval,
      dateSelectionTransactions: this.openSelectBoxDateInterval,
      'mobile-game': this.mobileGameCardModal,
      'game-filters': this.openFiltersModal,
      payments: this.openPaymentsModal,
      privacyModal: this.openPrivacyModal,
      termsModal: this.openTermsModal,
      promoModal: this.openPromoModal,
      depositMethods: this.openDepositMethods,
      withdrawMethods: this.openWithdrawMethods,
      allRanksModal: this.AllRanksModal,
      userLoyaltyBalance: this.userLoyaltyBalanceModal,
      loyaltyRestrictions: this.openLoyaltyRestrictionsModal,
      'bonus-resricted': this.openBonusRestrictedModal,
      'bank-modal': this.openBankModal,
      'quest-notification': this.opneQuestNotificationModal,
      'rules-modal': this.openRulesModal,
      'agreement-modal': this.openAgreementModal,
      'block-modal': this.openBlockModal,
      loyaltyVideo: this.openLoyaltyVideo,
    };
    const method = openModalMethods[id];
    if (method) {
      return method.call(this, res);
    }
  }

  openRulesModal(res: any) {
    this.modalWidth = 'max-w-[37.75rem]';
    this.currentModal = TournamentRulesModalComponent;
    this.inputs = { text: res?.text };
    this.title = 'rules';
    this.closeBar = true;
    this.background = 'smallContainerBgLight';
  }

  openAgreementModal(res: any) {
    this.modalWidth = 'max-w-[37.75rem]';
    this.currentModal = TournamentAgreementModalComponent;
    this.inputs = { text: res?.text, ID: res?.ID, state: res.state };
    this.title = 'agreement';
    this.closeBar = true;
    this.background = 'smallContainerBgLight';
  }

  openLoyaltyVideo() {
    this.modalWidth = 'max-w-[54rem]';
    this.currentModal = LoyaltyVideoModalComponent;
  }
  openPaymentsModal(res: any) {
    this.modalWidth = 'max-w-[37.75rem]';
    this.currentModal = PaymentStatusModalComponent;
    this.inputs = { openModal: res?.state, amount: res?.amount, currency: res?.currency, text: res?.text };
    this.isUnClosable = true;
  }
  openTermsRenew() {
    this.currentModal = TermsAndConditionRenewComponent;
    this.modalWidth = 'max-w-[37.75rem]';
    this.title = 'terms-acceptance';
    this.isUnClosable = true;
  }
  openTermsModal(res: any) {
    this.currentModal = LandingModalComponent;
    this.isLandingTerms = true;
    this.closeBar = true;
    this.isTermsRenew = res?.state ? true : false;
    this.inputs = {
      preselectedSlug: 'terms',
      termsRenewConfirmation: res?.state,
    };
    this.title = 'terms';
  }
  openPromoModal() {
    this.currentModal = PromoModalComponent;
    this.modalWidth = 'max-w-[37.75rem]';
  }
  openSelectBoxPrefixModal() {
    this.modalWidth = 'max-w-[37.75rem]';
    this.currentModal = SelectBoxPrefixModalComponent;
    this.closeBar = true;
    this.isSmall = true;
    this.background = 'smallContainerBgLight';
    this.title = 'prefix';
  }
  openFiltersModal() {
    this.currentModal = FiltersComponent;
    this.isFilter = true;
    this.background = 'bg-skyBtnHoverLight';
    this.closeBar = true;
    this.isSmall = true;
    this.title = this.localStorage.getSessionStorage('filters') || '';
  }
  mobileGameCardModal() {
    this.currentModal = MobileGameLaunchCardComponent;
    this.isGameCard = true;
  }
  openPrivacyModal() {
    this.modalWidth = 'max-w-[37.75rem]';
    this.currentModal = LandingModalComponent;
    this.inputs = {
      preselectedSlug: 'privacy',
    };
    this.closeBar = true;
    this.isLandingTerms = false;
    this.title = 'privacy';
  }
  openSelectBoxDate(res: any) {
    this.currentModal = SelectBoxDateModalComponent;
    this.modalWidth = 'max-w-[21.938rem]';
    this.inputs = {
      selectedModal: res?.dateSelectedModal,
      text: res?.text,
    };
  }
  openSelectBoxDateInterval(res: any) {
    this.modalWidth = 'max-w-[44.375em]';
    this.currentModal = SelectBoxIntervalDateComponent;
    this.inputs = {
      modalId: res?.modalId,
    };
  }
  openSelectBoxCountryModal() {
    this.currentModal = SelectBoxCountryModalComponent;
    this.modalWidth = 'max-w-[37.75rem]';
    this.closeBar = true;
    this.isSmall = true;
    this.background = 'smallContainerBgLight';
    this.title = 'country';
  }

  openSelectBoxStateModal(res: any) {
    this.currentModal = SelectBoxStateModalComponent;
    this.modalWidth = 'max-w-[37.75rem]';
    this.closeBar = true;
    this.isSmall = true;
    this.background = 'smallContainerBgLight';
    this.title = 'state';
    this.inputs = {
      country: res?.country,
    };
  }

  openSelectBoxGenderModal() {
    this.currentModal = SelectBoxModalGenderComponent;
    this.modalWidth = 'max-w-[37.75rem]';
    this.closeBar = true;
    this.isSmall = true;
    this.background = 'smallContainerBgLight';
    this.title = 'gender';
  }
  openSelectBoxCurrencyModal() {
    this.currentModal = SelectBoxCurrencyModalComponent;
    this.modalWidth = 'max-w-[37.75rem]';
    this.closeBar = true;
    this.title = 'currency';
    this.isSmall = true;
    this.background = 'smallContainerBgLight';
  }
  openSelectBoxPepModal() {
    this.currentModal = SelectBoxModalPepComponent;
    this.modalWidth = 'max-w-[37.75rem]';
    this.closeBar = true;
    this.isSmall = true;
    this.background = 'smallContainerBgLight';
    this.title = 'political_exposed_person';
  }
  openDepositMethods(res: any) {
    this.modalWidth = 'max-w-[34.75rem]';
    this.inputs = { type: res?.state?.type };
    this.currentModal = DepositCharacteristicsMethodsModalComponent;
  }
  openWithdrawMethods() {
    this.modalWidth = 'max-w-[54rem]';
    this.currentModal = WithdrawLimitsModalComponent;
  }
  AllRanksModal() {
    this.modalWidth = 'max-w-[41.125rem]';
    this.currentModal = AllRanksModalComponent;
    this.closeBar = true;
    this.title = 'all_ranks';
    this.appBarTitleToLeft = true;
    this.background = 'bg-smallContainerBgLight';
    this.isSmall = true;
  }
  userLoyaltyBalanceModal() {
    this.modalWidth = 'max-w-[40.125rem]';
    this.currentModal = UserLoyaltyBalanceModalComponent;
    this.closeBar = true;
    this.appBarTitleToLeft = true;
    this.title = 'reward_details';
  }
  openLoyaltyRestrictionsModal(res: any) {
    this.modalWidth = 'max-w-[32.75rem]';
    this.currentModal = LoyaltyPageRestrictionsModalComponent;
    this.loyaltyModals = true;
    this.inputs = {
      openModal: res?.state,
      bonusName: res?.text,
    };
  }
  openBonusRestrictedModal() {
    this.currentModal = BonusRestrictedModalComponent;
    this.modalWidth = 'max-w-[22.375rem]';
    this.background = 'bg-smallContainerBgLight';
    this.closeBar = true;
    this.isSmall = true;
  }
  openBankModal(res: any) {
    this.currentModal = BankModalsComponent;
    this.modalWidth = 'max-w-[36.69rem]';
    this.inputs = {
      openModal: res?.state,
      amount: res?.amount,
      currency: res?.currency,
      bankrates: res?.bankrates,
    };
  }
  opneQuestNotificationModal(res: any) {
    this.currentModal = QuestNotificationModalComponent;
    this.modalWidth = 'max-w-[19.125rem]';
    this.loyaltyModals = true;
    this.inputs = {
      openModal: res?.state,
      text: res?.text,
    };
  }
  openBlockModal(res: any) {
    this.currentModal = BlockModalComponent;
    this.closeBar = true;
    this.isSmall = true;
    this.modalWidth = 'max-w-[33.75rem]';
    this.background = 'bg-smallContainerBgLight';
    this.inputs = {
      formValue: res?.state?.form,
      loginID: res?.state?.loginID,
    };
  }
  closeModal() {
    document.body.className = document.body.className.replace('stop-scroll', ' ');
    this.currentModal = null;
    this.modalWidth = '';
    this.isUnClosable = false;
    this.isLandingTerms = false;
    this.title = '';
    this.isGameCard = false;
    this.closeBar = false;
    this.isFilter = false;
    this.loyaltyModals = false;
    this.inputs = {};
    this.isSmall = false;
    this.appBarTitleToLeft = false;
  }
}
