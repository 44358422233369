import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { firstValueFrom, Observable, Subject, takeUntil } from 'rxjs';
import { BankLimits, UserBalances } from 'src/app/core/interfaces/interfaces';
import { UserCurrencyPipePipe } from 'src/app/core/pipes/userCurrencyPipe/user-currency-pipe.pipe';
import { TurnoverSumFormatterPipe } from 'src/app/core/pipes/wagerBalancePipe/turnover-sum-formatter.pipe';
import { BankService } from 'src/app/core/services/bank/bank.service';
import { ModalsDataTransferService } from 'src/app/core/services/modalService/modals-data-transfer.service';
import { selectHelpers } from 'src/app/core/store/helpers/selector/helpers.selectors';
import { transferLoyaltydataActions } from 'src/app/core/store/loyalty/actions/loyalty.actions';
import { ModalsActions } from 'src/app/core/store/modals/actions/modals.actions';
import { selectUser } from 'src/app/core/store/user/selectors/user.selector';

@Component({
  selector: 'app-bank-modals',
  standalone: true,
  imports: [CommonModule, TranslocoModule, UserCurrencyPipePipe, TurnoverSumFormatterPipe],
  templateUrl: './bank-modals.component.html',
  styleUrl: './bank-modals.component.scss',
})
export class BankModalsComponent implements OnInit, OnDestroy {
  @Input() currency: string = '';
  @Input() set amount(value: number) {
    this.bankAmount = value;
  }
  @Input()
  openModal: 'deposit' | 'withdrawal' | null = null;
  @Input()
  public bankrates!: BankLimits;

  public isMobile: boolean = false;
  private helpers$: Observable<any> = this.store.select(selectHelpers);
  private UserInfo$: Observable<any> = this.store.select(selectUser);
  private unsubus$: Subject<boolean> = new Subject();
  private transactionAmount: number = 0;
  userBalance: UserBalances = {
    amount: 0,
    currency: 'USD',
    type: 'ACTIVE',
  };

  public bankAmount: number = 0;
  public inputAmount: number = 0;
  public isLoading: boolean = false;
  public isValid: boolean = false;

  constructor(
    private store: Store,
    private bankService: BankService,
    private modalDataService: ModalsDataTransferService
  ) {}

  ngOnInit(): void {
    this.helpers$.pipe(takeUntil(this.unsubus$)).subscribe((res: any) => {
      if (res.isLoaded) {
        this.isMobile = res.isMobile;
      }
    });
    this.UserInfo$.pipe(takeUntil(this.unsubus$)).subscribe((res: any) => {
      if (res?.userInfo !== null && Object.values(res?.userInfo?.data).length > 0) {
        this.userBalance.amount = res.userInfo.data.availableWithdraw;
        this.userBalance.currency = res.userInfo.data.currency;
      }
    });
  }
  openTermsModal() {
    this.store.dispatch(
      ModalsActions['[modals]OpenModalSuccess']({
        id: 'termsModal',
      })
    );
  }

  onInput(value: any, event: any) {
    value = value * 1;
    if (isNaN(value)) {
      event.target.value = event.target.value.substring(0, event.target.value.length - 1);
      return;
    }
    if (this.openModal === 'withdrawal') {
      this.inputAmount = value > this.bankAmount ? this.bankAmount : value;
      this.isValid = this.inputAmount > 0 && this.inputAmount <= this.bankAmount;
    } else {
      this.inputAmount =
        value > this.bankrates?.userConditionsRelation?.limitsRelation?.lte
          ? this.bankrates?.userConditionsRelation?.limitsRelation?.lte
          : value < this.bankrates?.userConditionsRelation?.limitsRelation?.gte
            ? this.bankrates?.userConditionsRelation?.limitsRelation?.gte
            : value;

      this.isValid = this.inputAmount > 0 && this.inputAmount <= this.userBalance.amount;
    }
    event.target.value = this.inputAmount;
  }

  async handleTransaction(isWithdrawal: boolean, all: boolean = false) {
    let transactionId;
    let withdrawalAll;
    if (
      this.isLoading ||
      (!this.isValid && !isWithdrawal) ||
      (this.bankAmount + this.inputAmount > this.bankrates?.userConditionsRelation?.limitsRelation?.lte &&
        !isWithdrawal)
    )
      return;

    if (isWithdrawal) {
      withdrawalAll = all || this.inputAmount === this.bankAmount;

      this.transactionAmount = withdrawalAll ? this.bankAmount : this.inputAmount;

      if (withdrawalAll) this.inputAmount = this.bankAmount;
    } else {
      this.transactionAmount = this.inputAmount;
    }

    try {
      document.body.className = 'stop-click';
      this.isLoading = true;

      const currentWithdrawal = await this.bankService.getWithdrawsActiveTransactions();
      if (currentWithdrawal.length > 0) {
        this.transactionAmount += isWithdrawal ? 0 : Math.abs(currentWithdrawal[0].Amount);
        await firstValueFrom(this.bankService.deleteWithdrawTransaction(currentWithdrawal[0].ID));
      }

      if (!withdrawalAll) {
        transactionId = await firstValueFrom(
          this.bankService.withdrawToFundist(
            12333,
            isWithdrawal ? this.bankAmount - this.transactionAmount : this.transactionAmount
          )
        );
      }

      const stackMoneyResponse = await firstValueFrom(
        isWithdrawal
          ? this.bankService.withdrawalMoney(this.transactionAmount, transactionId)
          : this.bankService.stackMoney(this.transactionAmount - this.bankAmount, transactionId)
      );

      if (stackMoneyResponse) {
        this.store.dispatch(
          transferLoyaltydataActions['[TransferData]SetRequesDataSuccess']({
            data: { sidenavdata: false, calendardata: false, ranksdata: false, bankdata: true },
          })
        );
        this.modalDataService.setBankDepStatus(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
      document.body.className = '';
      this.store.dispatch(ModalsActions['[modals]CloseModalsSuccess']());
    }
  }

  clearInput(event: any) {
    if (event.target.value == '0') event.target.value = event.target.value.substring(0, event.target.value.length - 1);
  }
  close() {
    if (this.isLoading) return;
    this.store.dispatch(ModalsActions['[modals]CloseModalsSuccess']());
  }
  ngOnDestroy(): void {
    if (this.isLoading) return;
    this.unsubus$.next(true);
    this.unsubus$.complete();
    this.store.dispatch(ModalsActions['[modals]CloseModalsSuccess']());
  }
}
