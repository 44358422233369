import { createReducer, on } from '@ngrx/store';
import { checkUserExist, restoreDefault } from '../actions/sign-up.actions';

export const checkUserExitFeatureKey = 'CheckUserExist';

export interface State {
  isRegistered: boolean;
  err: any;
}

export const initialState: State = {
  isRegistered: false,
  err: null,
};

export const reducer = createReducer(
  initialState,
  on(checkUserExist['[checkUserExist]CheckUserExistSuccess'], (state, { data }) => {
    return {
      ...state,
      userData: data.userData,
      isRegistered: data.result,
      err: data.errors,
    };
  }),
  on(restoreDefault['[restoreDefault]RestoreDefault'], (state) => {
    return {
      ...state,
      userData: {},
      isRegistered: false,
      err: null,
    };
  }),
  on(checkUserExist['[checkUserExist]UpdateUserData'], (state, action) => {
    return {
      ...state,
      userData: action.data,
    };
  })
);
