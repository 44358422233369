<ng-container *transloco="let t">
  <div [class]="' pt-4 w-full px-4 ' + (!isAuth ? ' flex ' : ' grid grid-cols-[80%_20%] pt-4 gap-4 ')">
    <button
      *ngIf="isAuth"
      (click)="saveGame()"
      class="flex rounded-lg mr-auto text-mainTextLight dark:text-mainText hover:bg-sidebarLight dark:hover:bg-mainHover px-3 py-2 flex-row gap-3 justify-center items-center">
      <img
        width="24"
        height="24"
        *ngIf="!isFavorite"
        src="https://cdn.chipychapa.com//welle/General_site/Games/nonsaved.svg"
        alt="save game" />
      <img
        width="24"
        height="24"
        *ngIf="isFavorite"
        src="https://cdn.chipychapa.com//welle/General_site/Games/saved_game.svg"
        alt="save game" />
      <span>
        {{ t('game.save_game') }}
      </span>
    </button>

    <button
      [class]="
        '  group rounded-lg hover:bg-sidebarLight dark:hover:bg-mainHover ' + (!isAuth ? ' ml-auto ' : ' items-end ')
      "
      (click)="closeModal()">
      <img
        class="m-auto group-hover:scale-110"
        src="https://cdn.chipychapa.com//welle/General_site/Games/close.svg"
        alt="cross" />
    </button>
  </div>

  <div class="flex pt-8 flex-col pb-4 gap-4 text-mainTextLight dark:text-mainText">
    <img class="w-full max-w-[10rem] mx-auto rounded-xl" [src]="game?.image" alt="game image" />
    <div class="text-center w-full text-xl flex justify-center font-semibold">
      {{ game?.text.name_en }}
    </div>
    <div class="flex justify-center w-full text-base font-semibold">
      {{ game?.merchant.name }}
    </div>

    <app-main-button
      class="px-5"
      (click)="playGame('0')"
      name="Play"
      variant="yellowBgGames"
      svg="https://cdn.chipychapa.com//welle/General_site/Games/Play.svg"></app-main-button>
    <app-main-button
      *ngIf="hasDemo && game?.merchant?.name !== 'Digitain'"
      class="px-5"
      (click)="playGame('1')"
      name="Play For Fun"
      variant="tertiaryCenter"></app-main-button>
  </div>
</ng-container>
