import { Component } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { CookieService } from 'src/app/core/services/cookies/cookie.service';
import { KeystoneService } from 'src/app/core/services/keystone/keystone.service';
import { TermsAndConditionsService } from 'src/app/core/services/terms-and-conditions/terms-and-conditions.service';
import { selectLoyalty } from 'src/app/core/store/loyalty/selector/loyalty.selector';
import { ModalsActions } from 'src/app/core/store/modals/actions/modals.actions';
import { MainButtonComponent } from 'src/app/shared/buttons/main-button/main-button.component';

@Component({
  selector: 'app-terms-and-condition-renew',
  standalone: true,
  imports: [TranslocoModule, MainButtonComponent],
  templateUrl: './terms-and-condition-renew.component.html',
  styleUrl: './terms-and-condition-renew.component.scss',
})
export class TermsAndConditionRenewComponent {
  checked: boolean = false;
  loyalty$: Observable<any> = this.store.select(selectLoyalty);
  unsubus$: Subject<boolean> = new Subject();

  constructor(
    private termsAndCondService: TermsAndConditionsService,
    private store: Store,
    private keystone: KeystoneService,
    private cookie: CookieService
  ) {}
  acceptTerms() {
    if (!this.checked) return;
    this.keystone.getPage('terms').subscribe((res: any) => {
      if (res?.opengraphImage) {
        this.termsAndCondService.postStorage('terms', res?.opengraphImage).subscribe();
        this.store.dispatch(ModalsActions['[modals]CloseModalsSuccess']());
      }
    });
    this.questCheck();
  }
  openTerms() {
    this.store.dispatch(
      ModalsActions['[modals]OpenModalSuccess']({
        id: 'termsModal',
        state: true,
      })
    );
  }
  checkTerms() {
    this.checked = !this.checked;
  }

  questCheck() {
    setTimeout(() => {
      this.loyalty$.pipe(takeUntil(this.unsubus$)).subscribe((res) => {
        if (
          res?.isLoaded &&
          res?.readyToClaimQuest?.readyToClaimAuthQuest &&
          !this.cookie.getCookie('readyToClaimAuthQuest')
        ) {
          const today = new Date();
          today.setUTCHours(23, 59, 0, 0);
          document.cookie = `readyToClaimAuthQuest=${true};expires=${today.toUTCString()};path=/;`;
          this.store.dispatch(
            ModalsActions['[modals]OpenModalSuccess']({
              id: 'quest-notification',
              state: 'notification',
            })
          );
        }
      });
    }, 1500);
  }
}
