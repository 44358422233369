import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';

export const authGuard: CanActivateFn = (route) => {
  const a = route.pathFromRoot.map((v) => v.url.map((segment) => segment.toString()).join('/')).join('/');
  if (a.includes('auth')) {
    return inject(AuthService).blockAuthPages();
  }
  return inject(AuthService).getIsUserAuth();
};
