<div class="w-full h-full flex flex-col px-2 pb-2" *transloco="let t">
  <div
    *ngFor="let item of items; let last = last"
    class="p-2"
    [ngClass]="last ? '' : 'border-b-[1px] dark:border-line border-mainBgLighter'">
    <label
      [class]="
        'inner-container flex justify-between items-center w-full rounded-xl cursor-pointer px-4 py-2 hover:dark:bg-mainHover hover:bg-skyBtnHoverLight active:dark:bg-blueBgColor active:bg-blueBgColor transition-colors form-custom-check ' +
        (item?.value === pepSelected?.value ? ' bg-blueBgColor ' : '')
      "
      [id]="item.value.toLowerCase() + 'PEP'">
      <img *ngIf="item?.img" [src]="item.img" alt="flag" />
      <span class="text-base font-normal leading-6 dark:text-mainText text-mainTextLight">
        {{ t('account.profile.pep.' + item.value) }}
      </span>
      <div class="flex gap-6 items-center">
        <input
          (click)="selectItem(item)"
          [checked]="item?.value === pepSelected?.value"
          type="radio"
          class="form-custom-check-pep" />
      </div>
    </label>
  </div>
</div>
