import { NgModule } from '@angular/core';
import { CommonModule, IMAGE_LOADER, ImageLoaderConfig } from '@angular/common';
import { imagesUrl } from './images.config';

@NgModule({
  declarations: [],
  providers: [
    {
      provide: IMAGE_LOADER,
      useValue: (config: ImageLoaderConfig) => {
        return imagesUrl[config?.src];
      },
    },
  ],
  imports: [CommonModule],
})
export class ImagesModule {}
