import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { UserService } from '../../services/user/user.service';

export const redirectGuard: CanActivateFn = (route) => {
  if (route.queryParams.message === 'SET_NEW_PASSWORD') {
    return inject(UserService).redirectForSpecialParams(`auth/recovery-password`, route.queryParams.code);
  }
  return inject(UserService).redirectForSpecialParams('/page-not-found', '');
};
